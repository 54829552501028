var render = function render(){
  var _vm$formData, _vm$formData$inspecti, _vm$formData2, _vm$formData2$inspect, _vm$formData2$inspect2, _vm$formData3, _vm$formData3$inspect, _vm$formData4, _vm$formData4$inspect, _vm$formData5, _vm$formData5$inspect;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto d-flex"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-left align-middle text-truncate"
  }, [_vm._v("Fleet")]), _c('th', {
    staticClass: "text-left align-middle text-truncate"
  }, [_vm._v("Field")]), _c('th', {
    staticClass: "text-left align-middle text-truncate"
  }, [_vm._v("Signature")]), _c('th', {
    staticClass: "text-left align-middle text-truncate"
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "text-center align-middle",
    staticStyle: {
      "width": "20px"
    }
  }, [_vm._v("#")])])]), _c('tbody', [(_vm$formData = _vm.formData) !== null && _vm$formData !== void 0 && _vm$formData.inspectionData ? [_c('tr', [_c('td', [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', [_vm._v(_vm._s((_vm$formData$inspecti = (_vm$formData2 = _vm.formData) === null || _vm$formData2 === void 0 ? void 0 : (_vm$formData2$inspect = _vm$formData2.inspectionData) === null || _vm$formData2$inspect === void 0 ? void 0 : (_vm$formData2$inspect2 = _vm$formData2$inspect.vehicle) === null || _vm$formData2$inspect2 === void 0 ? void 0 : _vm$formData2$inspect2.name) !== null && _vm$formData$inspecti !== void 0 ? _vm$formData$inspecti : '-'))])])]), _c('td', [_c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l((_vm$formData3 = _vm.formData) === null || _vm$formData3 === void 0 ? void 0 : (_vm$formData3$inspect = _vm$formData3.inspectionData) === null || _vm$formData3$inspect === void 0 ? void 0 : _vm$formData3$inspect.field, function (field, fieldIndex) {
    return _c('div', {
      key: fieldIndex,
      staticClass: "d-flex mb-1"
    }, [_c('strong', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(field === null || field === void 0 ? void 0 : field.label) + ": ")]), _c('span', [_vm._v(_vm._s(field !== null && field !== void 0 && field.value ? _vm.handleTypeDate(field === null || field === void 0 ? void 0 : field.value) : '-'))])]);
  }), 0)]), _c('td', [_c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l((_vm$formData4 = _vm.formData) === null || _vm$formData4 === void 0 ? void 0 : (_vm$formData4$inspect = _vm$formData4.inspectionData) === null || _vm$formData4$inspect === void 0 ? void 0 : _vm$formData4$inspect.signature, function (signature, signatureIndex) {
    return _c('div', {
      key: signatureIndex,
      staticClass: "d-flex mb-1"
    }, [_c('strong', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(signature === null || signature === void 0 ? void 0 : signature.label) + ": ")]), _c('span', [_vm._v(_vm._s(signature !== null && signature !== void 0 && signature.value ? _vm.handleTypeDate(signature === null || signature === void 0 ? void 0 : signature.value) : '-'))])]);
  }), 0)]), _c('td', [_c('strong', {
    staticClass: "text-primary text-capitalize"
  }, [_vm._v(_vm._s((_vm$formData5 = _vm.formData) === null || _vm$formData5 === void 0 ? void 0 : (_vm$formData5$inspect = _vm$formData5.inspectionData) === null || _vm$formData5$inspect === void 0 ? void 0 : _vm$formData5$inspect.status))])]), _c('td', [_c('b-dropdown', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      value: 'Action',
      expression: "'Action'",
      modifiers: {
        "top": true
      }
    }],
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "link iq-bg-primary",
      "toggle-class": "text-decoration-none",
      "no-caret": "",
      "size": "sm"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "dropdown-toggle p-0",
          attrs: {
            "id": "dropdownMenuButton5",
            "formData-toggle": "dropdown"
          }
        }, [_c('i', {
          staticClass: "fa fa-file m-0"
        })])];
      },
      proxy: true
    }], null, false, 3374703426)
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        var _vm$formData6, _vm$formData7, _vm$formData$vehicle, _vm$formData$vehicle$, _vm$formData8;
        return _vm.openInspectionChecklistSlide(true, false, (_vm$formData6 = _vm.formData) === null || _vm$formData6 === void 0 ? void 0 : _vm$formData6.inspectionData, (_vm$formData7 = _vm.formData) === null || _vm$formData7 === void 0 ? void 0 : _vm$formData7.vehicle_id, (_vm$formData$vehicle = _vm.formData.vehicle) === null || _vm$formData$vehicle === void 0 ? void 0 : (_vm$formData$vehicle$ = _vm$formData$vehicle.vehicle_type) === null || _vm$formData$vehicle$ === void 0 ? void 0 : _vm$formData$vehicle$.name, (_vm$formData8 = _vm.formData) === null || _vm$formData8 === void 0 ? void 0 : _vm$formData8.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-eye mr-2"
  }), _vm._v("Preview")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        var _vm$formData9, _vm$formData9$inspect;
        return _vm.onExport((_vm$formData9 = _vm.formData) === null || _vm$formData9 === void 0 ? void 0 : (_vm$formData9$inspect = _vm$formData9.inspectionData) === null || _vm$formData9$inspect === void 0 ? void 0 : _vm$formData9$inspect.id, 'pdf');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-file mr-2"
  }), _vm._v("Export PDF")])], 1)], 1)])] : [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colSpan": "6"
    }
  }, [_vm._v(" There's no inspection yet. ")])])]], 2)])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }