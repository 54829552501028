<template>
  <b-container fluid>
    <form @submit.prevent="onSaveTripDocument">
      <b-row>
        <b-col md="12">
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit" v-if="['EDIT', 'ADD'].includes(action)"><i class="fa fa-save"></i> Create Trip Document&nbsp; </b-button>
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormTripDocument"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
        </b-col>
        <b-col md="12" class="p-4">
          <b-row>
            <b-col md="6">
              <b-form-group label="Document Name">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.name_document"  />
                <strong v-if="['PREVIEW'].includes(action)">{{form.name_document}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="projectData?.project_contract_plan?.length > 0">
              <b-form-group label="For Trip">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.for_trip" />
                <strong v-if="['PREVIEW'].includes(action)">{{form.for_trip}}</strong>
              </b-form-group>
            </b-col>
            
            <b-col md="12">
              <b-form-group label="Documents">
                <b-form-file accept="application/*" multiple v-model="form.document" name='document' id="document" class="border p-2"></b-form-file>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name:'SlideTripDocument',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      }, 
      refreshData:{
        type:Function,
        default:true
      }, 
      closeSlideFormTripDocument:{
        type:Function,
        default:true
      },  
      projectData:{
        type:Object,
        default:null
      }, 
    },
    components:{
    },
    data(){
      return{
        form:{
          name_document: '',
          doc_for_trip: '',
          document: [],
        }
      }
    },
    watch:{
      id: {
        deep: true,
        handler: function (value) {
          if (value) {
            this.getDetailTripDocument(value)
          }
        },
      },
      action: {
        deep: true,
        handler: function (value) {
          if (value === "ADD") {
            this.resetForm()
          }
        },
      }
    },
    mounted(){},
    methods:{
      ...busdevActions,
      resetForm(){
        this.form = {
          name_document: '',
          doc_for_trip: '',
          document: [],
        }
      },

      async getDetailTripDocument(id){
        const res = await this.fetchTripDocumentById({id})

        if(res?.data?.status === true){
          let data = res.data.data
          this.form = {
            name_document: data?.name_document,
            doc_for_trip: data?.name_document,
            document: [],
          }
        }
      },

      async onSaveTripDocument(){
        let payload = {
          ...this.form,
          project_contract_id: this.projectData.id
        }

        let formData = new FormData()
        formData.append('name_document', payload.name_document)
        formData.append('doc_for_trip', payload.doc_for_trip)
        formData.append('project_contract_id', payload.project_contract_id)

        if(payload.document?.length > 0){
          // Append each file to FormData
          for (let i = 0; i < payload.document.length; i++) {
            formData.append('files', payload.document[i]);
          }
        }

        const res = await this.createTripDocument({data:formData})

        if(res.data.status === true){
          this.refreshData()
          this.resetForm()
          this.closeSlideFormTripDocument()
          this.$swal(`Success!`, `Create Trip Document Success.`, 'success')
        }
      }
    }
  }
</script>
