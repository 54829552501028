<template>
  <b-row class="m-3">
    <b-col md="12" v-if="!loading">
      <fieldset class="form-group border p-3" v-if="['ADD'].includes(action)">
        <legend class="w-auto px-2 h5 text-primary">General Information <span><b-button size="sm" variant="primary" v-b-toggle.generalInformation>
            <i class="fa fa-eye pr-0"></i> Detail
          </b-button></span>
        </legend>
        <b-collapse id="generalInformation">
          <b-row class="pl-3 pr-3">
            <b-col md="6">
              <b-form-group label="Project" label-for="project">
                <strong>
                  AI Project - Transhipment
                </strong>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Customer" label-for="customer">
                <strong>
                  123-412345-12323 - Dendy Gamer
                </strong>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <hr />
              <div class="d-flex flex-column overflow-auto">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th width="15%">Fleets</th>
                      <td width="5%">:</td>
                      <td width="30%" colSpan="4">
                        <div class="d-flex flex-column">
                          <b-row class="mb-2">
                            <b-col lg="3" class="mb-2">
                              <span>Fleet ownership</span> <br />
                              <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ projectData?.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                            </b-col>
                            <b-col lg="3" v-if="projectData?.fleet_ownership">
                              <span>Fleet Type</span> <br />
                              <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{projectData?.fleet_type?.name}}</b-badge>
                            </b-col>
                            <b-col lg="3" v-if="projectData?.fleet_type_id">
                              <span>Fleets</span> <br />
                              <div class="d-flex flex-warp">
                                <b-badge v-for="(objDetail, detailIndex) in projectData?.fleets" :key="detailIndex" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">{{objDetail?.vehicle_name}}</b-badge>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="15%">Periode Start</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <span>{{ projectData?.periode_start ? moment(projectData?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                      </td>
                      <th width="15%">Periode End</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <span>{{ projectData?.periode_start ? moment(projectData?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th width="15%">Contract Type</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <span>{{ projectData?.contract_type?.name }}</span>
                      </td>
                      <th width="15%">Cargo Type</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <span>{{ projectData?.cargo_type?.name ?? '-' }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th width="15%">Term</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <span>{{ projectData?.terms?.name ?? '-' }} <br /> Remarks: {{ projectData?.terms_remarks }}</span>
                      </td> 
                      <th width="15%">Total Quantity</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <div class="d-flex align-items-center" v-if="projectData?.cargo_type_id">
                          <span class="mr-2">{{ totalQuantityAdd?.toLocaleString() }}</span>
                          <strong>{{ projectData?.unit }}</strong>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="15%">POL</th>
                      <td width="5%">:</td>
                      <td width="30%">
                          <div class="d-flex flex-column mb-2 w-100">
                            <span v-for="(obj, polIndex) in projectData?.pol" :key="polIndex">{{polIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                          </div>
                        </td>
                      <th width="15%">POD</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <div class="d-flex flex-column mb-2 w-100">
                          <span v-for="(obj, podIndex) in projectData?.pod" :key="podIndex">{{podIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="15%">Laycan</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <span>{{ projectData?.laycan ? moment(projectData?.laycan).format('DD MMMM YYYY') : '-' }}</span>
                      </td>
                      <th width="15%">Distance</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <span>{{ projectData?.distance }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th width="15%">Freigh Rate (Est)</th>
                      <td width="5%">:</td>
                      <td width="30%">
                        <div class="d-flex flex-column">
                          <span>{{ formatCurrency(projectData?.freight_rate_est) }}</span>
                          <strong class="text-muted">{{ projectData?.ppn == 'include' ? 'Include ' : 'Exclude ' }} PPN</strong>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th width="15%">Remarks</th>
                      <td width="5%">:</td>
                      <td width="30%" colSpan="4">
                        <span>{{ projectData?.remarks }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </fieldset>
      
      <fieldset class="form-group border p-3 bg-default" v-if="['ADD'].includes(action)">
        <form @submit.prevent="onSaveSpalProcess"> 
          <legend class="w-auto px-2 h5 text-primary">SPAL / Contract Input</legend>
          <b-form-row>
            <b-col>
              <b-form-group label="Title" label-for="title">
                <b-form-input type="text" id="title" v-model="form.title" placeholder="Type Text"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="File Upload" label-for="attachment">
                <b-form-file class="border p-2" plain accept="application/*" multiple name="attachment" id="attachment" v-model="form.attachment" />
                <small>If you want to upload multiple file, press "ctrl / command" in your keyboard</small>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Notes" label-for="notes">
                <b-form-textarea v-model="form.notes" id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Is Fix SPAL / CONTRACT ?" label-for="is_fix_spal">
                <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  v-model="form.is_fix_spal"
                >
                  Yes / No
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button type="submit" variant="success" class="pull-right">
                <span v-if="saving" class="spinner-border spinner-border-sm" />
                <div v-else><i class="fa fa-save"></i> <span>Save Data</span></div>
              </b-button>
            </b-col>
          </b-form-row>
        </form>
      </fieldset>

      <fieldset class="form-group border p-3" v-if="['ADD', 'PREVIEW'].includes(action)">
        <legend class="w-auto px-2 h5 text-primary">history <span><b-button size="sm" variant="primary" v-b-toggle.history>
            <i class="fa fa-eye pr-0"></i> View History
          </b-button></span>
        </legend>
        <b-collapse id="history">
          <b-row class="pl-3 pr-3">
            <b-col md="12">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="20%">Title</th>
                    <th width="20%">File</th>
                    <th>Notes</th>
                    <th width="15%">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(history, historyIndex) in listHistorySpal" >
                    <tr :key="historyIndex">
                      <td>{{historyIndex + 1}}.</td>
                      <td>{{history?.title}}</td>
                      <td >
                        <div class="d-flex flex-wrap" v-if="history?.attachment?.length > 0">
                          <template v-for="(file, fileIndex) in history?.attachment" >
                            <img 
                              v-if="['application/pdf'].includes(file?.file_extension)"
                              src="../../../assets/images/activity/pdf.png" 
                              :key="fileIndex + '-history-file'"
                              alt="img" 
                              height="30px"
                              style="cursor:pointer"
                              class="mr-2 mb-2"
                              @click="handleOpenFilePreview(`${apiUrl}/${file?.file_url}`)"
                            />
                            <img 
                              v-else
                              :src="`${apiUrl}/${file?.file_url}`" 
                              :key="fileIndex + '-history-file'"
                              alt="img" 
                              height="30px"
                              style="cursor:pointer"
                              class="mr-2 mb-2"
                            />
                          </template>
                        </div>
                        <div v-else> - </div>
                      </td>
                      <td>{{history?.notes ?? '-'}}</td>
                      <td>
                        <b-button size="sm" variant="primary" v-b-toggle.comment>
                          <i class="fa fa-comment pr-0"></i> Comment
                        </b-button>
                      </td>
                    </tr>
                    <!-- Comment Box-->
                    <tr>
                      <td colspan="5">
                        <b-collapse id="comment">
                          <table class="table mb-0" width="100%">
                            <thead>
                              <tr>
                                <th>Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="history?.comment?.length > 0">
                                <tr v-for="(comment, commentIndex) in history?.comment" :key="commentIndex">
                                  <td>
                                    <h6 class="float-left mb-1"><strong>{{comment?.user_name ?? '-'}}</strong></h6>
                                    <br />
                                    <small>{{comment?.createdAt ? moment(comment?.createdAt).format('DD MMM YYYY HH:mm') : '-'}}</small>
                                    <br />
                                    <span>{{comment?.comment}}</span>
                                    <div class="d-flex flex-column m-0" v-if="comment?.comment_attachment?.length > 0">
                                      <small><strong class="mb-2">Attachment:</strong></small>
                                      <div class="d-flex flex-wrap">
                                        <template v-for="(commentFile, commentFileIndex) in comment?.comment_attachment" >
                                          <img 
                                            v-if="['application/pdf'].includes(commentFile?.file_extension)"
                                            src="../../../assets/images/activity/pdf.png" 
                                            :key="commentFileIndex + '-comment-file'"
                                            alt="img" 
                                            height="30px"
                                            style="cursor:pointer"
                                            class="mr-2 mb-2"
                                            @click="handleOpenFilePreview(`${apiUrl}/${commentFile?.file_url}`)"
                                          />
                                          <img 
                                            v-else
                                            :src="`${apiUrl}/${commentFile?.file_url}`" 
                                            :key="commentFileIndex + '-comment-file'"
                                            alt="img" 
                                            height="30px"
                                            style="cursor:pointer"
                                            class="mr-2 mb-2"
                                          />
                                        </template>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                              <tr v-else>
                                <td><span class="text-center text-muted">No comment yet, be the first comment.</span></td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="chat-footer p-3 bg-white">
                                    <div class="d-flex align-items-center">
                                      <div class="chat-attagement d-flex">
                                        <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                                        <div id="file-upload" class="d-inline-block ">
                                          <i style="cursor:pointer" @click="openFileInput" class="fa fa-paperclip pr-3" aria-hidden="true"></i>
                                        </div>
                                        <b-form-file id="file-upload"  class="border p-2" plain accept="application/*" multiple name="attachment" v-model="formComment.attachment" />
                                      </div>
                                      <input v-model="formComment.comment" type="text" class="form-control mr-3" placeholder="Type your message">
                                      <button type="button" @click.prevent="onAddComment(history?.id)" class="btn btn-primary d-flex align-items-center p-2"><i class="fas fa-paper-plane" aria-hidden="true"></i><span class="d-none d-lg-block ml-1">Send</span></button>
                                    </div>
                                    <div v-if="formComment.attachment.length > 0" class="d-flex mt-2 align-items-center ">
                                      <span class="mr-2"><strong >{{formComment.attachment.length}}</strong> Attachment</span>
                                      <a href="javascript:void(0)" @click="resetAttachmentComment"><i class="fa fa-trash text-danger" aria-hidden="true"></i></a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                       
                        </b-collapse>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-collapse>
      </fieldset>
    </b-col>

    <b-col md="12" v-else>
      <div class="alert alert-info m-4">
        Loading...
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'
  import { saveAs } from 'file-saver'

  export default {
    name:'SlideSpalProcess',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      }, 
      closeSlideSpalProcess:{
        type:Function,
        default:() => {}
      },  
    },
    components:{
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,

        saving: false,
        loading: false,

        projectData: null,
        listHistorySpal: [],

        form:{
          project_contract_id:null,
          title:'',
          notes:'',
          is_fix_spal:false,
          attachment:[]
        },
        formComment:{
          spal_process_id:null,
          user_id: JSON.parse(localStorage.getItem('profile')).id,
          user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
          comment:'',
          attachment:[]
        },
      }
    },
    watch:{
      id: {
        deep: true,
        immediate:true,
        handler: function (value) {
          if (value) {
            this.getDetailSpalProcess(value)
          }
        },
      },
      action: {
        deep: true,
        immediate:true,
        handler: function (value) {
          if(this.id){
            this.resetForm()
            this.getDetailSpalProcess(this.id)
          }
        },
      }
    },
    mounted(){
    },
    computed:{
      totalQuantityAdd() {
        return this.projectData?.project_contract_plan?.reduce((total, item) => total + Number(item?.plan_quantity), 0);
      },
    },
    methods:{
      ...busdevActions,
      handleOpenFilePreview: function (url) {
        window.open(url, "_blank");
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
      resetAttachmentComment(){
        this.formComment.attachment = []
      },
      resetForm(){
        this.form = {
          project_contract_id:this.id,
          title:'',
          notes:'',
          is_fix_spal:false,
          attachment:[]
        }

        this.formComment = {
          spal_process_id:null,
          user_id: JSON.parse(localStorage.getItem('profile')).id,
          user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
          comment:'',
          attachment:[]
        }
      },
      openFileInput() {
        // Create a file input element
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true; // Allow multiple file selection
        fileInput.accept = 'application/pdf,image/*'; 

        // Listen for changes to the file input
        fileInput.addEventListener('change', this.handleFileUpload);

        // Trigger a click event on the file input to open the file picker
        fileInput.click();
      },
      handleFileUpload(event) {
        const files = event.target.files; // Get the selected files
        const fileList = Array.from(files); // Convert FileList to Array

        this.formComment.attachment = fileList
        console.log('Uploaded files:', fileList);
        // You can now process the files, for example, send them to a server or perform other operations.
      },
      async getDetailSpalProcess(id){
        this.loading = true
        let params = {
          params:{},
          project_contract_id: id
        }

        const res = await this.fetchSpalProcess(params)

        if(res?.data?.status === true){
          this.listHistorySpal = res.data.data
          this.projectData = res.data?.data?.[0]?.project_contract

          this.listHistorySpal = this.listHistorySpal?.map(val => {
            if(val.comment.length > 0){
              val.comment = val.comment.sort((a, b) => a.id - b.id);
            }

            return val
          })
        } else {
          this.projectData = null
          this.listHistorySpal = []
        }

        this.loading = false
      },
      async getDetailSpalProcessWithoutLoad(id){
        let params = {
          params:{},
          project_contract_id: id
        }

        const res = await this.fetchSpalProcess(params)

        if(res?.data?.status === true){
          this.listHistorySpal = res.data.data

          this.listHistorySpal = this.listHistorySpal?.map(val => {
            if(val.comment.length > 0){
              val.comment = val.comment.sort((a, b) => a.id - b.id);
            }

            return val
          })
        } else {
          this.projectData = null
          this.listHistorySpal = []
        }
      },

      async onAddComment(id){
        console.log(id)
        let payload = {
          ...this.formComment,
          spal_process_id: id
        }

        let formData = new FormData()
        formData.append('spal_process_id', payload.spal_process_id)
        formData.append('user_id', payload.user_id)
        formData.append('user_name', payload.user_name)
        formData.append('comment', payload.comment)

        if(payload?.attachment?.length > 0){
          // Append each file to FormData
          for (let i = 0; i < payload.attachment.length; i++) {
            formData.append('files', payload.attachment[i]);
          }
        }

        const res = await this.createSpalProcessComment({data:formData})

        if(res.data.status === true){
          this.resetForm()
          this.getDetailSpalProcessWithoutLoad(this.id)
          // this.closeSlideFormPayment()
          this.$swal(`Success!`, `Comment Added.`, 'success')
        } else {
          this.$swal(`Oops!`, `Something went wrong.`, 'error')
        }

      },
      async onSaveSpalProcess(){
        this.saving = true
        let payload ={
          ...this.form,
          project_contract_id: this.id
        }

        let formData = new FormData()
        formData.append('project_contract_id', payload.project_contract_id)
        formData.append('title', payload.title)
        formData.append('is_fix_spal', payload.is_fix_spal)
        formData.append('notes', payload.notes)

        if(payload?.attachment?.length > 0){
          // Append each file to FormData
          for (let i = 0; i < payload.attachment.length; i++) {
            formData.append('files', payload.attachment[i]);
          }
        }

        const res = await this.createSpalProcess({data:formData})

        if(res.data.status === true){
          this.resetForm()
          this.getDetailSpalProcess(this.id)
          // this.closeSlideFormPayment()
          this.$swal(`Success!`, `Create Spal Process Success.`, 'success')
        } else {
          this.$swal(`Oops!`, `Something went wrong.`, 'error')
        }

        this.saving = false
      }
    }
  } 
</script>

<style scoped>
/* Ensure file input is hidden */
#file-upload {
  display: none;
}
</style>
