var render = function render(){
  var _vm$meta, _vm$meta2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.openSlideFormPayment('ADD', null);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v("   ADD DATA")])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Periode",
      "label-for": "periode"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "date",
      "range": "",
      "placeholder": "Select date range"
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Search",
      "label-for": "Period"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "search", $$v);
      },
      expression: "params.search"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Payment",
      "label-for": "status"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "plain": ""
    },
    model: {
      value: _vm.params.status,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "status", $$v);
      },
      expression: "params.status"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All")]), _c('b-form-select-option', {
    attrs: {
      "value": "request"
    }
  }, [_vm._v("Request")]), _c('b-form-select-option', {
    attrs: {
      "value": "paid"
    }
  }, [_vm._v("Paid")]), _c('b-form-select-option', {
    attrs: {
      "value": "revision"
    }
  }, [_vm._v("Revision")]), _c('b-form-select-option', {
    attrs: {
      "value": "canceled"
    }
  }, [_vm._v("Canceled")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "1"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter",
      "label-for": "button_search"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.getListPayment(1);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch",
      "body-class": "bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$paymentTotal;
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h4', {
          staticClass: "mb-0 text-white font-weight-bold text-truncate"
        }, [_vm._v("IDR " + _vm._s(_vm.formattedCurrencyIDR((_vm$paymentTotal = _vm.paymentTotal) === null || _vm$paymentTotal === void 0 ? void 0 : _vm$paymentTotal.total_billing)))]), _c('span', {
          staticClass: "text-white"
        }, [_vm._v("Total Billing")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch",
      "body-class": "bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$paymentTotal2;
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h4', {
          staticClass: "mb-0 text-white font-weight-bold text-truncate"
        }, [_vm._v("IDR " + _vm._s(_vm.formattedCurrencyIDR((_vm$paymentTotal2 = _vm.paymentTotal) === null || _vm$paymentTotal2 === void 0 ? void 0 : _vm$paymentTotal2.total_unpaid)))]), _c('span', {
          staticClass: "text-white"
        }, [_vm._v("Total Unpaid")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch",
      "body-class": "bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$paymentTotal3;
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h4', {
          staticClass: "mb-0 text-white font-weight-bold text-truncate"
        }, [_vm._v("IDR " + _vm._s(_vm.formattedCurrencyIDR((_vm$paymentTotal3 = _vm.paymentTotal) === null || _vm$paymentTotal3 === void 0 ? void 0 : _vm$paymentTotal3.total_paid)))]), _c('span', {
          staticClass: "text-white"
        }, [_vm._v("Total Paid")])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [[_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-bordered"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "align-middle",
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("For Trip")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Request Name")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Request Date")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Invoice Price")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Tax")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Invoice Total")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Balance")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Invoice Percent Age")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Real Pricing")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Real Invoice Number")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Invoice Date")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Due Date")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Invoice File")]), _c('th', {
    staticClass: "align-middle text-truncate"
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "align-middle text-center"
  }, [_vm._v("Action")])])]), _c('tbody', [_vm.list.length > 0 ? _vm._l(_vm.list, function (state, index) {
    var _state$for_trip, _state$name_tax, _state$balance$toLoca, _state$balance, _state$real_invoice_n, _state$history_mail;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s((_state$for_trip = state === null || state === void 0 ? void 0 : state.for_trip) !== null && _state$for_trip !== void 0 ? _state$for_trip : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.request_name))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.request_date ? _vm.moment(state === null || state === void 0 ? void 0 : state.request_date).format('DD MMM YYYY') : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.invoice_price ? _vm.formatCurrency(state === null || state === void 0 ? void 0 : state.invoice_price) : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s((_state$name_tax = state === null || state === void 0 ? void 0 : state.name_tax) !== null && _state$name_tax !== void 0 ? _state$name_tax : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.invoice_total ? _vm.formatCurrency(state === null || state === void 0 ? void 0 : state.invoice_total) : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s((_state$balance$toLoca = state === null || state === void 0 ? void 0 : (_state$balance = state.balance) === null || _state$balance === void 0 ? void 0 : _state$balance.toLocaleString()) !== null && _state$balance$toLoca !== void 0 ? _state$balance$toLoca : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.real_pricing ? _vm.formatCurrency(state === null || state === void 0 ? void 0 : state.real_pricing) : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s((_state$real_invoice_n = state === null || state === void 0 ? void 0 : state.real_invoice_number) !== null && _state$real_invoice_n !== void 0 ? _state$real_invoice_n : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.invoice_date ? _vm.moment(state === null || state === void 0 ? void 0 : state.invoice_date).format('DD MMM YYYY') : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.due_date ? _vm.moment(state === null || state === void 0 ? void 0 : state.due_date).format('DD MMM YYYY') : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.real_invoice_attachment, function (file, fileIndex) {
      return _c('div', {
        key: fileIndex,
        staticClass: "d-flex align-items-center mb-2",
        staticStyle: {
          "cursor": "pointer"
        }
      }, [_c('i', {
        staticClass: "fa fa-file text-primary mr-2"
      }), _c('span', {
        staticClass: "text-primary"
      }, [_vm._v(_vm._s(file.file_name))])]);
    }), 0)]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.status ? 'Active' : 'Inactive'))]), _c('td', {
      staticClass: "text-center"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideFormPayment('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Detail ")]), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideFormPayment('EDIT', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pen mr-2"
    }), _vm._v("Edit ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          var _vm$projectData;
          return _vm.openSlideSendMail('ADD', (_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : _vm$projectData.id, state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-envelope mr-2"
    }), _vm._v("Send Mail ")]), (state === null || state === void 0 ? void 0 : (_state$history_mail = state.history_mail) === null || _state$history_mail === void 0 ? void 0 : _state$history_mail.length) > 0 ? _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideHistoryMail('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-envelope mr-2"
    }), _vm._v("History Mail ")]) : _vm._e(), !(state !== null && state !== void 0 && state.real_invoice_fulfilled) ? _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideRealInvoice(state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-scroll mr-2"
    }), _vm._v("Real Invoice ")]) : _vm._e(), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "danger"
      }
    }, [_c('i', {
      staticClass: "fa fa-trash mr-2"
    }), _vm._v("Delete ")])], 1)], 1)]);
  }) : _vm._e()], 2)])])], _vm.list.length > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": (_vm$meta = _vm.meta) === null || _vm$meta === void 0 ? void 0 : _vm$meta.totalItem,
      "per-page": (_vm$meta2 = _vm.meta) === null || _vm$meta2 === void 0 ? void 0 : _vm$meta2.perPage,
      "first-number": "",
      "align": "center"
    },
    on: {
      "change": _vm.getListPayment
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center w-100 my-2 mb-4"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No Data Payment List.")])])], 2), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideFormPaymentList",
      "title": "Create Payment List",
      "width": "70%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('SlidePaymentList', {
          attrs: {
            "closeSlideFormPayment": _vm.closeSlideFormPayment,
            "refreshData": _vm.refreshData,
            "projectData": _vm.projectData,
            "id": _vm.id,
            "action": _vm.action
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideFormPaymentList,
      callback: function callback($$v) {
        _vm.slideFormPaymentList = $$v;
      },
      expression: "slideFormPaymentList"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideRealInvoice",
      "title": "Create Real Invoice",
      "width": "40%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('SlideRealInvoice', {
          attrs: {
            "closeSlideRealInvoice": _vm.closeSlideRealInvoice,
            "refresh-data": _vm.refreshData,
            "paymentId": _vm.id
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideRealInvoice,
      callback: function callback($$v) {
        _vm.slideRealInvoice = $$v;
      },
      expression: "slideRealInvoice"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideSendMail",
      "title": 'Send Mail',
      "width": "90%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var hide = _ref3.hide;
        return [_c('SlideSendMail', {
          attrs: {
            "projectId": _vm.projectId,
            "id": _vm.id,
            "closeSlideSendMail": _vm.closeSlideSendMail
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideSendMail,
      callback: function callback($$v) {
        _vm.slideSendMail = $$v;
      },
      expression: "slideSendMail"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideHistoryMail",
      "title": 'History Mail',
      "width": "50%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var hide = _ref4.hide;
        return [_c('SlideHistoryMail', {
          attrs: {
            "id": _vm.id,
            "closeSlideHistoryMail": _vm.closeSlideHistoryMail
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideHistoryMail,
      callback: function callback($$v) {
        _vm.slideHistoryMail = $$v;
      },
      expression: "slideHistoryMail"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }