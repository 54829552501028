<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-4 p-0"
                  :active="true"
                  href="#crew"
                  title="CREW"
                />
                <tab-nav-items
                  class="col-md-4 p-0"
                  :active="false"
                  href="#candidate"
                  title="CANDIDATE"
                />
                <tab-nav-items
                  class="col-md-4 p-0"
                  :active="false"
                  href="#blacklist"
                  title="BLACK LIST"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <!-- CREW -->
            <tab-content-item :active="true" id="crew">
              <b-row class="mb-4">
                <b-col md="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="bg-danger rounded">
                    <template v-slot:body>
                      <a href="#" @click.prevent="openSlideCrewExpired">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="rounded-circle iq-card-icon bg-white"><i class="las la-users text-danger"></i></div>
                          <div class="text-right">
                            <div class="rounded-circle iq-card-icon bg-white text-danger " v-if="loadingCrewExpired"><span class="spinner-border spinner-border-lg"></span></div>
                            <h2 class="mb-0 text-white font-weight-bold " v-else><span class="counter">{{ expiredCrewData?.crew_status_expired ?? 0 }}</span></h2>
                            <h5 class=" text-white">Expired Crew</h5>
                          </div>
                        </div>
                      </a>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="bg-danger rounded">
                    <template v-slot:body>
                      <a href="#" @click.prevent="openSlideCrewMonthExpired">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="rounded-circle iq-card-icon bg-white"><i class="las la-id-card-alt text-danger"></i></div>
                          <div class="text-right">
                            <div class="rounded-circle iq-card-icon bg-white text-danger " v-if="loadingCrewExpired"><span class="spinner-border spinner-border-lg"></span></div>
                            <h2 class="mb-0 text-white font-weight-bold " v-else><span class="counter">{{ expiredCrewData?.crew_status_month_expired ?? 0 }}</span></h2>
                            <h5 class=" text-white">1 Month Expired Crew</h5>
                          </div>
                        </div>
                      </a>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="bg-danger rounded">
                    <template v-slot:body>
                      <a href="#" @click.prevent="openSlideCocCopExpired('COC')">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="rounded-circle iq-card-icon bg-white"><i class="fa fa-file text-danger"></i></div>
                          <div class="text-right">
                            <div class="rounded-circle iq-card-icon bg-white text-danger " v-if="loadingCopCocExpired || loadingCrewExpired"><span class="spinner-border spinner-border-lg"></span></div>
                            <h2 class="mb-0 text-white font-weight-bold " v-else><span class="counter">{{ expiredCopCocData?.coc_count_expired ?? 0 }}</span></h2>
                            <h5 class=" text-white">Expired COC</h5>
                          </div>
                        </div>
                      </a>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="bg-danger rounded">
                    <template v-slot:body>
                      <a href="#" @click.prevent="openSlideCocCopExpired('COP')">
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="rounded-circle iq-card-icon bg-white"><i class="fa fa-file text-danger"></i></div>
                          <div class="text-right">
                            <div class="rounded-circle iq-card-icon bg-white text-danger " v-if="loadingCopCocExpired || loadingCrewExpired"><span class="spinner-border spinner-border-lg"></span></div>
                            <h2 class="mb-0 text-white font-weight-bold " v-else><span class="counter">{{ expiredCopCocData?.cop_count_expired ?? 0 }}</span></h2>
                            <h5 class=" text-white">Expired COP</h5>
                          </div>
                        </div>
                      </a>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary"
                    ><strong>LIST CREW</strong></h5
                  >
                </template>
                <template v-slot:headerAction>
                <div class="d-flex align-items-center">
                  <b-button variant="success" class="mr-3" @click="exportCrewList()" :disabled="crewList.loading">
                    <span
                      v-if="crewList.loading"
                      class="spinner-border spinner-border-sm"
                    />
                    <div v-else class="d-flex gap-2 align-items-center"> 
                      <i class="fa fa-file-excel"></i> 
                      <span>Export</span>
                    </div>
                  </b-button>
                  <router-link to="/crewing/add-crew">
                    <b-button variant="primary" class="mr-3"
                      ><i class="fa fa-users"></i> &nbsp; Add Crew</b-button
                    >
                  </router-link>
                  <a
                    class="text-primary float-right"
                    v-b-toggle.collapse-filter
                    role="button"
                    style="cursor: pointer"
                  >
                    <i class="fa fa-filter" />
                  </a>
                </div>
                </template>
                <template v-slot:body>
                  <b-collapse visible id="collapse-filter" class="mb-2">
                    <b-row>
                      <b-col md="3">
                        <b-form-group label="Search" label-for="search">
                          <b-form-input
                            v-model="crewList.params.fullname"
                            type="text"
                            placeholder="Ketikan Nama"
                            :disabled="crewList.loading"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Fleet" label-for="Fleet">
                          <v-select
                            v-model="crewList.params.vehicle_id"
                            label="text"
                            :options="fleetOptions"
                            :reduce="
                              (fleetOptions) => fleetOptions.value
                            "
                            placeholder="Select Kapal"
                            :disabled="crewList.loading"
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Jabatan" label-for="jabatan">
                          <b-form-select
                            plain
                            v-model="crewList.params.position_id"
                            :options="optionsPosition"
                            size="lg"
                            :disabled="crewList.loading"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Pilih Jabatan...</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Status" label-for="status">
                          <b-form-select
                            plain
                            v-model="crewList.params.status"
                            :options="optionsEmployeeStatusFilter"
                            size="lg"
                            :disabled="crewList.loading"
                            placeholder="Pilih status crew..."
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Pilih Status...</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Join on Board" label-for="Join on Board">
                          <date-picker
                            @change="onCrewChangeDateJob"
                            v-model="dateRangeJob"
                            type="date"
                            range
                            placeholder="Select date range"
                            :disabled="crewList.loading"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Sign Off" label-for="Sign Off">
                          <date-picker
                            @change="onCrewChangeDate"
                            v-model="dateRange"
                            type="date"
                            range
                            placeholder="Select date range"
                            :disabled="crewList.loading"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="3"></b-col>
                      <b-col md="1">
                        <b-form-group label="Filter" label-for="button_search">
                          <b-button
                            @click="filterCrewList"
                            variant="primary"
                            class="mb-3 mr-1"
                            :disabled="crewList.loading"
                            ><i class="fa fa-filter"></i
                          ></b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <div class="d-flex align-items-center mb-2">
                    <b-button variant="primary" type="button" class="mr-2 p-2" @click="openSlideListPkl" :disabled="loadingCopCocExpired || loadingCrewExpired"><i class="fa fa-users"></i> &nbsp; List of Crew PKL Not Complete</b-button>
                    <b-button variant="primary" type="button" class="mr-2 p-2" @click="openSlideNoticeSignOff" :disabled="loadingCopCocExpired || loadingCrewExpired"><i class="fa fa-users"></i> &nbsp; List of Notice Sign Off Crew</b-button>
                    <b-button variant="primary" type="button" class="p-2" style="margin-top:0px" @click="openSlideListBirthday" :disabled="loadingCopCocExpired || loadingCrewExpired"><i class="fa fa-users"></i> &nbsp; List Birthday Crew</b-button>
                  </div>
                  <div class="d-flex align-items-end justify-content-end">
                    <span class="mr-1">Total Crew: </span> <strong>{{ crewList.meta.total  }}</strong> 
                  </div>
                  <!-- TABLE CONTENT CREWLIST -->
                  <TableContentCrewList
                    :table-data="crewList.data"
                    :indexes="crewList.meta.indexes"
                    v-model="form_crew"
                    :current-page="crewList.meta.currentPage"
                    :isPreview="false"
                  />
                  <div class="mt-3" v-if="crewList.data.body.length > 0">
                    <b-pagination
                      v-model="crewList.meta.currentPage"
                      :total-rows="crewList.meta.total"
                      :per-page="crewList.meta.perPage"
                      first-number
                      @change="getCrewList"
                      align="center"
                    />
                  </div>
                  <p v-else class="text-center my-4">List crew tidak ada.</p>
                </template>
              </iq-card>

              <!-- MODAL NOTICE SIGN OFF -->
              <b-modal
                id="notice_sign_off"
                size="md"
                title="Notice Sign Off"
                cancel-title="Close"
                hide-footer
              >
                <form
                  action="#"
                  method="post"
                  @submit="onSaveNoticeSignOff"
                >
                  <b-row>
                    <b-form-group class="col-sm-12" label="Reason Sign Off">
                      <v-select
                        v-model="notice_sign_off_form.reason_sign_off_id"
                        label="text"
                        :options="options_signoff_reasons"
                        :reduce="(options_signoff_reasons) => options_signoff_reasons.value"
                        placeholder="Select Type of Vehicle"
                        required
                      ></v-select>
                    </b-form-group>
                    <b-form-group class="col-sm-12" label="Notice Off">
                      <b-form-input
                        type="datetime-local"
                        id="join_onboard"
                        v-model="notice_sign_off_form.notice_off"
                        required
                      ></b-form-input>
                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                    </b-form-group>
                    <b-form-group class="col-sm-12" label="Detail Notice Off">
                      <b-form-textarea
                        type="text"
                        id="detail_notice_off"
                        v-model="notice_sign_off_form.detail_notice_off"
                        required
                        placeholder="Detail notice sign off..."
                      ></b-form-textarea>
                    </b-form-group>
                    <div class="form-group col-sm-12">
                      <label
                        label="attachment_notice_off"
                        for="attachment_notice_off"
                        ><span class="text-muted">Attachment File</span></label
                      >
                      <div>
                        <b-form-group  
                            label-for="attachment_notice_off">
                          <b-form-file
                            id="attachment_notice_off"
                            v-model="notice_sign_off_form.attachment_notice_off"
                            accept="image/*,application/*"
                          ></b-form-file>
                          <small class="text-muted">
                            File Type * :
                            <b-badge variant="info"
                              >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                              .pdf</b-badge
                            >
                          </small>
                        </b-form-group>
                        <b-form-text class="text-muted" v-if="notice_sign_off_form?.attachment_notice_off?.name">
                          File Name: {{ notice_sign_off_form?.attachment_notice_off?.name }}
                        </b-form-text>
                      </div>
                    </div>
                  </b-row> 
                  <b-row>
                    <b-col :md="'6'">
                      <b-button
                        variant="secondary"
                        block
                        @click="$bvModal.hide('notice_sign_off')"
                      >Close</b-button>
                    </b-col>
                    <b-col md="6">
                      <b-button
                        variant="primary"
                        block
                        type="submit"
                        :disabled="notice_sign_off_loading"
                        ><i class="fa fa-file-signature" v-if="!notice_sign_off_loading"></i>
                        <span v-if="!notice_sign_off_loading">SUBMIT</span>
                        <span
                          v-if="notice_sign_off_loading"
                          class="spinner-border spinner-border-sm"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </form>
              </b-modal>
              <!-- MODAL SIGN OFF  END -->
            </tab-content-item>
            <!-- CANDIDATE -->
            <tab-content-item :active="false" id="candidate">
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary"
                    ><strong>CANDIDATE</strong></h5
                  >
                </template>
                <template v-slot:headerAction>
                <div class="d-flex align-items-center">
                  <b-button variant="success" class="mr-3" @click="exportCandidateList()" :disabled="candidateList.loading">
                    <span
                      v-if="candidateList.loading"
                      class="spinner-border spinner-border-sm"
                    />
                    <div v-else class="d-flex gap-2 align-items-center"> 
                      <i class="fa fa-file-excel"></i> 
                      <span>Export</span>
                    </div>
                  </b-button>
                  <router-link to="/crewing/add-candidate">
                    <b-button variant="primary" class="mr-3"
                      ><i class="fa fa-users"></i> &nbsp; Add Candidate</b-button
                    >
                  </router-link>
                  <a
                    class="text-primary float-right"
                    v-b-toggle.collapse-filter
                    role="button"
                    style="cursor: pointer"
                  >
                    <i class="fa fa-filter" />
                  </a>
                </div>
                </template>
                <template v-slot:body>
                  <b-collapse visible id="collapse-filter" class="mb-2">
                    <b-row>
                      <b-col md="2">
                        <b-form-group label="Search" label-for="search">
                          <b-form-input
                            v-model="candidateList.params.full_name"
                            type="text"
                            placeholder="Ketikan Nama"
                            :disabled="candidateList.loading"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Jabatan" label-for="jabatan">
                          <b-form-select
                            plain
                            v-model="candidateList.params.position_id"
                            :options="optionsPosition"
                            size="lg"
                            :disabled="candidateList.loading"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Pilih Jabatan...</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Status" label-for="status">
                          <b-form-select
                            plain
                            v-model="candidateList.params.status"
                            :options="statusCandidate"
                            size="lg"
                            :disabled="candidateList.loading"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="''"
                                >Pilih Status...</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group
                          label="Jadwal Interview"
                          label-for="jadawal-interview"
                        >
                          <date-picker
                            @change="onCandidateChangeDate"
                            v-model="dateRange"
                            type="datetime"
                            range
                            placeholder="Select date range"
                            :disabled="candidateList.loading"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                     
                      <b-col md="1">
                        <b-form-group label="Filter" label-for="button_search">
                          <b-button
                            @click="filterCandidateList"
                            variant="primary"
                            class="mb-3 mr-1"
                            :disabled="candidateList.loading"
                            ><i class="fa fa-filter"></i
                          ></b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <div class="d-flex align-items-center my-2">
                    <span class="mr-1">Total Candidate: </span> <strong>{{ candidateList.meta.total  }}</strong> 
                  </div>
                  <!-- TABLE CONTENT CANDIDATELIST -->
                  <TableContentCandidateList
                    :table-data="candidateList.data"
                    :indexes="candidateList.meta.indexes"
                    v-model="form_candidate"
                    :current-page="candidateList.meta.currentPage"
                  />
                  <div class="mt-3" v-if="candidateList.data.body.length > 0">
                    <b-pagination
                      v-model="candidateList.meta.currentPage"
                      :total-rows="candidateList.meta.total"
                      :per-page="candidateList.meta.perPage"
                      first-number
                      @change="getCandidateList"
                      align="center"
                    />
                  </div>
                  <p v-else class="text-center my-4"
                    >List candidate tidak ada.</p
                  >
                  <!-- SET INTERVIEW -->
                  <b-modal
                    id="setup-interview"
                    size="lg"
                    title="Set Interview"
                    cancel-title="Close"
                    hide-footer
                  >
                    <form action="#" method="post" @submit="onSaveSetInterview">
                      <b-row class="mb-3">
                        <b-col md="6">
                          <b-form-group
                            label="Expert User *"
                            label-for="expert_user"
                          >
                            <v-select
                              v-model="
                                form.setInterviewCandidate.expert_user_id
                              "
                              label="text"
                              :options="optionsExpertUsers"
                              :reduce="
                                (optionsExpertUsers) => optionsExpertUsers.value
                              "
                              placeholder="Select Type of Expert User"
                              required
                            ></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            label="Interview Date & Time"
                            label-for="interview_date"
                          >
                            <date-picker
                              required
                              v-model="
                                form.setInterviewCandidate.date_interview
                              "
                              type="datetime"
                              placeholder="Select date range"
                              format="DD-MM-YYYY HH:mm"
                            ></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            label="for Position *"
                            label-for="expert_user"
                          >
                            <b-form-select
                              plain
                              v-model="form.setInterviewCandidate.position_id"
                              :options="optionsPosition"
                              size="lg"
                              required
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select Position</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Lokasi" label-for="expert_user">
                            <b-form-input
                              type="text"
                              v-model="form.setInterviewCandidate.location"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Urgensi"
                            label-for="interview_date"
                          >
                            <template v-for="(item, index) in typeInterview">
                              <b-form-radio
                                v-model="form.setInterviewCandidate.urgensi"
                                class="custom-radio-color-checked"
                                inline
                                :color="item.color"
                                name="color"
                                :key="index"
                                :value="item.value"
                                :disabled="item.disabled"
                                required
                                ><strong
                                  ><h5>{{ item.label }}</h5></strong
                                ></b-form-radio
                              >
                            </template>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Notes (Candidate)"
                            label-for="interview_date"
                          >
                            <wysiwyg
                              v-model="
                                form.setInterviewCandidate.notes_set_interview
                              "
                              required
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Notes (Interviewer)"
                            label-for="interview_date"
                          >
                            <wysiwyg
                              v-model="
                                form.setInterviewCandidate.notes_set_interview_user
                              "
                              required
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <h3>Eskalasi Notification User</h3>
                          <hr />
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Expert User *"
                            label-for="expert_user"
                          >
                            <div
                              class="mb-3 align-content-center w-auto d-flex"
                              v-for="(state, index) in form
                                .setInterviewCandidate.notification"
                              v-bind:key="index"
                            >
                              <v-select
                                v-model="state.expert_user_id"
                                class="w-100 mr-2"
                                label="text"
                                :options="optionsExpertUsers"
                                :reduce="
                                  (optionsExpertUsers) =>
                                    optionsExpertUsers.value
                                "
                                placeholder="Select Type of Expert User"
                                required
                              ></v-select>
                              <b-button
                                class="w-20"
                                v-if="
                                  form.setInterviewCandidate.notification
                                    .length > 1 && index > 0
                                "
                                variant="danger"
                                size="sm"
                                @click="() => deleteNotificationUser(index)"
                                ><i class="fa fa-trash"></i>
                              </b-button>
                            </div>
                          </b-form-group>
                          <b-button
                            block
                            variant="info"
                            size="md"
                            @click="addNotificationUser"
                            ><i class="fa fa-plus"></i> Add More Data
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col :md="'6'">
                          <b-button
                            variant="secondary"
                            block
                            @click="$bvModal.hide('setup-interview')"
                            >Close</b-button
                          >
                        </b-col>
                        <b-col md="6">
                          <b-button
                            variant="primary"
                            block
                            type="submit"
                            :disabled="saving"
                            ><i class="fa fa-file-signature" v-if="!saving"></i>
                            <span v-if="!saving">SUBMIT</span>
                            <span
                              v-if="saving"
                              class="spinner-border spinner-border-sm"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </form>
                  </b-modal>
                  <!-- SET INTERVIEW END -->
                  <!-- MODAL VERIFICATION DOCUMENT -->
                  <b-modal
                    id="verification_document"
                    size="md"
                    title="Verification Document"
                    cancel-title="Close"
                    hide-footer
                  >
                    <form
                      action="#"
                      method="post"
                      @submit="onSaveVerificationDocument"
                    >
                      <b-row>
                        <b-col md="12">
                          <table class="table table-striped">
                            <tbody>
                              <tr class="bg-info">
                                <td colspan="2" class="text-center"
                                  ><strong>LIST DOCUMENT</strong></td
                                >
                              </tr>
                              <tr
                                v-for="(state, index) in master_list_document"
                                v-bind:key="index"
                              >
                                <td width="90%"
                                  ><strong>{{
                                    state?.document_name
                                  }}</strong></td
                                >
                                <td width="10%">
                                  <i
                                    v-if="state?.status_checklist === 1"
                                    class="fa fa-check text-primary"
                                  ></i>
                                  <i v-else class="fa fa-ban text-primary"></i>
                                </td>
                              </tr>
                              <tr class="bg-info">
                                <td colspan="2" class="text-center"
                                  ><strong>VERIFICATION KEMENHUB</strong></td
                                >
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <strong>Seaferer code verification</strong>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <b-form-group  
                                      label-for="seaferer_code_verification">
                                    <b-form-file
                                      id="seaferer_code_verification"
                                      v-model="form_verification.seaferer_code_verification"
                                      accept="image/*,application/*"
                                      required
                                    ></b-form-file>
                                    <small class="text-muted">
                                      File Type * :
                                      <b-badge variant="info"
                                        >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                                        .pdf</b-badge
                                      >
                                    </small>
                                  </b-form-group>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <strong>Verification By</strong>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <b-form-group>
                                    <b-form-input
                                      type="text"
                                      name="verification_by"
                                      placeholder="verification by...."
                                      v-model="form_verification.verification_by"
                                      required
                                    ></b-form-input>
                                  </b-form-group>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                      <div
                        v-if="loading"
                        class="d-flex justify-content-center w-100 my-2"
                      >
                        <span class="spinner-border spinner-border-lg" />
                      </div>
                      <b-row>
                        <b-col :md="'6'">
                          <b-button
                            variant="secondary"
                            block
                            @click="$bvModal.hide('verification_document')"
                            >Close</b-button
                          >
                        </b-col>
                        <b-col md="6">
                          <b-button
                            variant="primary"
                            block
                            type="submit"
                            :disabled="saving"
                            ><i class="fa fa-file-signature" v-if="!saving"></i>
                            <span v-if="!saving">SUBMIT</span>
                            <span
                              v-if="saving"
                              class="spinner-border spinner-border-sm"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </form>
                  </b-modal>
                  <!-- MODAL VERIFICATION DOCUMENT END -->
                  <!-- MODAL INDUCTION -->
                  <b-modal
                    id="induction"
                    size="md"
                    title="Induction Checklist"
                    cancel-title="Close"
                    hide-footer
                  >
                    <form action="#" method="post" @submit="onSaveInduction">
                      <b-row
                        v-for="(state, index) in department_form"
                        v-bind:key="index"
                      >
                        <b-col md="12">
                          <b-card no-body class="mb-1">
                            <b-card-header
                              header-tag="header"
                              class="p-1"
                              role="tab"
                            >
                              <b-button
                                :disabled="
                                  state?.checked ||
                                  (state.department_id !== profile_department &&
                                    profile_department !== null)
                                    ? true
                                    : false
                                "
                                block
                                v-b-toggle="`accordion-` + index"
                                :variant="`${
                                  state?.checked ? 'success' : 'info'
                                }`"
                                :class="`${
                                  state?.checked &&
                                  `justify-content-between d-flex align-items-center`
                                }`"
                                ><span>{{ state?.name }}</span>
                                <i
                                  v-if="state?.checked"
                                  class="fas fa-check"
                                ></i>
                              </b-button>
                            </b-card-header>
                            <b-collapse
                              :id="`accordion-` + index"
                              :visible="false"
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <b-form-group
                                  label="Induksi Oleh"
                                  label-for="induction_by"
                                >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="induction_by"
                                    v-model="state.induction_by"
                                  />
                                </b-form-group>
                                <b-form-group
                                  label="Tanggal Induksi"
                                  label-for="induction_date"
                                >
                                  <input
                                    type="date"
                                    class="form-control"
                                    id="induction_date"
                                    v-model="state.induction_date"
                                  />
                                </b-form-group>
                                <b-form-group
                                  label="Attachment"
                                  label-for="attachment"
                                >
                                  <div class="custom-file">
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="validatedCustomFile"
                                      @change="
                                        (event) =>
                                          prefiewFilesInduction(event, state)
                                      "
                                      accept="image/*,application/*"
                                    />
                                    <label
                                      class="custom-file-label"
                                      for="validatedCustomFile"
                                      v-if="!state.attachment"
                                      >Browse file...</label
                                    >
                                    <label
                                      class="custom-file-label"
                                      for="validatedCustomFile"
                                      v-else
                                      >{{ state.attachment_label }}</label
                                    >
                                  </div>
                                </b-form-group>
                                <b-form-group label="Notes" label-for="notes">
                                  <b-form-textarea
                                    id="notes"
                                    name="notes"
                                    rows="3"
                                    v-model="state.notes"
                                  ></b-form-textarea>
                                </b-form-group>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </b-col>
                      </b-row>
                      <div
                        v-if="loading"
                        class="d-flex justify-content-center w-100 mt-3"
                      >
                        <span class="spinner-border spinner-border-lg" />
                      </div>

                      <b-row class="mt-3">
                        <b-col :md="'6'">
                          <b-button
                            variant="secondary"
                            block
                            @click="$bvModal.hide('induction')"
                            >Close</b-button
                          >
                        </b-col>
                        <b-col md="6">
                          <b-button
                            variant="primary"
                            block
                            type="submit"
                            :disabled="saving"
                            ><i class="fa fa-file-signature" v-if="!saving"></i>
                            <span v-if="!saving">SUBMIT</span>
                            <span
                              v-if="saving"
                              class="spinner-border spinner-border-sm"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </form>
                  </b-modal>
                  <!-- MODAL INDUCTION END -->
                  <!-- MEDICAL CHECKUP -->
                  <b-modal
                    id="medical-checkup"
                    size="md"
                    title="Medical Checkup"
                    cancel-title="Close"
                    hide-footer
                  >
                    <form
                      action="#"
                      method="post"
                      @submit="onSaveMedichalCheckup"
                    >
                      <b-row
                        v-for="(state, index) in medical_checkup"
                        v-bind:key="index"
                      >
                        <b-col md="12">
                          <div
                            v-if="medical_checkup.length > 1 && index > 0"
                            class="d-flex w-100 px-4 my-1 justify-content-end cursor-pointer position-absolute top-0"
                            @click="deleteMedicalCheckupForm(index)"
                          >
                            <i class="fa fa-trash text-danger"></i>
                          </div>
                          <b-form-group
                            label="Attachment"
                            label-for="attachment"
                          >
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="validatedCustomFile"
                                accept="image/*,application/*"
                                @change="(event) => prefiewFiles(event, state)"
                              />

                              <label
                                class="custom-file-label"
                                for="validatedCustomFile"
                                v-if="!state.file"
                                >Browse file...</label
                              ><label
                                class="custom-file-label"
                                for="validatedCustomFile"
                                v-else
                                >{{ state.label_file }}</label
                              >
                              <!-- this.medical_checkup -->
                            </div>
                          </b-form-group>
                          <b-form-group label="Notes" label-for="notes">
                            <b-form-textarea
                              id="notes"
                              name="notes"
                              rows="3"
                              v-model="state.nama_document"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row class="mb-3">
                        <b-col md="12">
                          <b-button
                            variant="success"
                            class="mt-1"
                            @click="addMedicalCheckupForm"
                            ><i class="fa fa-plus"></i> ADD FORM</b-button
                          >
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col :md="'6'">
                          <b-button
                            variant="secondary"
                            block
                            @click="$bvModal.hide('medical-checkup')"
                            >Close</b-button
                          >
                        </b-col>
                        <b-col md="6">
                          <b-button
                            variant="primary"
                            block
                            type="submit"
                            :disabled="saving"
                            ><i class="fa fa-file-signature" v-if="!saving"></i>
                            <span v-if="!saving">SUBMIT</span>
                            <span
                              v-if="saving"
                              class="spinner-border spinner-border-sm"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </form>
                  </b-modal>
                  <!-- MEDICAL CHECKUP END -->
                </template>
              </iq-card>
            </tab-content-item>
            <!-- BLACKLIST -->
            <tab-content-item :active="false" id="blacklist">
              <TabBlacklist :optionsPosition="optionsPosition" />
            </tab-content-item>

            <!-- FORM SIGN ON -->
            <b-sidebar
              id="sign-on"
              title="SIGN ON CANDIDATE"
              width="70%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
              ref="formSignOn"
            >
              <template #default="{ hide }">
                <form action="#" method="post" @submit="onSignOnSave">
                  <b-row class="m-3">
                    <b-col cols="12" class="text-right">
                      <b-button variant="primary" @click="() => handleDownloadPKL(candidateProfile?.id, candidateProfile?.nama_lengkap)"
                        ><i class="fa fa-download"></i> DOWNLOAD DOCUMENT
                        CONTRACT</b-button
                      >
                    </b-col>
                    <b-col md="12">
                      <!-- INFORMASI CANDIDATE -->
                      <fieldset
                        class="form-group border p-3"
                        v-if="currentStep === 0"
                      >&nbsp;
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >Informasi Crew</legend
                        >
                        <b-row>
                          <b-col md="6">
                              <div class="form-group row">
                                <label
                                  label="nama_crew"
                                  for="nama_crew"
                                  class="col-sm-6"
                                  ><strong>Nama Crew</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data
                                      ?.full_name ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label label="nik" for="nik" class="col-sm-6"
                                  ><strong>NIK</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data?.nik_ktp ??
                                    '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="jabatan"
                                  for="jabatan"
                                  class="col-sm-6"
                                  ><strong>Jabatan</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data?.position
                                      ?.name ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="seaman_book"
                                  for="seaman_book"
                                  class="col-sm-6"
                                  ><strong>Seaman Book</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data
                                      ?.seaman_book_number ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="seafarer_code"
                                  for="seafarer_code"
                                  class="col-sm-6"
                                  ><strong>Seaferer Code</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data
                                      ?.seaferer_code ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="passport_number"
                                  for="passport_number"
                                  class="col-sm-6"
                                  ><strong>Passport Number</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data
                                      ?.passport_number ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="file_contract"
                                  for="file_contract"
                                  class="col-sm-6"
                                  ><strong>Attachment File</strong></label
                                >
                                <div class="col-sm-6">
                                  <b-form-group  
                                      label-for="attachment">
                                    <b-form-file
                                      id="attachment"
                                      v-model="form_signon.attachment"
                                      accept="image/*,application/*"
                                    ></b-form-file>
                                    <small class="text-muted">
                                      File Type * :
                                      <b-badge variant="info"
                                        >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                                        .pdf</b-badge
                                      >
                                    </small>
                                  </b-form-group>
                                  <b-form-text class="text-muted">
                                    {{ form_signon?.attachment?.name }}
                                  </b-form-text>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label
                                  label="file_contract"
                                  for="file_contract"
                                  class="col-sm-6"
                                  ><strong>Notes</strong></label
                                >
                                <div class="col-sm-6">
                                  <input
                                    type="text"
                                    id="join_onboard"
                                    class="form-control"
                                    v-model="form_signon.remarks"
                                    placeholder="notes..."
                                    required
                                  />
                                </div>
                              </div>
                          </b-col>
                          <b-col md="6">
                            <div class="form-group row">
                              <label
                                label="perusahaan"
                                for="perusahaan"
                                class="col-sm-6"
                                ><strong>Perusahaan</strong></label
                              >
                              <b-form-group class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.company_id"
                                  :options="optionsCompanies"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                  @change="setBpjs"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Perusahaan</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </div>
                            <!-- <div class="form-group row">
                              <label
                                label="perusahaan"
                                for="perusahaan"
                                class="col-sm-6"
                                ><strong>Department</strong></label
                              >
                              <b-form-group class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.department_id"
                                  :options="optionsDepartment"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                  disabled
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Department</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </div> -->
                            <div class="form-group row">
                              <label
                                label="Jabatan"
                                for="jabatan"
                                class="col-sm-6"
                                ><strong>Jabatan</strong></label
                              >
                              <b-form-group class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.position_id"
                                  :options="optionsPosition"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Jabatan</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </div>
                            <div class="form-group row">
                              <label
                                label="nama_kapal"
                                for="nama_kapal"
                                class="col-sm-6"
                                ><strong>Nama Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                <v-select
                                  v-model="form_signon.vehicle_id"
                                  label="text"
                                  :options="fleetOptions"
                                  :reduce="
                                    (fleetOptions) => fleetOptions.value
                                  "
                                  placeholder="Pilih Kapal"
                                  required
                                ></v-select>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="nama_kapal"
                                for="nama_kapal"
                                class="col-sm-6"
                                ><strong>Work Area</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.work_area_id"
                                  :options="optionsWorkArea"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Work Area</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="pelabuhan"
                                for="pelabuhan"
                                class="col-sm-6"
                                ><strong>Pelabuhan</strong></label
                              >
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  id="pelabuhan"
                                  class="form-control"
                                  v-model="form_signon.port_id"
                                  placeholder="Pelabuhan..."
                                  required
                                />
                            
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="sign_on"
                                for="sign_on"
                                class="col-sm-6"
                                ><strong>Sign PKL</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-input
                                  type="date"
                                  id="sign_on"
                                  v-model="form_signon.sign_on"
                                  required
                                ></b-form-input>
                                <small style="float: right"
                                  >click <i class="fa fa-calendar"></i> to
                                  show</small
                                >
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="join_onboard"
                                for="join_onboard"
                                class="col-sm-6"
                                ><strong>Join Onboard</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-input
                                  type="datetime-local"
                                  id="join_onboard"
                                  v-model="form_signon.join_on_board"
                                  required
                                ></b-form-input>
                                <small style="float: right"
                                  >click <i class="fa fa-calendar"></i> to
                                  show</small
                                >
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="sign_off"
                                for="sign_off"
                                class="col-sm-6"
                                ><strong>Sign Off / Turun Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-input
                                  type="datetime-local"
                                  id="sign_off"
                                  v-model="form_signon.sign_off"
                                ></b-form-input>
                                <small style="float: right"
                                  >click <i class="fa fa-calendar"></i> to
                                  show</small
                                >
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </fieldset>
                      <!-- PAYROLL COMPONENT -->
                      <fieldset
                        class="form-group border p-3"
                        v-if="currentStep === 1"
                      >
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >Payroll Component</legend
                        >
                        <b-row>
                          <b-col md="12">
                            <div class="form-group row">
                              <label
                                label="basic_sallary"
                                for="basic_sallary"
                                class="col-sm-6"
                                ><strong>Sallary</strong></label
                              >
                              <div class="col-sm-6">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text bg-primary"
                                      id="rp"
                                      >Rp.</span
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    name="basic_sallary"
                                    class="form-control"
                                    id="basic_sallary"
                                    v-model="form_signon.basic_sallary"
                                    required
                                  />
                                </div>
                                <b-form-text class="text-muted">
                                  {{
                                    formatCurrency(form_signon.basic_sallary)
                                  }}
                                </b-form-text>
                              </div>
                            </div>

                            <!-- <div class="form-group row">
                              <label
                                label="overtime"
                                for="overtime"
                                class="col-sm-6"
                                ><strong>Overtime</strong></label
                              >
                              <div class="col-sm-6">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text bg-primary"
                                      id="rp"
                                      >Rp.</span
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    name="overtime"
                                    class="form-control"
                                    id="overtime"
                                    v-model="form_signon.overtime"
                                    required
                                  />
                                </div>
                                <b-form-text class="text-muted">
                                  {{ formatCurrency(form_signon.overtime) }}
                                </b-form-text>
                              </div>
                            </div>
                             -->

                          
                          </b-col>
                        </b-row>
                      </fieldset>
                      <!-- LEAVE PAY SETUP -->
                      <!-- <fieldset
                        class="form-group border p-3"
                        v-if="currentStep === 2"
                      >
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >Leave Pay Setup</legend
                        >
                        <b-row>
                          <b-col md="12">
                            <div
                              class="d-flex justify-content-between align-items-center w-100"
                            >
                              <legend style="font-size: 14pt"
                                >Leave Pay Setting</legend
                              >

                              <b-form-checkbox
                                id="leave_pay"
                                v-model="form_signon.leave_pay"
                                :value="1"
                                :unchecked-value="0"
                              >
                              </b-form-checkbox>
                            </div>

                            <div v-if="form_signon.leave_pay === 1">
                              <b-form-group
                                class="col-md-12"
                                label="Leave Pay Type"
                                label-for="leave_pay_type"
                              >
                                <b-form-select
                                  plain
                                  v-model="form_signon.leave_pay_type"
                                  size="sm"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="''" selected
                                      >Select Leave Pay
                                      Type</b-form-select-option
                                    >
                                    <b-form-select-option
                                      :value="'partial_scheme'"
                                      >Partial Schema</b-form-select-option
                                    >
                                    <b-form-select-option :value="'full_scheme'"
                                      >Full Schema</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </b-form-group>

                              <b-form-group
                                class="col-md-12"
                                label="Set Up Leave Pay Month"
                                label-for="setup_leave_pay_month"
                              >
                                <b-form-input
                                  type="number"
                                  id="setup_leave_pay_month"
                                  name="setup_leave_pay_month"
                                  placeholder="Leave Pay month Setup ..."
                                  v-model="form_signon.setup_leave_pay_month"
                                  required
                                ></b-form-input>
                                <small style="float: right"
                                  >Pilih Jabatan Dahulu untuk Kalkulasi Leave
                                  Pay Amount
                                </small>
                              </b-form-group>
                              <b-form-group
                                class="col-md-12"
                                label="Leave Pay Amount"
                                label-for="leave_pay_amount"
                              >
                                <b-form-input
                                  disabled
                                  type="number"
                                  id="leave_pay_amount"
                                  name="leave_pay_amount"
                                  placeholder="Leave Pay Amount ..."
                                  v-model="form_signon.leave_pay_amount"
                                  required
                                ></b-form-input>
                                <b-form-text class="text-muted">{{ formatCurrency(form_signon.leave_pay_amount) }}</b-form-text>
                              </b-form-group>
                            </div>
                          </b-col>
                        </b-row>
                      </fieldset> -->
                      <!-- BPJS SETTING -->
                      <fieldset
                        class="form-group border p-3"
                        v-if="currentStep === 2"
                      >
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >BPJS Setting</legend
                        >
                        <b-row>
                          <b-col md="6">
                            <div
                              class="d-flex justify-content-between align-items-center w-100"
                            >
                              <legend style="font-size: 14pt"
                                >BPJS Ketenagakerjaan</legend
                              >
                              <b-form-checkbox
                                id="bpjstk_config"
                                v-model="form_signon.bpjstk"
                                :value="1"
                                :unchecked-value="0"
                              >
                              </b-form-checkbox>
                            </div>
                            <div v-if="form_signon.bpjstk === 1">
                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>Basis Penggali</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-6">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.bpjstk_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.bpjstk_config === 1"
                                  >
                                    <b-form-select
                                      plain
                                      :options="optionsGaji"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.basispengali_bpjstk"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >Pilih Gaji</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>JHT Configuration</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-12 mb-2">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      required
                                      v-model="form_signon.jht_config"
                                    >
                                    </b-form-select>
                                  </div>

                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jht_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >JHT (DITANGGUNG PERUSAHAAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.jht_company_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jht_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >JHT (DITANGGUNG KARYAWAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.jht_employee_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>JP Configuration</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-12 mb-2">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      required
                                      v-model="form_signon.jp_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jp_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >JP (DITANGGUNG PERUSAHAAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.jp_company_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jp_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >JP (DITANGGUNG KARYAWAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.jp_employee_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>JKM Configuration</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-6">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.jkm_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jkm_config === 1"
                                  >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="form_signon.jkm_set_value"
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>JKK Configuration</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-6">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.jkk_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jkk_config === 1"
                                  >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="form_signon.jkk_set_value"
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col md="6">
                            <div
                              class="d-flex justify-content-between align-items-center w-100"
                            >
                              <legend style="font-size: 14pt"
                                >BPJS Kesehatan</legend
                              >

                              <b-form-checkbox
                                id="bpjsksh_config"
                                v-model="form_signon.bpjsksh"
                                :value="1"
                                :unchecked-value="0"
                              >
                              </b-form-checkbox>
                            </div>

                            <div v-if="form_signon.bpjsksh === 1">
                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>Basis Penggali</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-6">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="
                                        form_signon.basispengali_bpjsksh_config
                                      "
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="
                                      form_signon.basispengali_bpjsksh_config ===
                                      1
                                    "
                                  >
                                    <b-form-select
                                      plain
                                      :options="optionsGaji"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.basispengali_bpjsksh"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >Pilih Gaji</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>BPJS Configuration</strong></label
                                >

                                <div class="px-2 row">
                                  <div class="col-sm-12 mb-2">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      required
                                      v-model="form_signon.bpjsksh_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.bpjsksh_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >BPJS KSH (DITANGGUNG PERUSAHAAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.bpjsksh_company_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.bpjsksh_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >BPJS KSH (DITANGGUNG KARYAWAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.bpjsksh_employee_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </fieldset>
                      <!-- ONSAVE -->
                      <b-row>
                        <b-col md="4"
                          ><b-button
                            :variant="`${
                              currentStep === 0 ? 'secondary' : 'primary'
                            }`"
                            :disabled="currentStep === 0"
                            block
                            @click="prevStep"
                            type="button"
                            ><i class="fa fa-chevron-left"></i> PREVIOUS FORM
                          </b-button>
                        </b-col>
                        <b-col md="8" v-if="currentStep !== 2"
                          ><b-button variant="primary" block type="submit">
                            NEXT FORM
                            <i class="fa fa-chevron-right"></i> </b-button
                        ></b-col>
                        <b-col md="8" v-if="currentStep === 2"
                          ><b-button
                            :disabled="saving"
                            variant="primary"
                            block
                            type="submit"
                          >
                            <span
                              v-if="saving"
                              class="spinner-border spinner-border-sm"
                            />
                            <i v-if="!saving" class="fa fa-file-signature"></i>
                            <span v-if="!saving">SUBMIT</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </form>
              </template>
            </b-sidebar>
            <!-- FORM SIGN ON END -->

            <!-- FORM SWAP POSITION  -->
            <b-sidebar
              id="swap-position"
              title="SWAP POSITION"
              width="50%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
            >
              <template #default="{ hide }">
                <form action="#" method="post" @submit="onSaveSwapPosition">
                  <b-row>
                    <b-col md="12">
                      <b-card no-body class="mb-1">
                        <b-card-body>
                          <b-row>
                            <b-col lg="12">
                              <b-form-group label="Vehicles" label-for="vehicle_id">
                                <v-select
                                  v-model="swap_position.vehicle_id"
                                  label="text"
                                  :options="fleetOptions"
                                  :reduce="(fleetOptions) => fleetOptions.value"
                                  placeholder="Select Type of Vehicle"
                                  required
                                ></v-select>
                              </b-form-group>
                            </b-col>

                            <b-col lg="12">
                              <b-form-group
                                label="Join on Board"
                                label-for="join_on_board"
                              >
                                <b-form-input
                                  type="datetime-local"
                                  name="join_on_board"
                                  v-model="swap_position.join_on_board"
                                  required
                                ></b-form-input>
                                <small style="float: right"
                                  >click <i class="fa fa-calendar"></i> to show
                                </small>
                              </b-form-group>
                            </b-col>   
                            
                            <b-col lg="12">
                              <b-form-group
                                label="Sign On"
                                label-for="start_date"
                              >
                                <b-form-input
                                  type="datetime-local"
                                  name="start_date"
                                  v-model="swap_position.start_date"
                                ></b-form-input>
                                <small style="float: right"
                                  >click <i class="fa fa-calendar"></i> to show
                                </small>
                              </b-form-group>
                            </b-col>

                            <b-col lg="12">
                              <b-form-group label="Position/Jabatan" label-for="position_id">
                                <v-select
                                  v-model="swap_position.position_id"
                                  label="text"
                                  :options="optionsPosition"
                                  :reduce="(optionsPosition) => optionsPosition.value"
                                  placeholder="Select Position"
                                  required
                                ></v-select>
                              </b-form-group>
                            </b-col>

                            <b-col lg="12">
                              <b-form-group label="Company" label-for="company_id">
                                <b-form-select
                                  plain
                                  v-model="swap_position.company_id"
                                  size="sm"
                                  :options="optionsCompanies"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option
                                      :value="''"
                                    >Select Company</b-form-select-option>
                                  </template>
                                </b-form-select> </b-form-group
                            ></b-col>
                            <b-col lg="12">
                              <b-form-group label="Port" label-for="port_id">
                                <v-select
                                  v-model="swap_position.port_id"
                                  label="text"
                                  :options="optionsPorts"
                                  :reduce="(optionsPorts) => optionsPorts.value"
                                  placeholder="Select port..."
                                  required
                                ></v-select>
                              </b-form-group>
                            </b-col>

                            <b-col lg="12">
                              <b-form-group
                                label="Attachment"
                                label-for="attachment"
                              >
                                <div class="custom-file">
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    id="validatedCustomFile"
                                    accept="application/*,image/png,image/jpeg,image/jpg"
                                    @change="(event) => prefiewFiles(event, swap_position)"
                                  />
                                  <label
                                    class="custom-file-label"
                                    for="validatedCustomFile"
                                    v-if="!swap_position.file"
                                    >Browse file...</label
                                  >
                                  <label
                                    class="custom-file-label"
                                    for="validatedCustomFile"
                                    v-else
                                    >{{ swap_position.file_label }}</label
                                  >
                                </div>
                                <small class="text-muted">
                                  File Type * :
                                  <b-badge variant="info"
                                    >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                                    .pdf</b-badge
                                  >
                                  <br />
                                  File CapaKota * :
                                  <b-badge variant="info">10 MB / File</b-badge>
                                </small>
                              </b-form-group>
                            </b-col>

                            <b-col lg="12">
                              <b-form-group
                                type="textarea"
                                label="Notes"
                                label-for="remarks"
                              >
                                <b-form-textarea
                                  class="form-control"
                                  id="remarks"
                                  name="remarks"
                                  rows="3"
                                  v-model="swap_position.remarks"

                                ></b-form-textarea>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                    </b-col>


                    <b-col md="12" class="p-4">
                      <b-row class="mt-3">
                        <b-col md="6">
                          <b-button variant="secondary" block @click="hideModal"
                            >Close</b-button
                          >
                        </b-col>
                        <b-col md="6">
                          <b-button variant="primary" block type="submit" :disabled="saving"
                            ><i class="fa fa-edit" v-if="!saving"></i>
                            <span v-if="!saving">SUBMIT</span>
                            <span v-if="saving" class="spinner-border spinner-border-sm" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </form>
              </template>
            </b-sidebar>
            <!-- FORM SWAP POSITION END -->

            <!-- FORM SIGN OFF -->
            <b-sidebar
              id="sign-off"
              title="SIGN OFF CREW"
              width="50%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
            >
              <template #default="{ hide }">
                <form action="#" method="post" @submit="onSaveSignOffCrew">
                  <b-row class="m-3">
                    <b-col md="12">
                      <fieldset class="form-group border p-3">
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >Informasi Crew</legend
                        >
                        <b-row>
                          <b-col md="6">
                            <div class="form-group row">
                              <label
                                label="nama_crew"
                                for="nama_crew"
                                class="col-sm-6"
                                ><strong>Nama Crew</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.fullname ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label label="nik" for="nik" class="col-sm-6"
                                ><strong>NIK</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.no_ktp ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="jabatan"
                                for="jabatan"
                                class="col-sm-6"
                                ><strong>Jabatan</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.position?.name ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="seaman_book"
                                for="seaman_book"
                                class="col-sm-6"
                                ><strong>Seaman Book</strong></label
                              >
                              <div class="col-sm-6">
                                {{
                                  crewProfile?.personal_data
                                    ?.seaman_book_number ?? '-'
                                }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="seafarer_code"
                                for="seafarer_code"
                                class="col-sm-6"
                                ><strong>Seafarer Code</strong></label
                              >
                              <div class="col-sm-6">
                                {{
                                  crewProfile?.personal_data?.seaferer_code ??
                                  '-'
                                }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="passport_number"
                                for="passport_number"
                                class="col-sm-6"
                                ><strong>Passport Number</strong></label
                              >
                              <div class="col-sm-6">
                                {{
                                  crewProfile?.personal_data?.passport_number ??
                                  '-'
                                }}
                              </div>
                            </div>
                          </b-col>
                          <b-col md="6">
                            <div class="form-group row">
                              <label
                                label="perusahaan"
                                for="perusahaan"
                                class="col-sm-6"
                                ><strong>Perusahaan</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.company?.company ?? '-' }}</div
                              >
                            </div>
                            <div class="form-group row">
                              <label
                                label="nama_kapal"
                                for="nama_kapal"
                                class="col-sm-6"
                                ><strong>Nama Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.vehicle?.name ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="pelabuhan"
                                for="pelabuhan"
                                class="col-sm-6"
                                ><strong>Pelabuhan</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.port?.port_name ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="naik_kapal"
                                for="naik_kapal"
                                class="col-sm-6"
                                ><strong>Sign On / Naik Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.sign_on ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="turun_kapal"
                                for="turun_kapal"
                                class="col-sm-6"
                                ><strong>Sign Off / Turun Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-input
                                  type="datetime-local"
                                  name="sign_off"
                                  v-model="form_signoff_crew.sign_off"
                                  required
                                ></b-form-input>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="turun_kapal"
                                for="turun_kapal"
                                class="col-sm-6"
                                ><strong>Status EMP Crewing</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signoff_crew.status_emp_crewing_id"
                                  :options="optionsEmployeeStatus"
                                  size="sm"
                                  required
                                  name="status_emp_crewing_id"
                                  @change="() => {
                                    form_signoff_crew.status_emp_crewing_id === 4 ? 
                                    master_signoff_reasons = master_signoff_reasons.map(val => {
                                      return{
                                        ...val,
                                        disabled: val.id === 7 ? false : true,
                                        checked: val.id === 7 ? true : false
                                      }
                                    }) : 
                                    master_signoff_reasons = master_signoff_reasons.map(val => {
                                      return{
                                        ...val,
                                        disabled:false,
                                        checked:false
                                      }
                                    })
                                  }"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option  :value="null"
                                      >Select Status Crew</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </fieldset>

                      <fieldset class="form-group border p-3">
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >Reason Sign Off</legend
                        >
                        <table class="table table-striped">
                          <thead>
                            <tr class="text-center">
                              <th width="90%">DESKRIPSI</th>
                              <th width="10%">STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(state, index) in master_signoff_reasons"
                              v-bind:key="index"
                            >
                              <th> {{ state.name }}</th>
                              <td class="text-center">
                                <template>
                                  <b-form-checkbox
                                    v-model="state.checked"
                                    name="check-button"
                                    class="ml-4"
                                    switch
                                    inline
                                    :disabled="state.disabled"
                                    @change="
                                      selectStatusSignOffCrew(state, index)
                                    "
                                  >
                                  </b-form-checkbox>
                                </template>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <fieldset
                          class="form-group border p-3"
                          v-if="master_signoff_reasons?.[6]?.checked"
                        >
                          <legend class="w-auto px-2 text-primary"
                            >Reason Black List</legend
                          >
                          <b-form-group>
                            <b-form-select
                              plain
                              v-model="form_signoff_crew.reason_blacklist_id"
                              :options="optionBlacklistReasons"
                              size="lg"
                              aria-placeholder="Pilih Alasan"
                              required
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Pilih Alasan</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </b-form-group>
                          <b-form-textarea
                            rows="3"
                            value="Notes"
                            v-model="form_signoff_crew.remarks"
                            placeholder="Notes..."
                          ></b-form-textarea>
                        </fieldset>

                        <b-form-textarea
                          v-else
                          placeholder="Notes..."
                          id="remarks"
                          v-model="form_signoff_crew.remarks"
                        ></b-form-textarea>
                      </fieldset>
                      <b-col cols="12" class="text-right"
                        ><span
                          ><b-button
                            variant="primary"
                            block
                            type="submit"
                            :disabled="saving"
                            ><i class="fa fa-file-signature" v-if="!saving"></i>
                            <span v-if="!saving">SUBMIT</span>
                            <span
                              v-if="saving"
                              class="spinner-border spinner-border-sm"
                            /> </b-button></span
                      ></b-col>
                    </b-col>
                  </b-row>
                </form>
              </template>
            </b-sidebar>
            <!-- FORM SIGN OFF END -->

            <!-- FORM BLACK LIST  -->
            <b-sidebar
              id="blacklist-crew"
              title="BLACK LIST"
              width="50%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
            >
              <template #default="{ hide }">
                <b-row class="m-3">
                  <b-col md="12">
                    <fieldset
                      class="form-group border p-3"
                      v-if="form_crew.key === 'crew'"
                    >
                      <legend class="w-auto px-2 text-primary"
                        >Informasi Crew</legend
                      >
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <td width="25%"><strong>Nama Crew</strong></td>
                            <td width="25%">{{
                              crewProfile?.fullname ?? '-'
                            }}</td>
                            <td width="25%"><strong>Perusahaan</strong></td>
                            <td width="25%">{{
                              crewProfile?.company?.company ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Jabatan</strong></td>
                            <td width="25%">{{
                              crewProfile?.position?.name ?? '-'
                            }}</td>
                            <td width="25%"><strong>Pelabuhan</strong></td>
                            <td width="25%">{{
                              crewProfile?.port?.port_name ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>NIK</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.nik_ktp ?? '-'
                            }}</td>
                            <td width="25%"><strong>Nama Kapal</strong></td>
                            <td width="25%">{{
                              crewProfile?.vehicle?.name ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Nomor Pegawai</strong></td>
                            <td width="25%">{{ crewProfile?.nip ?? '-' }}</td>
                            <td width="25%"><strong>Sign On</strong></td>
                            <td width="25%">{{
                              crewProfile?.sign_on ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Seaferer Code</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.seaferer_code ?? '-'
                            }}</td>
                            <td width="25%"><strong>Sign Off</strong></td>
                            <td width="25%">{{
                              crewProfile?.sign_off ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Nomor NPWP</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.npwp_number ?? '-'
                            }}</td>
                            <td width="25%"><strong>No. Tlp / Hp</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.hp_number ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Nomor Passport</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.passport_number ?? '-'
                            }}</td>
                            <td width="25%"><strong>Email</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.email ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"
                              ><strong>Nomor Seamanbook</strong></td
                            >
                            <td width="25%">{{
                              crewProfile?.personal_data?.seaman_book_number ??
                              '-'
                            }}</td>
                            <td width="25%"
                              ><strong>Kewarganegaraan</strong></td
                            >
                            <td width="25%">{{
                              crewProfile?.personal_data?.citizenship ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Alamat</strong></td>
                            <td colspan="3">{{
                              crewProfile?.candidate?.alamat_ktp?.[0]?.alamat ??
                              '-'
                            }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>

                    <fieldset
                      class="form-group border p-3"
                      v-if="form_candidate.key === 'candidate'"
                    >
                      <legend class="w-auto px-2 text-primary"
                        >Informasi Candidate</legend
                      >
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <td width="25%"><strong>Nama Candidate</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.full_name ?? '-'
                            }}</td>
                            <td width="25%"><strong>Nomor Passport</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data
                                ?.passport_number ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Jabatan</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.position?.name
                            }}</td>
                            <td width="25%"
                              ><strong>Nomor Seamanbook</strong></td
                            >
                            <td width="25%">{{
                              candidateProfile?.personal_data
                                ?.seaman_book_number ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>NIK</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.nik_ktp ?? '-'
                            }}</td>
                            <td width="25%"><strong>Apply Date</strong></td>
                            <td width="25%">{{
                              candidateProfile?.apply_date ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Seaferer Code</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.seaferer_code ??
                              '-'
                            }}</td>
                            <td width="25%"><strong>No. Tlp / Hp</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.hp_number ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Nomor NPWP</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.npwp_number ??
                              '-'
                            }}</td>
                            <td width="25%"><strong>Email</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.email ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Alamat</strong></td>
                            <td colspan="3">{{
                              candidateProfile?.alamat_ktp?.[0]?.alamat ?? '-'
                            }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>

                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary"
                        >Reason Black List</legend
                      >
                      <b-form-group>
                        <b-form-select
                          plain
                          v-model="form.blacklist.reason_off"
                          :options="optionBlacklistReasons"
                          size="lg"
                          aria-placeholder="Pilih Alasan"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null"
                              >Pilih Alasan</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                      <b-form-textarea
                        rows="3"
                        name="remarks"
                        v-model="form.blacklist.remarks"
                        required
                        placeholder="Remarks..."
                      ></b-form-textarea>
                    </fieldset>
                    <b-col cols="12" class="text-right"
                      ><span
                        ><b-button
                          variant="primary"
                          block
                          @click="onSaveBlacklist"
                          :disabled="loading"
                          ><i class="fa fa-file-signature"></i> SUBMIT
                        </b-button></span
                      ></b-col
                    >
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- FORM BLACK LIST END -->

            <!-- FORM MEDICAL CHECKUP -->
            <b-sidebar
              id="medical-checkup"
              title="MEDICAL CHECKUP"
              width="50%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
            >
              <template #default="{ hide }">
                <b-row class="m-3">
                  <b-col md="12">
                    <fieldset class="form-group border p-3 iq-bg-info">
                      <legend class="w-auto px-2" style="font-size: 14pt"
                        ><strong>Medical Check Up</strong></legend
                      >
                      <b-form-row>
                        <b-col>
                          <b-form-group
                            label="Check Up Name"
                            label-for="checkup_name"
                          >
                            <b-form-input
                              type="text"
                              id="checkup_name"
                              placeholder="Check Up Name"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Expired Date"
                            label-for="expired_date"
                          >
                            <b-form-input
                              type="date"
                              id="expired_date"
                            ></b-form-input>
                            <small style="float: right"
                              >click <i class="fa fa-calendar"></i> to
                              show</small
                            >
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="File Check Up"
                            label-for="file_medical_checkup"
                          >
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="validatedCustomFile"
                                required
                              />
                              <label
                                class="custom-file-label"
                                for="validatedCustomFile"
                                >Browse file...</label
                              >
                            </div>
                            <small class="text-muted">
                              File Type * :
                              <b-badge variant="info">.pdf / Maks 3 MB</b-badge>
                            </small>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-button variant="success" class="mb-3 mr-1"
                        ><i class="fa fa-plus pr-0"></i> Add Document</b-button
                      >
                      <b-button variant="danger" class="mb-3 mr-1"
                        ><i class="fa fa-minus pr-0"></i> Delete
                        Document</b-button
                      >
                    </fieldset>
                    <b-button variant="primary" block
                      ><i class="fa fa-file-signature"></i> SUBMIT
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- FORM MEDICAL CHECKUP END -->

            <!-- FORM BLACK LIST  -->
            <b-modal
              id="delete-crew"
              size="md"
              title="HAPUS CREW"
              cancel-title="Close"
              hide-footer
            >
              <template #default="{ hide }">
                <b-row >
                  <b-col md="12">
                    <b-form-textarea
                      rows="3"
                      name="remarks"
                      v-model="delete_remarks"
                      required
                      placeholder="Remarks..."
                      class="mb-2"
                    ></b-form-textarea>
                      <b-button
                      variant="primary"
                      block
                      @click="onDeleteCrew"
                      >Hapus Crew</b-button>
                  </b-col>
                </b-row>
              </template>
            </b-modal>  
            
            <b-modal
              id="delete-candidate"
              size="md"
              title="HAPUS CANDIDATE"
              cancel-title="Close"
              hide-footer
            >
              <template #default="{ hide }">
                <b-row>
                  <b-col md="12">
                    <b-form-textarea
                      rows="3"
                      name="remarks"
                      v-model="delete_remarks"
                      required
                      placeholder="Remarks..."
                      class="mb-2"
                    ></b-form-textarea>
                      <b-button
                      variant="primary"
                      block
                      @click="onDeleteCandidate"
                      >Hapus Candidate</b-button>
                  </b-col>
                </b-row>
              </template>
            </b-modal>
            <!-- FORM BLACK LIST END -->

            <!-- SLIDE - EXPIRED CREW -->
            <b-sidebar
              v-model="slideExpiredCrew"
              id="slideExpiredCrew"
              :title="`Expired Crew`"
              width="100%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <SlideExpiredCrew 
                  :optionsCompanies="optionsCompanies"
                />
              </template>
            </b-sidebar>

            <!-- SLIDE - EXPIRED CREW END -->
            <!-- SLIDE - EXPIRED CREW MONTH -->
            <b-sidebar
              v-model="slideExpiredCrewMonth"
              id="slideExpiredCrewMonth"
              :title="`Expired 1 Month Crew`"
              width="100%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <SlideExpiredCrewMonth
                  :optionsCompanies="optionsCompanies"
                />
              </template>
            </b-sidebar>
            <!-- SLIDE - EXPIRED CREW MONTH END -->

            <!-- SLIDE - EXPIRED COC COP -->
            <b-col md="12">
              <b-sidebar
                v-model="slideExpiredCocCop"
                id="slideExpiredCocCop"
                :title="`Expired ${action == 'COC' ? 'COC' : 'COP'}`"
                width="100%"
                backdrop-variant="dark"
                bg-variant="white"
                right
                backdrop
                shadow>
                <template #default="{hide}">
                  <SlideExpiredCocCop 
                    :list="expiredCopCocData" 
                    :exportCocCop="exportCocCop"
                    :loading="loadingCopCocExpired" 
                    :action="action"
                    :getListExpiredCocCop="getListExpiredCocCop"
                  />
                </template>
              </b-sidebar>
            </b-col>
            <!-- SLIDE - EXPIRED COC COP END -->

             <!-- SLIDE - LIST BIRTHDAY -->
             <b-col md="12">
              <b-sidebar
                v-model="slideListBirthday"
                id="slideListBirthday"
                :title="`List Birthday Crew`"
                width="100%"
                backdrop-variant="dark"
                bg-variant="white"
                right
                backdrop
                shadow>
                <template #default="{hide}">
                  <SlideListBirthday />
                </template>
              </b-sidebar>
            </b-col>
            <!-- SLIDE - LIST BIRTHDAY END -->

             <!-- SLIDE - LIST BIRTHDAY -->
             <b-col md="12">
              <b-sidebar
                v-model="slideListPkl"
                id="slideListPkl"
                :title="`List of Crew PKL Not Completed`"
                width="100%"
                backdrop-variant="dark"
                bg-variant="white"
                right
                backdrop
                shadow>
                <template #default="{hide}">
                  <SlideListPkl 
                    :fleetOptions="fleetOptions"
                    :optionsCompanies="optionsCompanies"
                  />
                </template>
              </b-sidebar>
            </b-col>
            <!-- SLIDE - LIST BIRTHDAY END -->  
            
            <!-- SLIDE - LIST BIRTHDAY -->
             <b-col md="12">
              <b-sidebar
                v-model="slideNoticeSignOff"
                id="slideNoticeSignOff"
                :title="`List Notice Sign Off Crew`"
                width="100%"
                backdrop-variant="dark"
                bg-variant="white"
                right
                backdrop
                shadow>
                <template #default="{hide}">
                  <SlideListNoticeSignOff 
                    :fleetOptions="fleetOptions"
                    :optionsPosition="optionsPosition"
                    :optionsCompanies="optionsCompanies"
                  />
                </template>
              </b-sidebar>
            </b-col>
            <!-- SLIDE - LIST BIRTHDAY END -->
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="reject-modal"
      size="l"
      title="Reject (Navigation Equipment - Navigation Light)"
      ok-title="Save Changes"
      cancel-title="Close"
    >
      <b-row>
        <b-col md="12">
          <b-form-group label="Reason *" label-for="reason">
            <b-form-textarea id="reason" rows="2"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>
<script>
import moment from 'moment'
import { xray } from '../../config/pluginInit'
import { crewingActions, fleetsActions } from '@src/Utils/helper'
import listCrewOptionsData from '@src/core/mock/data/ListCrewOptionsData'
import FormBlacklist from '@src/components/crewing/FormBlackList.vue'
import TableContentCrewList from '@/src/components/crewing/crew/TableContentCrewList.vue'
import TableContentCandidateList from '@/src/components/crewing/candidate/TableContentCandidateList.vue'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import SlideExpiredCrew from './SlideExpiredCrew.vue'
import SlideExpiredCrewMonth from './SlideExpiredCrewMonth.vue'
import SlideExpiredCocCop from './SlideExpiredCocCop.vue'
import SlideListBirthday from './SlideListBirthday.vue'
import SlideListPkl from './SlideListPkl.vue'
import SlideListNoticeSignOff from './SlideListNoticeSignOff.vue'

import TabBlacklist from './TabBlacklist.vue'

export default {
  name: 'ProfileEdit',
  data() {
    return {
      ...listCrewOptionsData,
      action: '',
      slideNoticeSignOff:false,
      slideExpiredCrew:false,
      slideExpiredCrewMonth:false,
      slideExpiredCocCop: false,
      loadingCrewExpired:false,
      loadingCopCocExpired:false,
      expiredCrewData:null,
      expiredCopCocData:null,

      slideListBirthday: false,
      slideListPkl: false,

      loadingBirthday: false,
      loadingPkl: false,

      listBirthday: [],
      listPkl: [],
      
      delete_remarks:'',
      currentStep: 0,
      list_position: [],
      medical_checkup: [
        {
          label_file: '',
          file: '',
          nama_document: '',
        },
      ],
      optionsExpertUsers: [],
      optionBlacklistReasons: [],
      class_allowance: null,
      loadingList: false,
      saving: false,
      loading: false,
      form_crew: {
        id: null,
        key: '',
      },
      form_candidate: {
        id: null,
        key: '',
      },
      form: {
        _saving: false,
        name_of_the_experts: '',
        blacklist: {
          reason_off: '',
          remarks: '',
        },
        setInterviewCandidate: {
          expert_user_id: null,
          date_interview: '',
          position_id: '',
          location: '',
          urgensi: '',
          notes_set_interview: '',
          notes_set_interview_user:'',
          notification: [
            {
              expert_user_id: null,
            },
          ],
        },
      },
      notice_sign_off_loading:false,
      notice_sign_off_form:{
        reason_sign_off_id: null,
        notice_off:null,
        detail_notice_off:'',
        attachment_notice_off: null
      },
      form_signon: {
        company_id: null,
        department_id: 4,
        position_id: null,
        work_area_id: null,
        vehicle_id: null,
        port_id: '',
        sign_on: null,
        join_on_board: null,
        sign_off: null,
        attachment: null,
        remarks: '',
        basic_sallary: null,
        bpjsksh: 1,
        bpjstk: 1,
        bpjsksh_config: 0,
        basispengali_bpjsksh_config: 0,
        basispengali_bpjsksh: '',
        bpjsksh_company_set_value: null,
        bpjsksh_employee_set_value: null,
        bpjstk_config: 0,
        basispengali_bpjstk: null,
        jht_config: 0,
        jht_company_set_value: null,
        jht_employee_set_value: null,
        jp_config: 0,
        jp_company_set_value: null,
        jp_employee_set_value: null,
        jkk_config: 0,
        jkk_set_value: null,
        jkm_config: 0,
        jkm_set_value: null,
      },
      form_signoff_crew: {
        reason_signoff_id: [],
        sign_off: null,
        remarks: '',
        is_blacklist: '',
        status_emp_crewing_id: null,
        reason_blacklist_id: null,
      },
      form_verification: {
        seaferer_code_verification: null,
        verification_by: '',
      },
      levelSelected: null,
      selectedStatusCrew: null,
      selectStatusCandidate: null,
      selectedPerusahaan: null,
      selectedFleets: null,
      selectedPort: null,
      selectedPic: null,
      selectedReason: null,

      dateRange: [],   
      dateRangeJob: [],
      text: [
        {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: false,
        },
      ],
      crewList: {
        loading:false,
        isFiltering: false,
        params: {
          fullname: '',
          vehicle_id: JSON.parse(localStorage.getItem('list_crew_filter'))?.vehicle_id,
          from: null,
          to: null,
          join_on_board_from:null,
          join_on_board_to:null,
          position_id: JSON.parse(localStorage.getItem('list_crew_filter'))?.position_id,
          status: JSON.parse(localStorage.getItem('list_crew_filter'))?.status,
        },
        data: {
          head: [],
          body: [],
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
          indexes:1
        },
      },
      candidateList: {
        loading:false,
        isFiltering: false,
        params: {
          full_name: '',
          status: JSON.parse(localStorage.getItem('list_candidate_filter'))?.status ?? '',
          from:  null,
          to:  null,
          position_id:  JSON.parse(localStorage.getItem('list_candidate_filter'))?.position_id ?? null,
        },
        data: {
          head: [],
          body: [],
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
          indexes:1
        },
      },
     
      setting_bpjs:null,
      optionsCompanies: [],
      optionsWorkArea: [],
      optionsPorts: [],
      optionsPayrollAllowance: [],
      optionsDepartment: [],
      optionsPosition: [],
      master_list_document: [],
      master_list_department: [],
      department_form: [],
      master_signoff_reasons: [],
      options_signoff_reasons: [],
      optionsEmployeeStatus: [],
      optionsEmployeeStatusFilter: [],

      condite_questions: [],
      condite_points: [],
      condite_mapping: [],

      optionsSetting: [
        {
          value: 0,
          text: 'Default',
        },
        {
          value: 1,
          text: 'Tentukan Sendiri',
        },
      ],

      optionsGaji: [
        {
          value: 'GP',
          text: 'Gaji Pokok',
        },
        {
          value: 'GPT',
          text: 'Gaji Pokok + Tunjangan',
        },
        {
          value: 'UMP',
          text: 'UMP',
        },
      ],
      candidateProfile: null,
      crewProfile: null,
      profile_department: JSON.parse(localStorage.getItem('profile')).userDetail.department,

      swap_position: {
        id: null,
        vehicle_id: null,
        start_date: null,
        end_date: null,
        join_on_board: null,
        publish: 'Active',
        remarks: '',
        work_area_id: null,
        company_id: null,
        port_id: null,
        letter_of_no: null,
        status_emp_crewing: 1,
        file: null,
        file_label: null,
        position_id:null,
        swap:true
      },
      swap_position_id:null,
      swap_position_nip:null,
    }
  },
  created() {
    this.getCandidateList = _.debounce(this.getCandidateList, 500)
    this.getCrewList = _.debounce(this.getCrewList, 500)
  },
  async  mounted() {
    xray.index()
   
    await this.fetchFleets()
    await this.getCrewList()
    await this.getCandidateList()
    await this.getListExpiredNotifCrew()
    await this.getListExpiredCocCop()
    await this.fetchDataUserExpert()
    await this.fetchPerusahaan()
    await this.fetchAreaKerja()
    await this.fetchPelabuhan()
    await this.getDepartment()
    await this.getPositionList()
    await this.getMasterSignOffReasons()
    await this.getBlacklistReasons()
    await this.getEmployeeStatus()
    
  },
  components: {
    FormBlacklist,
    TableContentCrewList,
    TableContentCandidateList,
    SlideExpiredCrew,
    SlideExpiredCrewMonth,
    SlideExpiredCocCop,
    SlideListBirthday,
    SlideListPkl,
    SlideListNoticeSignOff,

    TabBlacklist,
  },
  watch: {
    'form_candidate.id': {
      async handler() {
        this.form_crew.key = ''

        await this.fetchProfileCandidate()
        await this.fetchDocumentList()
        await this.getMasterDepartmentList()
      },
      deep: true,
    }, 
    'form_crew.id': {
      async handler() {
        this.form_candidate.key = ''

        await this.fetchProfileCrew()
       
      },
      deep: true,
    },
    'form_signon.position_id': {
      handler(value) {
        if (value) {
          this?.list_position?.map((val) => {
            if (val.id_position === value) {
              this.class_allowance = val.class_allowance
            }
          })
        }
      },
    },
    'form_signon.setup_leave_pay_month': {
      handler(value) {
        if (value) {
          this.form_signon.leave_pay_amount =
            this.form_signon.setup_leave_pay_month * this.class_allowance
        } else if (
          this.form_signon.setup_leave_pay_month === null ||
          this.form_signon.setup_leave_pay_month === 0
        ) {
          this.form_signon.leave_pay_amount = 0
        }
      },
    },
    'form_signon.leave_pay': {
      handler(value) {
        if (value === 0) {
          this.form_signon.leave_pay_type = 'full_scheme'
          this.form_signon.leave_pay_amount = 0
          this.form_signon.setup_leave_pay_month = 0
        }
      },
    },
  },
  methods: {
    ...crewingActions,
    ...fleetsActions,
    openSlideCrewMonthExpired(){
      this.slideExpiredCrewMonth = !this.slideExpiredCrewMonth

      this.slideExpiredCrew = false
      this.slideExpiredCocCop = false
    }, 
    openSlideCrewExpired(){
      this.slideExpiredCrew = !this.slideExpiredCrew

      this.slideExpiredCrewMonth = false
      this.slideExpiredCocCop = false
    },
    openSlideCocCopExpired(value){
      this.action = value
      this.slideExpiredCocCop = !this.slideExpiredCocCop

      this.slideExpiredCrew = false
    }, 
    
    openSlideListBirthday(){
      this.slideListBirthday = !this.slideListBirthday
    }, 
    openSlideNoticeSignOff(){
      this.slideNoticeSignOff = !this.slideNoticeSignOff
    },
    openSlideListPkl(){
      this.slideListPkl = !this.slideListPkl
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    closeSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', `${id}`)
    },
    prefiewFiles: function (event, state) {
      const input = event.target

      if (input.files && input.files[0]) {
        state.label_file = input.files[0].name
        state.file = input.files[0]
      }
    },
    prefiewFilesInduction: function (event, state) {
      const input = event.target

      if (input.files && input.files[0]) {
        state.attachment_label = input.files[0].name
        state.attachment = input.files[0]
      }
    },
    selectStatusSignOffCrew: function (state, index) {
      if (state.id === 7 && state?.checked) {
        this.master_signoff_reasons.forEach((val, i) => {
          if (state.id !== val.id) {
            val.disabled = true
            val.checked = false
          }
          this.form_signoff_crew.status_emp_crewing_id = 3
        })
      } else if (state.id === 7 && !state?.checked) {
        this.master_signoff_reasons.forEach((val, i) => {
          if (state.id !== val.id) {
            val.disabled = false
            val.checked = false
          }
          this.form_signoff_crew.status_emp_crewing_id = null
          this.form_signoff_crew.reason_blacklist_id = null
        })
      }
    },
    hideModal() {
      this.resetFormSwapHistory()
      this.master_signoff_reasons = this.master_signoff_reasons.map(val => {
        return {
          ...val,
          disabled: false,
          checked: false,
        }
      })
      this.closeSidebar('swap-position')
    },
    resetFormSwapHistory(){
      this.swap_position = {
        id: null,
        vehicle_id: null,
        start_date: null,
        end_date: null,
        join_on_board: null,
        publish: 'Active',
        remarks: '',
        work_area_id: null,
        company_id: null,
        port_id: null,
        letter_of_no: null,
        status_emp_crewing: 1,
        file: null,
        file_label: null,
        swap:false
      }
    },

    async getListExpiredNotifCrew(){
      try {
        this.loadingCrewExpired = true

        const res = await this.fetchExpiredNotifCrew()

        this.expiredCrewData = res.data
        this.loadingCrewExpired = false
      } catch (error) {
        this.loadingCrewExpired = false
        this.expiredCrewData = null
      }
    },
    async exportCocCop(){
      try {
        let params = {
          export: 1
        }
        const res = await this.exportExpiredNotifCOPCOC(params)
        console.log(res, "res")

        saveAs(res.data, 'Expired COC / COP.xlsx')
      } catch (error) {
        this.$swal(`Oops!`, 'Export Failed: ' + error, 'error')
      }
    },
    async getListExpiredCocCop(page, perPage){
      try {
        let params = {
          export: 0,
          page: page || 1,
          per_page: perPage || 50
        }

        this.loadingCopCocExpired = true
    
        const res = await this.fetchExpiredNotifCOPCOC(params)

        this.expiredCopCocData = res.data
        this.loadingCopCocExpired = false
      } catch (err) {
        this.loadingCopCocExpired = false
        this.expiredCopCocData = null
      }
    },

    async onDeleteCrew () {
      let payload  = {
        remarks: this.delete_remarks
      }
      const { status, data } = await this.deleteCrew({id:this.form_crew.id, data:payload })

      if (status !== 'success') {
        return
      }

      await this.getCrewList()
      this.$swal(`Berhasil`, 'Menunggu Approval Penghapusan Crew.', 'success')

      this.$nextTick(() => {
        this.$bvModal.hide('delete-crew')
      })
      this.delete_remarks = ''
    },
    async onDeleteCandidate () {
      let payload  = {
        remarks: this.delete_remarks
      }
      const { status, data } = await this.deleteCandidate({id:this.form_candidate.id, data:payload })

      if (status !== 'success') {
        return
      }

      await this.getCandidateList()
      this.$swal(`Berhasil`, 'Menunggu Approval Penghapusan Candidate.', 'success')

      this.$nextTick(() => {
        this.$bvModal.hide('delete-candidate')
      })
      this.delete_remarks = ''
    },
    async onSaveSwapPosition(e) {
      e.preventDefault()

      this.saving = true

      let method = 'setSwapPosition'

      const formData = new FormData()

      formData.append('vehicle_id', this.swap_position.vehicle_id)
      formData.append('position_id', this.swap_position.position_id)
      formData.append('is_blacklist', 'no')
      formData.append('status_emp_crewing_id', 5)
      formData.append('start_date', moment(this.swap_position.start_date).format('YYYY-MM-DD'))
      formData.append('join_on_board', moment(this.swap_position.join_on_board).format('YYYY-MM-DD'))
      formData.append('join_on_board_time', moment(this.swap_position.join_on_board).format('HH:mm:ss'))
      formData.append('remarks', this.swap_position.remarks)
      formData.append('company_id', this.swap_position.company_id)
      formData.append('port_id', this.swap_position.port_id)
      formData.append('letter_of_no', null)
      if(this.swap_position.file){
        formData.append('attachment', this.swap_position.file)
      }
      formData.append('swap', 1)

      const res = await this[method]({
        emp_crewing_id: this.form_crew.id,
        formData,
      })

      if (res.status === 'success') {
        this.resetFormSwapHistory()
        this.$swal(`Berhasil!`, `Berhasil Swap Position.`, 'success')
        this.closeSidebar('swap-position')
        this.saving = false
      } else {
        this.saving = false
        return this.$swal(`Oops!`, res?.data, 'error')
      }
    },

    async getEmployeeStatus() {
      const { status, data } = await this.fetchEmployeeStatus()

      if (status !== 'success') {
        return
      }
    
      let tempArr = []
      data?.data?.map((val) => {
        tempArr.push({
          id:val.id,
          alias:val.alias,
          text:val.name,
          value:val.id
        })
      })

      let tempArrFilter = []
      data?.data?.map((val) => {
        tempArrFilter.push({
          id:val.id,
          alias:val.alias,
          text:val.name,
          value:val.name
        })
      })

      tempArr = tempArr.filter(obj => obj.id !== 5 && obj.id !== 1 && obj !== 4)
      tempArrFilter = tempArrFilter.filter(obj => obj.id !== 4)

      this.optionsEmployeeStatus = tempArr
      this.optionsEmployeeStatusFilter = tempArrFilter
    },   
    async setBpjs() {
      this.setting_bpjs = null

      const { status, data } = await this.fetchPayrollSettingBPJS({id:this.form_signon.company_id})

      if (status !== 'success') {
        this.form_signon = {
          ...this.form_signon,
          bpjsksh: 1,
          bpjstk: 1,
          bpjsksh_config: 0,
          basispengali_bpjsksh_config: 0,
          basispengali_bpjsksh: '',
          bpjsksh_company_set_value: null,
          bpjsksh_employee_set_value: null,
          bpjstk_config: 0,
          basispengali_bpjstk: null,
          jht_config: 0,
          jht_company_set_value: null,
          jht_employee_set_value: null,
          jp_config: 0,
          jp_company_set_value: null,
          jp_employee_set_value: null,
          jkk_config: 0,
          jkk_set_value: null,
          jkm_config: 0,
          jkm_set_value: null,
        }
        return
      }

      this.form_signon = {
        ...this.form_signon,
        bpjsksh: data?.pyrl_setting_bpjsksh?.is_bpjsksh,
        bpjstk: data?.pyrl_setting_bpjstk?.is_bpjsksh ?? 1,
        bpjsksh_config: data?.pyrl_setting_bpjsksh?.bpjsksh_perusahaan || data?.pyrl_setting_bpjsksh?.bpjsksh_karyawan ? 1 : 0,
        basispengali_bpjsksh_config: data?.pyrl_setting_bpjsksh?.basis_pengali ? 1 : 0,
        basispengali_bpjsksh: data?.pyrl_setting_bpjsksh?.basis_pengali,
        bpjsksh_company_set_value: data?.pyrl_setting_bpjsksh?.bpjsksh_perusahaan,
        bpjsksh_employee_set_value: data?.pyrl_setting_bpjsksh?.bpjsksh_karyawan ,
        bpjstk_config: data?.pyrl_setting_bpjstk?.basis_pengali ? 1 : 0,
        basispengali_bpjstk: data?.pyrl_setting_bpjstk?.basis_pengali ?? '', // GP, GPT, UMP
        jht_config:data?.pyrl_setting_bpjstk?.jht_karyawan || data?.pyrl_setting_bpjstk?.jht_perusahaan ? 1 : 0,
        jht_company_set_value: data?.pyrl_setting_bpjstk?.jht_perusahaan ?? null,
        jht_employee_set_value: data?.pyrl_setting_bpjstk?.jht_karyawan ?? null,
        jp_config: data?.pyrl_setting_bpjstk?.jp_karyawan ? 1 : 0,
        jp_company_set_value: data?.pyrl_setting_bpjstk?.jp_perusahaan ?? null,
        jp_employee_set_value: data?.pyrl_setting_bpjstk?.jp_karyawan ??  null,
        jp_max_gaji: data?.pyrl_setting_bpjstk?.jp_max_gaji ?? null,
        jp_wna:  data?.pyrl_setting_bpjstk?.jp_wna ?? '', // YES, NO
        jp_lebihusia_max: data?.pyrl_setting_bpjstk?.jp_lebihusia_max  ?? '', // YES, NO
        jp_max_usia: data?.pyrl_setting_bpjstk?.jp_max_usia ??  null,
        jkm_config: data?.pyrl_setting_bpjstk?.jkm ? 1 : 0,
        jkm_set_value: data?.pyrl_setting_bpjstk?.jkm ?? null,
        jkk_config:  data?.pyrl_setting_bpjstk?.jkk ? 1 : 0,
        jkk_set_value:  data?.pyrl_setting_bpjstk?.jkk ?? null,
      },

      this.setting_bpjs = data
    }, 
    async handleDownloadPKL(id, name) {
      const res = await this.fetchDownloadPKL({ id })
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `File PKL - ${name}.pdf`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    async getBlacklistReasons() {
      let res = await this.fetchBlacklistReasons()
      if (res.status) {
        if (res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionBlacklistReasons.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async fetchPerusahaan() {
      let res = await this.fetchCompanies()
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsCompanies.push({
              value: state.id,
              text: state.company,
            })
          })
        }
      }
    },
    async getPositionList() {
      let res = await this.fetchPositionList()
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsPosition.push({
              value: state.id_position,
              text: state.name_position,
            })
          })
        }
        this.list_position = res.data
      }
    },
    async fetchAreaKerja() {
      let res = await this.fetchWorkArea()

      if (res.status) {
        if (res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsWorkArea.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async fetchPelabuhan() {
      let params = {
        page: 1,
        perPage: 100000,
      }
      let res = await this.fetchPorts(params)
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsPorts.push({
              value: state.portName,
              text: state.portName,
            })
          })
        }
      }
    },
    async getDepartment() {
      let params = {
        is_induction: 1,
      }
      let res = await this.fetchMasterDepartmentList(params)
      if (res.status) {
        if (res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsDepartment.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async getMasterSignOffReasons() {
      let res = await this.masterSignOffReasons()
      if (res.status) {
        res.data.data.map((val) => {
          this.master_signoff_reasons.push({
            ...val,
            disabled: false,
            checked: false,
          })
          this.options_signoff_reasons.push({
            value: val.id,
            text: val.name,
          })
        })

      }
    },

    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 200,
      }

      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          data.map((state) => {
            this.optionsExpertUsers.push({
              value: state.id,
              text: state.userDetail.name,
            })
          })
        }
      }
    },
    async getCrewList(page) {
      const params = {
        ...this.crewList.params,
        page: page || 1,
      }

      if(!params.fullname) delete params.fullname
      if(!params.from) delete params.from
      if(!params.to) delete params.to
      if(!params.position_id) delete params.position_id
      if(!params.vehicle_id) delete params.vehicle_id

      this.crewList.loading = true

      const { status, data } = await this.fetchCrewList(params)

      if (status === 'success') {
        this.crewList.meta.perPage = data.per_page
        this.crewList.meta.currentPage = data.current_page
        this.crewList.meta.total = data.total
        this.crewList.meta.totalPage = Math.round(data.total / data.per_page)
        this.crewList.meta.indexes = data.from
        if (data.data) {
          this.crewList.data.body = data.data
          this.crewList.data.head = [
            'NO',
            'NAMA',
            'FLEET',
            'JABATAN',
            'JOIN ON BOARD',
            'SIGN ON',
            'SIGN OFF',
            'NOTICE SIGN OFF',
            'STATUS',
            'ACTION',
          ]
        }
        this.crewList.loading = false
      }
      this.crewList.loading = false
    },
    async getCandidateList(page) {
      const params = {
        ...this.candidateList.params,
        page: page || 1,
      }

      if(!params.full_name) delete params.full_name
      if(!params.from) delete params.from
      if(!params.to) delete params.to
      if(!params.position_id) delete params.position_id
      if(!params.status) delete params.status

      this.candidateList.loading = true

      const { status, data } = await this.fetchCandidateList(params)

      if (status === 'success') {
        this.candidateList.meta.perPage = data.per_page
        this.candidateList.meta.currentPage = data.current_page
        this.candidateList.meta.total = data.total
        this.candidateList.meta.indexes = data.from
        this.candidateList.meta.totalPage = Math.round(
          data.total / data.per_page
        )
        if (data.data) {
          this.candidateList.data.body = data.data
          this.candidateList.data.head = [
            'NO',
            'NAMA',
            'JABATAN',
            'JADWAL INTERVIEW',
            'EXPERT',
            'STATUS',
            'ACTION',
          ]
        }
        this.candidateList.loading = false
      }
      this.candidateList.loading = false
    },
  
    async fetchFleets() {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: '',
      })

      if(!params.search) delete params.search

      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map((val) => ({
            text: val.name,
            value: val.id,
          }))
        }
      }
    },
    onCrewChangeDate() {
      this.crewList.params.from = this.dateRange
        ? `${moment(this.dateRange[0]).format('YYYY-MM-DD')}`
        : null
      this.crewList.params.to = this.dateRange
        ? `${moment(this.dateRange[1]).format('YYYY-MM-DD')}`
        : null
    }, 
    onCrewChangeDateJob() {
      this.crewList.params.join_on_board_from = this.dateRangeJob
        ? `${moment(this.dateRangeJob[0]).format('YYYY-MM-DD')}`
        : null
      this.crewList.params.join_on_board_to = this.dateRangeJob
        ? `${moment(this.dateRangeJob[1]).format('YYYY-MM-DD')}`
        : null
    },
    onCandidateChangeDate() {
      this.candidateList.params.from = this.dateRange
        ? `${moment(this.dateRange[0]).format('YYYY-MM-DD')}`
        : null
      this.candidateList.params.to = this.dateRange
        ? `${moment(this.dateRange[1]).format('YYYY-MM-DD')}`
        : null
    },
    async filterCrewList(page = 1) {
      const params = {
        ...this.crewList.params,
        page: 1,
      }

      localStorage.setItem('list_crew_filter', JSON.stringify(params))

      if(!params.fullname) delete params.fullname
      if(!params.vehicle_id) delete params.vehicle_id
      if(!params.position_id) delete params.position_id
      if(!params.status) delete params.status
      if((this.dateRangeJob.length >= 0 && !this?.dateRangeJob?.[0]) || this.dateRangeJob.length <= 0){
        delete params.join_on_board_from
        delete params.join_on_board_to
      } 
      if((this.dateRange.length >= 0 && !this?.dateRange?.[0]) || this.dateRange.length <= 0){
        delete params.from
        delete params.to
      }

      const { status, data } = await this.fetchCrewList(params)
      this.crewList.isFiltering = true
      if (status === 'success') {
        this.crewList.meta.perPage = data.per_page
        this.crewList.meta.currentPage = data.current_page
        this.crewList.meta.total = data.total
        this.crewList.meta.totalPage = Math.round(data.total / data.per_page)
        if (data.data) {
          this.crewList.data.body = data.data
          this.crewList.data.head = [
            'NO',
            'NAMA',
            'FLEET',
            'JABATAN',
            'JOIN ON BOARD',
            'SIGN ON',
            'SIGN OFF',
            'STATUS',
            'ACTION',
          ]
        }
      }
    },
    async filterCandidateList() {
      const params = {
        ...this.candidateList.params,
        page: 1,
      }

      localStorage.setItem('list_candidate_filter', JSON.stringify(params))

      if(!params.full_name) delete params.full_name
      if(!params.from) delete params.from
      if(!params.to) delete params.to
      if(!params.position_id) delete params.position_id
      if(!params.status) delete params.status

      const { status, data } = await this.fetchCandidateList(params)
      this.candidateList.isFiltering = true
      if (status === 'success') {
        this.candidateList.meta.perPage = data.per_page
        this.candidateList.meta.currentPage = data.current_page
        this.candidateList.meta.total = data.total
        this.candidateList.meta.totalPage = Math.round(
          data.total / data.per_page
        )
        if (data.data) {
          this.candidateList.data.body = data.data
          this.candidateList.data.head = [
            'NO',
            'NAMA',
            'JABATAN',
            'JADWAL INTERVIEW',
            'EXPERT',
            'STATUS',
            'ACTION',
          ]
        }
      }
    },

    async fetchProfileCandidate() {
      if (this.form_candidate.id) {
        let method = 'getProfileCandidate'

        const { status, data } = await this[method](this.form_candidate.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.candidateProfile = data

        this.form.setInterviewCandidate.position_id = this.candidateProfile.personal_data.position_id

        this.form_signon.position_id = this.candidateProfile?.personal_data?.position_id

      }
    },
    async fetchProfileCrew() {
      if (this.form_crew.id) {
        let method = 'getCrewDetail'

        const { status, data } = await this[method](this.form_crew.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.crewProfile = data[0]

        this.form_signoff_crew.sign_off = data[0].sign_off

        const sortedItems = [...this.crewProfile.history_position_swap];
        sortedItems.sort((a, b) => new Date(b.join_on_board) - new Date(a.join_on_board));
        let newestObject = sortedItems[0];

        const signOffDate = new Date(newestObject?.end_date); 
        const joinOnBoardDate = new Date(newestObject?.join_on_board); 
        
        // Subtract one day (24 hours)
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
        let nextDate = null

        if(newestObject?.end_date){
          nextDate = new Date(signOffDate.getTime() + oneDayInMilliseconds);
        } else if (newestObject?.join_on_board){
          nextDate = new Date(joinOnBoardDate.getTime() + oneDayInMilliseconds);
        } else {
          nextDate = null
        }
        nextDate = nextDate ? moment(nextDate).format('YYYY-MM-DD HH:mm:ss') : null

        this.swap_position.vehicle_id = Number(newestObject.vehicle_id)
        this.swap_position.position_id = Number(this.crewProfile.position_id)
        this.swap_position.start_date = nextDate ? nextDate : null
        this.swap_position.join_on_board = nextDate ? nextDate : null
        this.swap_position.company_id = Number(newestObject.company_id)
        this.swap_position.port_id = newestObject.port_id
        this.swap_position.letter_of_no = newestObject?.letter_of_no ?? null

        this.swap_position_id = newestObject.id
        this.swap_position_nip = newestObject.nip
      }
    },
    async fetchDocumentList() {
      this.loading = true
      this.master_list_document = []
      const { status, data } = await this.masterDocumentList()

      if (status !== 'success') {
        this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
        return
      }
      data?.data?.map((val) => {
        if (val.type === 'system' && val.is_travel === 0) {
          this.master_list_document.push({
            document_list_id: val.id,
            status_checklist: 1,
            document_name: val.name,
          })
        } else if(val.type === 'manual' && val.is_travel === 0) {
          this.master_list_document.push({
            document_list_id: val.id,
            status_checklist: 0,
            document_name: val.name,
          })
        }
      })

      this.master_list_document.map((val) => {
        this?.candidateProfile?.support_document?.map((item) => {
          if (
            val.document_list_id === item.document_list_id &&
            val.status_checklist === 0
          ) {
            val.status_checklist = 1
          }
        })
      })
      this.loading = false
    },
    async getMasterDepartmentList() {
      this.loading = true
      this.department_form = []
      this.master_list_department = []
      let induction_checklist = []

      let params = {
        is_induction: 1,
      }
      const { status, data } = await this.fetchMasterDepartmentList(params)
      const res = await this.fetchInductionChecklist(this.form_candidate.id)

      if (status !== 'success') {
        this.loading = false
        return
      }

      this.master_list_department = data?.data
      induction_checklist = res.data

      this.master_list_department.map((val) => {
        this.department_form.push({
          department_id: val.id,
          name: `${val.name} Department`,
          induction_by: null,
          induction_date: null,
          attachment: null,
          attachment_label: '',
          notes: '',
          checked: false,
        })
      })

      if (induction_checklist.length >= 1) {
        this.department_form.map((val) => {
          induction_checklist.map((item) => {
            if (val.department_id === item.department_id) {
              val.checked = true
            }
          })
        })
      }
      this.loading = false
    },
    // Medical Checkup Adding and Removing Forms
    addMedicalCheckupForm() {
      this.medical_checkup.push({
        label_file: '',
        file: '',
        nama_document: '',
      })
    },
    deleteMedicalCheckupForm(index) {
      this.medical_checkup.splice(index, 1)
    },
    addNotificationUser() {
      this.form.setInterviewCandidate.notification.push({
        expert_user_id: null,
      })
    },
    deleteNotificationUser(index) {
      this.form.setInterviewCandidate.notification.splice(index, 1)
    },

    async onSaveMedichalCheckup(e) {
      e.preventDefault()
      this.saving = true

      let method = 'medicalCheckup'

      let tempMedicalCheckup = []
      this.medical_checkup.map((item) => {
        tempMedicalCheckup.push({
          file: item.file,
          nama_document: item.nama_document,
        })
      })

      let formData = new FormData()

      tempMedicalCheckup.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `medical_checkup[${index}][${property}]`,
            item[property]
          )
        }
      })

      const { status, data } = await this[method]({
        data: formData,
        id: this.form_candidate.id,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `Semua Data Harus Terisi`, 'error')
        return
      }

      let changeStatus = new FormData()

      changeStatus.append('change_status', 'induction')

      await this.changeStatusCandidate({
        id: this.form_candidate.id,
        change_status: changeStatus,
      })

      this.candidateList.data.body.map((val) => {
        if (val.id === this.form_candidate.id) {
          val.status = 'induction'
        }
      })

      this.$swal(`Success!`, `${data}`, 'success')

      this.saving = false

      this.medical_checkup = [
        {
          label_file: '',
          file: '',
          nama_document: '',
        },
      ]
      this.$nextTick(() => {
        this.$bvModal.hide('medical-checkup')
      })
    },

    prevStep() {
      if (this.currentStep <= 0) {
        return
      }

      this.currentStep--
    },

    async onSignOnSave(e) {
      e.preventDefault()
      if (this.currentStep !== 2) {
        this.currentStep++
        return
      }
      this.saving = true

      let method = 'setSignOnCandidate'
      let formData = new FormData()

      if (this.form_signon.attachment) {
        formData.append('attachment', this.form_signon.attachment)
      }
      formData.append('bpjstk_config', this.form_signon.bpjstk_config)
      formData.append('bpjsksh_config', this.form_signon.bpjsksh_config)
      formData.append('company_id', this.form_signon.company_id)
      formData.append('department_id', this.form_signon.department_id)
      formData.append('position_id', this.form_signon.position_id)
      formData.append('work_area_id', this.form_signon.work_area_id)
      formData.append('vehicle_id', this.form_signon.vehicle_id)
      formData.append('port_id', this.form_signon.port_id)
      formData.append('sign_on', this.form_signon.sign_on)
      formData.append('join_on_board', moment(this.form_signon.join_on_board).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('join_on_board_time', moment(this.form_signon.join_on_board).format('HH:mm:ss'))
      formData.append('sign_off', this.form_signon.sign_off ? moment(this.form_signon.sign_off).format('YYYY-MM-DD HH:mm:ss') : null)
      formData.append('sign_off_time',this.form_signon.sign_off ? moment(this.form_signon.sign_off).format('HH:mm:ss') : null)

      formData.append('remarks', this.form_signon.remarks)
      formData.append('basic_sallary', Number(this.form_signon.basic_sallary))

      formData.append('bpjsksh', this.form_signon.bpjsksh)
      formData.append('bpjstk', this.form_signon.bpjstk)
      formData.append('bpjsksh_config', this.form_signon.bpjsksh_config)
      formData.append('basispengali_bpjsksh_config', this.form_signon.basispengali_bpjsksh_config)
      formData.append('basispengali_bpjsksh', this.form_signon.basispengali_bpjsksh)
      formData.append('bpjsksh_company_set_value', this.form_signon.bpjsksh_company_set_value)
      formData.append('bpjsksh_employee_set_value', this.form_signon.bpjsksh_employee_set_value)
      formData.append('bpjstk_config', this.form_signon.bpjstk_config)
      formData.append('basispengali_bpjstk', this.form_signon.basispengali_bpjstk)
      formData.append('jht_config', this.form_signon.jht_config)
      formData.append('jht_company_set_value', this.form_signon.jht_company_set_value)
      formData.append('jht_employee_set_value', this.form_signon.jht_employee_set_value)
      formData.append('jp_config', this.form_signon.jp_config)
      formData.append('jp_company_set_value',this.form_signon.jp_company_set_value)
      formData.append('jp_employee_set_value', this.form_signon.jp_employee_set_value)
      formData.append('jkk_config', this.form_signon.jkk_config)
      formData.append('jkk_set_value', this.form_signon.jkk_set_value)
      formData.append('jkm_config', this.form_signon.jkm_config)
      formData.append('jkm_set_value', this.form_signon.jkm_set_value)
      formData.append('jkm_set_value', this.form_signon.jkm_set_value)

      // formData.append('leave_pay', this.form_signon.leave_pay)
      // formData.append('leave_pay_type', this.form_signon.leave_pay_type)
      // formData.append('leave_pay_amount', this.form_signon.leave_pay_amount)
      // formData.append('setup_leave_pay_month', this.form_signon.setup_leave_pay_month)

      const { status, data } = await this[method]({
        id: this.form_candidate.id,
        data: formData,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `Gagal melakukan sign on.`, 'error')
        return
      }

      let changeStatus = new FormData()

      changeStatus.append('change_status', 'crew')

      await this.changeStatusCandidate({
        id: this.form_candidate.id,
        change_status: changeStatus,
      })

      this.candidateList.data.body = this.candidateList.data.body.filter(
        (val) => {
          return val.id !== this.form_candidate.id
        }
      )

      await this.getCrewList()
      await this.getCandidateList()

      this.form_signon.company_id = null
      this.form_signon.department_id = null
      this.form_signon.position_id = null
      this.form_signon.work_area_id = null
      this.form_signon.vehicle_id = null
      this.form_signon.port_id = null
      this.form_signon.sign_on = null
      this.form_signon.join_on_board = null
      this.form_signon.sign_off = null
      this.form_signon.attachment = null
      this.form_signon.remarks = ''
      this.form_signon.basic_sallary = null

      this.form_signon.bpjsksh = 1
      this.form_signon.bpjstk = 1
      this.form_signon.bpjsksh_config = 0
      this.form_signon.basispengali_bpjsksh_config = 0
      this.form_signon.basispengali_bpjsksh = ''
      this.form_signon.bpjsksh_company_set_value = null
      this.form_signon.bpjsksh_employee_set_value = null
      this.form_signon.bpjstk_config = 0
      this.form_signon.basispengali_bpjstk = null
      this.form_signon.jht_config = 0
      this.form_signon.jht_company_set_value = null
      this.form_signon.jht_employee_set_value = null
      this.form_signon.jp_config = 0
      this.form_signon.jp_company_set_value = null
      this.form_signon.jp_employee_set_value = null
      this.form_signon.jkk_config = 0
      this.form_signon.jkk_set_value = null
      this.form_signon.jkm_config = 0
      this.form_signon.jkm_set_value = null

      this.closeSidebar('sign-on')

      this.$swal(`Tersimpan`, 'Candidate Berhasil Menjadi Crew.', 'success')
      this.saving = false
    },

    async onSaveInduction(e) {
      e.preventDefault()
      this.saving = true

      let method = 'setInductionCandidate'

      let readyToSave = []

      this?.department_form?.map((val) => {
        if (!val.checked) {
          if (this.profile_department === null) {
            readyToSave.push({
              department_id: val.department_id,
              induction_by: val.induction_by,
              induction_date: val.induction_date,
              attachment: val.attachment,
              notes: val.notes,
            })
          } else if (val.department_id === Number(this.profile_department)) {
            readyToSave.push({
              department_id: val.department_id,
              induction_by: val.induction_by,
              induction_date: val.induction_date,
              attachment: val.attachment,
              notes: val.notes,
            })
          }
        }
      })

      let statusFetching = null

      const promises = readyToSave.map(async (val) => {
        let formData = new FormData()
        formData.append('department_id', val.department_id)
        formData.append('induction_by', val.induction_by)
        formData.append('induction_date', val.induction_date)
        formData.append('attachment', val.attachment)
        formData.append('notes', val.notes)

        const { status, data } = await this[method]({
          formData,
          id: this.form_candidate.id,
        })

        if (status === 422) {
          statusFetching = status
        }

        this.department_form.map((item) => {
          if (val.department_id === item.department_id) {
            if (status !== 422) {
              item.checked = true
            }
          }
        })
      })

      await Promise.all(promises).then(async () => {
        if (statusFetching === null) {
          this.saving = false

          let changeStatus = new FormData()

          changeStatus.append('change_status', 'contract')

          await this.changeStatusCandidate({
            id: this.form_candidate.id,
            change_status: changeStatus,
          })

          this.candidateList.data.body.map((val) => {
            if (val.id === this.form_candidate.id) {
              val.status = 'contract'
            }
          })

          this.$swal(`Tersimpan`, 'Berhasil melakukan induction', 'success')

          this.$nextTick(() => {
            this.$bvModal.hide('induction')
          })
        } else {
          this.saving = false
        }

        this.saving = false
      })
    },

    async onSaveVerificationDocument(e) {
      e.preventDefault()
      this.saving = true

      let method = 'setVerificationDocument'

      let formData = new FormData()
      formData.append(
        'seaferer_code_verification',
        this.form_verification.seaferer_code_verification
      )
      formData.append('verification_by', this.form_verification.verification_by)

      const { status, data } = await this[method]({
        formData,
        id: this.form_candidate.id,
      })

      if (status === 400) {
        this.saving = false
        return this.$swal(`Oops!`, `Harap isi semua data.`, 'error')
      }

      let changeStatus = new FormData()

      changeStatus.append('change_status', 'set_interview')

      await this.changeStatusCandidate({
        id: this.form_candidate.id,
        change_status: changeStatus,
      })

      this.candidateList.data.body.map((val) => {
        if (val.id === this.form_candidate.id) {
          val.status = 'set_interview'
        }
      })

      this.$swal(`Tersimpan`, 'Verification Berhasil.', 'success')

      this.$nextTick(() => {
        this.$bvModal.hide('verification_document')
      })

      this.saving = false
    },

    async onSaveSetInterview(e) {
      e.preventDefault()
      this.saving = true

      let method = 'setInterviewCandidate'
      let formData = new FormData()

      let tempDateInterview = moment(
        this.form.setInterviewCandidate.date_interview
      ).format('YYYY-MM-DD HH:mm:ss')

      formData.append('expert_user_id', this.form.setInterviewCandidate.expert_user_id)
      formData.append('date_interview', tempDateInterview)
      formData.append('position_id', this.form.setInterviewCandidate.position_id)
      formData.append('location', this.form.setInterviewCandidate.location)
      formData.append('urgensi', this.form.setInterviewCandidate.urgensi)
      formData.append('notes_set_interview', this.form.setInterviewCandidate.notes_set_interview)
      formData.append('notes_set_interview_user', this.form.setInterviewCandidate.notes_set_interview_user)

      this.form.setInterviewCandidate.notification.forEach((item, index) => {
        for (const property in item) {
          formData.append(`notification[${index}][${property}]`, item[property])
        }
      })

      if (this.form_candidate.id) {
        const { status, data } = await this[method]({
          formData,
          id: this.form_candidate.id,
        })

        if (status !== 'success') {
          this.saving = false
          this.$swal(`Oops!`, `Semua Data Harus Terisi`, 'error')
          return
        }

        this.optionsExpertUsers.map((val) => {
          if (val.value === this.form.setInterviewCandidate.expert_user_id) {
            this.form.name_of_the_experts = val.text
          }
        })
        let changeStatus = new FormData()

        changeStatus.append('change_status', 'interview')

        await this.changeStatusCandidate({
          id: this.form_candidate.id,
          change_status: changeStatus,
        })

        this.candidateList.data.body.map((val) => {
          if (val.id === this.form_candidate.id) {
            val.status = 'interview'
            val.date_interview = this.form.setInterviewCandidate.date_interview
            val.expert = this.form.name_of_the_experts
          }
        })

        this.form.setInterviewCandidate.expert_user_id = null
        this.form.setInterviewCandidate.date_interview = null
        this.form.setInterviewCandidate.position_id = null
        this.form.setInterviewCandidate.location = null
        this.form.setInterviewCandidate.urgensi = null
        this.form.setInterviewCandidate.notes_set_interview = null
        this.form.setInterviewCandidate.notification = [
          { expert_user_id: null },
        ]

        this.$swal(`Berhasil`, 'Mebuat jadwal interview candidate.', 'success')
        this.saving = false
        this.$nextTick(() => {
          this.$bvModal.hide('setup-interview')
        })

        this.$swal({
          title: 'Apakah anda yakin ingin mengirimkan email ke candidate?',
          text: "Ya, Saya yakin.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Send it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.sendEmailToCandidate({id:this.form_candidate.id})
            if(res.status === false || res.status === 400 || res.status === 500){
              this.$swal(
                'Failed!',
                'Something went wrong.',
                'error'
              )
              return 
            } 
            this.$swal(
              'Success!',
              'Berhasil mengirimkan email ke candidate.',
              'success'
            )
            this.$swal({
              title: 'Apakah anda yakin ingin mengirimkan email ke interviwer?',
              text: "Ya, Saya yakin.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Send it!'
            }).then(async (result) => {
              if (result.isConfirmed) {
                const res = await this.sendEmailToInterviewer({id:this.form_candidate.id})
                if(res.status === false || res.status === 400 || res.status === 500){
                  this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                  )
                  return 
                } 
                this.$swal(
                  'Success!',
                  'Berhasil mengirimkan email ke Interviewer.',
                  'success'
                )
              }
            })
          }else {
            this.$swal({
              title: 'Apakah anda yakin ingin mengirimkan email ke interviwer?',
              text: "Ya, Saya yakin.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Send it!'
            }).then(async (result) => {
              if (result.isConfirmed) {
                const res = await this.sendEmailToInterviewer({id:this.form_candidate.id})
                if(res.status === false || res.status === 400 || res.status === 500){
                  this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                    )
                  return 
                } 
                this.$swal(
                  'Success!',
                  'Berhasil mengirimkan email ke Interviewer.',
                  'success'
                ) 
                
              }
            })
          }
        })
      }
    },

    async onSaveSignOffCrew(e) {
      e.preventDefault()
      this.saving = true
      this.form_signoff_crew.reason_signoff_id = []

      let method = 'setSignOffCrew'

      this.master_signoff_reasons.map((val) => {
        if (val.checked) {
          this.form_signoff_crew.reason_signoff_id.push(val.id)
        }
      })

      if (this.master_signoff_reasons[6].checked) {
        this.form_signoff_crew.is_blacklist = 'yes'
      } else {
        this.form_signoff_crew.is_blacklist = 'no'
      }

      this.form_signoff_crew.sign_off = moment(this.form_signoff_crew.sign_off).format('YYYY-MM-DD')
      this.form_signoff_crew.sign_off_time = moment(this.form_signoff_crew.sign_off).format('HH:mm:ss')

      const { status, data } = await this[method]({
        id: this.form_crew.id,
        data: this.form_signoff_crew,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `Semua Data Harus Terisi`, 'error')
        return
      }

      let changeStatus = new FormData()

      changeStatus.append(
        'change_status',
        `${
          this.form_signoff_crew.status_emp_crewing_id === 2
            ? 'Finish'
            : 'Unfinish'
        }`
      )

      await this.changeStatusCandidate({
        id: this.form_crew.id,
        change_status: changeStatus,
      })

      this.crewList.data.body.map((val) => {
        if (val.id === this.form_crew.id) {
          val.status_crew =
            this.form_signoff_crew.status_emp_crewing_id === 2
              ? 'Finish'
              : 'Unfinish'
        }
      })

      this.form_signoff_crew.reason_signoff_id = []
      this.form_signoff_crew.remarks = ''
      this.form_signoff_crew.is_blacklist = ''
      this.form_signoff_crew.status_emp_crewing_id = 1
      this.form_signoff_crew.reason_blacklist_id = null

      this.master_signoff_reasons.map((val) => {
        val.checked = false
      })

      this.closeSidebar('sign-off')

      this.$swal(`Tersimpan`, 'Data Sign Off Tersimpan', 'success')
      this.saving = false
    },

    async onSaveBlacklist() {
      this.loading = true
      let formData = new FormData()

      formData.append('reason_off', this.form.blacklist.reason_off)
      formData.append('remarks', this.form.blacklist.remarks)

      if (this.form_candidate.key === 'candidate') {
        const { status, data } = await this.setBlacklistCandidate({
          formData,
          id: this.form_candidate.id,
        })
        if (status !== 'success') {
          this.$swal(`Oops!`, `${data}`, 'error')
          this.loading = false
          this.form.blacklist.reason_off = ''
          this.form.blacklist.remarks = ''
          return
        }
        this.candidateList.data.body = this.candidateList.data.body.filter(
          (val) => {
            return val.id !== this.form_candidate.id
          }
        )

        this.$swal(`Tersimpan`, 'Blacklist candidate berhasil', 'success')
      } else if (this.form_crew.key === 'crew') {
        const { status, data } = await this.setBlacklistCrew({
          formData,
          id: this.form_crew.id,
        })
        if (status !== 'success') {
          this.$swal(`Oops!`, `${data}`, 'error')
          this.loading = false
          this.form.blacklist.reason_off = ''
          this.form.blacklist.remarks = ''
          return
        }
        this.crewList.data.body = this.crewList.data.body.filter((val) => {
          return val.id !== this.form_crew.id
        })

        this.$swal(`Tersimpan`, 'Blacklist crew berhasil', 'success')
      }

      this.loading = false
      this.closeSidebar('blacklist-crew')
      this.form.blacklist.reason_off = ''
      this.form.blacklist.remarks = ''
    },

    async onSaveNoticeSignOff(e){
      e.preventDefault()
      this.notice_sign_off_loading = true

      let tempArr = []
      tempArr.push(this.notice_sign_off_form.reason_sign_off_id)

      const formData = new FormData()
      formData.append('reason_sign_off_id', tempArr)
      formData.append('notice_off', moment(this.notice_sign_off_form.notice_off).format('YYYY-MM-DD HH:mm') )
      formData.append('detail_notice_off', this.notice_sign_off_form.detail_notice_off)
      if(this.notice_sign_off_form.attachment_notice_off) formData.append('attachment_notice_off', this.notice_sign_off_form.attachment_notice_off)
  
      const { status, data } = await this.setNoticeSignOff({
          formData,
          id: this.form_crew.id,
        })

      if (status !== 'success') {
        this.$swal(`Oops!`, `${data}`, 'error')
        this.notice_sign_off_loading = false
        return
      }

      this.$swal(`Tersimpan`, 'Notice sign off crew berhasil', 'success')
      this.$bvModal.hide('notice_sign_off')
      this.notice_sign_off_loading = false
      this.notice_sign_off_form = {
        reason_sign_off_id: null,
        notice_off:null,
        detail_notice_off:'',
        attachment_notice_off: null
      }
    },

    async exportCrewList () {
      this.crewList.loading = true
      let params = {
        ...this.crewList.params
      }
      
      if(!params.fullname) delete params.fullname
      if(!params.vehicle_id) delete params.vehicle_id
      if(!params.position_id) delete params.position_id
      if(!params.status) delete params.status
      if(!params.from) delete params.from
      if(!params.to) delete params.to

      const res = await this.exportListCrew({params})

      if(res.status === 404 || res.status === 500 || res.status === 400 || res.status === 401){
        this.$swal(`Oops!`, `Something went wrong.`, 'error')
        this.crewList.loading = false
        return
      }

      saveAs(res.data, 'List Crew.xlsx')
      this.crewList.loading = false
    },  
    
    async exportCandidateList () {
      this.candidateList.loading = true
      let params = {
        ...this.candidateList.params
      }
      
      if(!params.full_name) delete params.full_name
      if(!params.position_id) delete params.position_id
      if(!params.status) delete params.status
      if(!params.from) delete params.from
      if(!params.to) delete params.to

      const res = await this.exportListCandidate({params})

      if(res.status === 404 || res.status === 500 || res.status === 400 || res.status === 401){
        this.$swal(`Oops!`, `Something went wrong.`, 'error')
        this.candidateList.loading = false
        return
      }

      saveAs(res.data, 'List Candidate.xlsx')
      this.candidateList.loading = false
    },
  },
}
</script>
