var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', _vm._l(_vm.tableData.head, function (state, index) {
    return _c('th', {
      key: index,
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state))]);
  }), 0)]), _c('tbody', _vm._l(_vm.tableData.body, function (data, index) {
    var _data$nip, _data$personal_data$s, _data$personal_data, _data$nip2, _data$personal_data$s2, _data$personal_data2, _data$vehicle$name, _data$vehicle, _data$company$company, _data$company, _data$position$name, _data$position, _data$status_emp_crew, _data$status_emp_crew2, _data$status_emp_crew3;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.indexes + index))]), !_vm.isPreview ? _c('td', [_c('router-link', {
      staticClass: "text-truncate",
      attrs: {
        "to": {
          path: "/crewing/profile",
          query: {
            user: 'crew',
            id: data.id,
            preview: _vm.isPreview
          }
        }
      }
    }, [_vm._v(_vm._s(data.fullname))]), _c('br'), _c('small', {
      staticClass: "text-truncate",
      staticStyle: {
        "color": "gray"
      }
    }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_data$nip = data === null || data === void 0 ? void 0 : data.nip) !== null && _data$nip !== void 0 ? _data$nip : '-'))]), _c('br'), _vm._v("Seaferer Code :" + _vm._s((_data$personal_data$s = data === null || data === void 0 ? void 0 : (_data$personal_data = data.personal_data) === null || _data$personal_data === void 0 ? void 0 : _data$personal_data.seaferer_code) !== null && _data$personal_data$s !== void 0 ? _data$personal_data$s : '-'))]), _c('br'), data !== null && data !== void 0 && data.is_delete ? _c('small', {
      staticClass: "text-danger text-truncate"
    }, [_vm._v("Waiting approval to delete this crew")]) : _vm._e()], 1) : _c('td', [_c('router-link', {
      staticClass: "text-truncate",
      attrs: {
        "target": "_blank",
        "to": {
          path: "/crewing/profile",
          query: {
            user: 'crew',
            id: data.id,
            preview: _vm.isPreview
          }
        }
      }
    }, [_vm._v(_vm._s(data.fullname))]), _c('br'), _c('small', {
      staticClass: "text-truncate",
      staticStyle: {
        "color": "gray"
      }
    }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_data$nip2 = data === null || data === void 0 ? void 0 : data.nip) !== null && _data$nip2 !== void 0 ? _data$nip2 : '-'))]), _c('br'), _vm._v("Seaferer Code :" + _vm._s((_data$personal_data$s2 = data === null || data === void 0 ? void 0 : (_data$personal_data2 = data.personal_data) === null || _data$personal_data2 === void 0 ? void 0 : _data$personal_data2.seaferer_code) !== null && _data$personal_data$s2 !== void 0 ? _data$personal_data$s2 : '-'))]), _c('br'), data !== null && data !== void 0 && data.is_delete ? _c('small', {
      staticClass: "text-danger text-truncate"
    }, [_vm._v("Waiting approval to delete this crew")]) : _vm._e()], 1), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_data$vehicle$name = data === null || data === void 0 ? void 0 : (_data$vehicle = data.vehicle) === null || _data$vehicle === void 0 ? void 0 : _data$vehicle.name) !== null && _data$vehicle$name !== void 0 ? _data$vehicle$name : '-')), _c('br'), _c('small', {
      staticClass: "text-truncate",
      staticStyle: {
        "color": "gray"
      }
    }, [_vm._v(_vm._s((_data$company$company = data === null || data === void 0 ? void 0 : (_data$company = data.company) === null || _data$company === void 0 ? void 0 : _data$company.company) !== null && _data$company$company !== void 0 ? _data$company$company : '-'))])]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_data$position$name = data === null || data === void 0 ? void 0 : (_data$position = data.position) === null || _data$position === void 0 ? void 0 : _data$position.name) !== null && _data$position$name !== void 0 ? _data$position$name : '-') + " ")]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(data !== null && data !== void 0 && data.join_on_board ? _vm.moment(data === null || data === void 0 ? void 0 : data.join_on_board).format('DD MMM YYYY HH:mm') : '-') + " "), _vm.joinOnBoardStatus(data) ? _c('b-badge', {
      staticClass: "text-truncate",
      attrs: {
        "variant": _vm.joinOnBoardStatus(data) === 'More than 1 year' ? 'success d-block' : _vm.joinOnBoardStatus(data) === 'More than 6 month' ? 'primary d-block' : _vm.joinOnBoardStatus(data) === 'More than 3 month' ? 'warning d-block' : 'dark d-block'
      }
    }, [_vm._v(_vm._s(_vm.joinOnBoardStatus(data)) + " ")]) : _vm._e()], 1), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(data !== null && data !== void 0 && data.sign_on ? _vm.moment(data === null || data === void 0 ? void 0 : data.sign_on).format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(data !== null && data !== void 0 && data.sign_off ? _vm.moment(data === null || data === void 0 ? void 0 : data.sign_off).format('DD MMM YYYY HH:mm') : '-') + " "), _c('br')]), _c('td', {
      staticClass: "text-center"
    }, [data !== null && data !== void 0 && data.notice_off ? _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(data !== null && data !== void 0 && data.notice_off ? _vm.moment(new Date(data === null || data === void 0 ? void 0 : data.notice_off)).format('DD MMM YYYY HH:mm') : '-'))]), _c('span', {
      staticClass: "text-muted text-capitalize"
    }, [_vm._v("'" + _vm._s(data === null || data === void 0 ? void 0 : data.detail_notice_off) + "'")])]) : _c('span', [_vm._v("-")])]), _c('td', {
      staticClass: "text-center"
    }, [_c('b-badge', {
      staticClass: "mb-1 text-truncate",
      attrs: {
        "variant": "".concat((data === null || data === void 0 ? void 0 : (_data$status_emp_crew = data.status_emp_crewing) === null || _data$status_emp_crew === void 0 ? void 0 : _data$status_emp_crew.alias) === 'active' ? 'success' : 'warning', " d-block")
      }
    }, [_vm._v(_vm._s((_data$status_emp_crew2 = data === null || data === void 0 ? void 0 : (_data$status_emp_crew3 = data.status_emp_crewing) === null || _data$status_emp_crew3 === void 0 ? void 0 : _data$status_emp_crew3.name) !== null && _data$status_emp_crew2 !== void 0 ? _data$status_emp_crew2 : '-') + " ")]), _vm.expiredContract(data) ? _c('b-badge', {
      staticClass: "text-truncate",
      attrs: {
        "variant": "danger d-block"
      }
    }, [_vm._v(_vm._s(_vm.expiredContract(data)) + " ")]) : _vm._e()], 1), !_vm.isPreview ? _c('td', {
      staticClass: "text-center"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.swap-position",
        modifiers: {
          "swap-position": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Swap Position")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.notice_sign_off",
        modifiers: {
          "notice_sign_off": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Notice Sign Off ")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.sign-off",
        modifiers: {
          "sign-off": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Sign Off")]), !data.status_condite_time ? _c('b-dropdown-item', [_c('router-link', {
      attrs: {
        "to": {
          path: (data === null || data === void 0 ? void 0 : data.position_id) === 1 || (data === null || data === void 0 ? void 0 : data.position_id) === 2 ? "/crewing/condite/".concat(data.id) : "/crewing/conduct/".concat(data.id),
          params: {
            id: data.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Penilaian Conduite")])], 1) : _vm._e(), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.handleDownloadPKL(data.id, data.fullname);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-file mr-2"
    }), _vm._v("Download Surat PKL")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.blacklist-crew",
        modifiers: {
          "blacklist-crew": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v("Black List")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.delete-crew",
        modifiers: {
          "delete-crew": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-user mr-2"
    }), _vm._v("Delete Crew")])], 1)], 1) : _vm._e()]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }