<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
            <h5 class="card-title text-primary"><strong>SHIPMENT REAL</strong></h5>
        </template>
        <!-- <template v-slot:headerAction>
          <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
              <template v-slot:button-content>
                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                  <i class="fa fa-save m-0"></i>
                  </span>
              </template>
              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
          </b-dropdown>
          <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>
          <b-button @click="realisasiData = !realisasiData;"  variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> &nbsp; ADD REALISASI DATA</b-button>
        </template> -->
        <template v-slot:body>
          <!-- FILTER START -->
          <b-row>
              <b-col md="3">
                <b-form-group label="Period" label-for="Period">
                  <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Cargo Type" label-for="cargo">
                  <b-form-select plain>
                    <b-form-select-option>All</b-form-select-option>
                    <b-form-select-option>COAL</b-form-select-option>
                    <b-form-select-option>SPLIT STONE</b-form-select-option>
                    <b-form-select-option>NICKEL</b-form-select-option>
                    <b-form-select-option>CPO</b-form-select-option>
                    <b-form-select-option>ASD</b-form-select-option>
                    <b-form-select-option>OTHERS</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Ownership" label-for="cargo">
                  <b-form-select plain>
                    <b-form-select-option>All</b-form-select-option>
                    <b-form-select-option>Own Ship</b-form-select-option>
                    <b-form-select-option>Third Party</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Keyword" label-for="keyword">
                  <b-form-input type="text" id="keyword"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group style label="Search" label-for="button_search">
                  <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
                </b-form-group>
              </b-col>
          </b-row>
          <!-- FILTER END -->

          <b-row>
            <b-col md="12">
              <div class="d-flex overflow-auto">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th style="width:5px" class="text-center table-info text-truncate">NO</th>
                      <th class="text-center table-info text-truncate">SHIPPER</th>                       
                      <th class="text-center table-info text-truncate">TYPE</th>                       
                      <th class="text-center table-info text-truncate">SHIPMENT PLAN</th>                       
                      <th class="text-center table-info text-truncate">VOLUME PLAN</th>                       
                      <th class="text-center table-info text-truncate">SHIPMENT REAL</th>                       
                      <th class="text-center table-info text-truncate">VOLUME REAL</th>                       
                      <th class="text-center table-info">STAGE</th>                       
                      <th class="text-center table-info">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">1</td>
                      <td class="text-center text-truncate">-</td>
                      <td class="text-center text-truncate">-</td>
                      <td class="text-center text-truncate">-</td>
                      <td class="text-center text-truncate">-</td>
                      <td class="text-center text-truncate">-</td>
                      <td class="text-center text-truncate">-</td>
                      <td class="text-center">
                        <b-dropdown variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret size="sm">
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton6" data-toggle="dropdown">
                              WAITING
                            </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary">
                            <strong>WAITING</strong>
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="secondary">
                            <strong>PROCESS</strong>
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="secondary">
                            <strong>RUNNING</strong>
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="secondary">
                            <strong>REJECTED</strong>
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="secondary">
                            <strong>DONE</strong>
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                      <td class="text-center">
                        <b-dropdown 
                          size="lg"
                          variant="link iq-bg-primary iq-border-radius-10"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary" @click="openSlideDetailRealisasi">
                            <i class="fa fa-eye mr-2"></i>Detail
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="primary" @click="openSlideFormRealShipment('ADD', null)">
                            <i class="fa fa-plus mr-2"></i>Add Real Shipment
                          </b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#" variant="danger" >
                            <i class="fa fa-arrow-left mr-2"></i>Back to Plan
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col md="12">
              <div class="mt-3">
                <b-pagination :value="1" :total-rows="50" align="center" />
              </div>
            </b-col>
          </b-row>

          <!-- REALISASI DATA -->
          <b-sidebar
            v-model="realisasiData"
            id="realisasiData"
            title="ADD REALISASI DATA"
            width="90%"
            backdrop-variant="dark"
            bg-variant="white"
            right
            backdrop
            shadow>
            <template #default="{hide}">
              <b-row class="m-3">
                <b-col md="12">
                  <fieldset class="form-group border p-3 bg-default">
                    <legend class="w-auto px-2 h5 text-primary">Find Data</legend>
                    <b-form-row>
                      <b-col md="2">
                        <b-form-group label="Realisasi Period" label-for="Period">
                          <date-picker :lang="lang" v-model="realisasiPeriod" type="month" placeholder="Select Month"></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label-for="dateIssued" label="Project Filter">
                          <v-multiselect v-model="filterDailyOps.subProject" :options="projectOptions" :multiple="true" group-values="sub" group-label="project" :group-select="true" placeholder="Select one" track-by="name" label="name">
                            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                          </v-multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Search" label-for="kind-fleet" class="mb-0">
                          <b-button type="submit" variant="success"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Select Shipper Data</legend>
                    <b-row class="pl-3 pr-3">
                      <b-col cols="12">
                        <b-alert :show="true" variant=" " class="text-white bg-warning">
                          <div class="iq-alert-icon">
                            <i class="ri-information-fill"></i>
                          </div>
                          <div class="iq-alert-text">Not Found Data of Shipper in this period</div>
                        </b-alert>
                      </b-col>
                      <b-col md="12">
                        <div class="table-responsive">
                          <table class="table table-striped table-sm">
                            <thead>
                              <tr class="bg-primary">
                                <th class="text-center">NO</th>
                                <th class="text-center">TYPE</th>
                                <th class="text-center">PROJECT</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="iq-bg-primary">
                                <th class="text-center">1</th>
                                <th class="text-center"><b-badge class="border border-primary text-primary" variant="none">EXISTING</b-badge></th>
                                <td class="text-center"><strong>ARUTMIN</strong></td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <table class="table mb-0" width="100%">
                                    <thead>
                                      <tr class="bg-info">
                                        <th>#</th>
                                        <th width="17%">Description</th>
                                        <th>Fleet</th>
                                        <th>Cargo & Term</th>
                                        <th>Location</th>
                                        <th class="text-center" width="18%">Shipment Plan</th>
                                        <th class="text-center">Plan Quantity</th>
                                        <th>Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td><input type="checkbox" name="shipper1" id="shipper1"/></td>
                                        <td>PLN Rembang</td>
                                        <td><b-badge variant="info">Third Party</b-badge><br/> TB.Dharma 1</td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                            <p><strong>COAL</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                            <p><strong>CIF</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                            <p><strong>Paiton</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                            <p><strong>Rembang</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-row>
                                            <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POL</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POD</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>10 Januari 2023</p>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>16 Januari 2023</p>
                                            </b-col>
                                          </b-row>
                                        </td>
                                        <td class="text-center"><strong>7.000 MT</strong></td>
                                        <td>-</td>
                                      </tr>
                                      <tr>
                                        <td><input type="checkbox" name="shipper2" id="shipper2"/></td>
                                        <td>PLN Rembang</td>
                                        <td><b-badge variant="primary">Own Ship</b-badge><br/> TB.ETI 102</td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                            <p><strong>COAL</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                            <p><strong>CIF</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                            <p><strong>Paiton</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                            <p><strong>Rembang</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-row>
                                            <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POL</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POD</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>19 Januari 2023</p>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>23 Januari 2023</p>
                                            </b-col>
                                          </b-row>
                                        </td>
                                        <td class="text-center"><strong>7.500 MT</strong></td>
                                        <td>-</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr class="iq-bg-primary">
                                <th class="text-center">2</th>
                                <th class="text-center"><b-badge variant="success" style="font-size:12pt;">New</b-badge></th>
                                <td class="text-center"><strong style="font-size:12pt;">Pertamina Gas</strong></td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <table class="table mb-0" width="100%">
                                    <thead>
                                      <tr class="bg-info">
                                        <th>#</th>
                                        <th width="17%">Description</th>
                                        <th>Fleet</th>
                                        <th>Cargo & Term</th>
                                        <th>Location</th>
                                        <th class="text-center" width="18%">Shipment Plan</th>
                                        <th class="text-center">Plan Quantity</th>
                                        <th>Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td><input type="checkbox" name="shipper3" id="shipper3"/></td>
                                        <td>PLN Rembang</td>
                                        <td><b-badge variant="info">Third Party</b-badge><br/> TB.Dharma 1</td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                            <p><strong>COAL</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                            <p><strong>CIF</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                            <p><strong>Paiton</strong></p>
                                          </b-form-group>
                                          <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                            <p><strong>Rembang</strong></p>
                                          </b-form-group>
                                        </td>
                                        <td>
                                          <b-row>
                                            <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POL</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <strong>ETA POD</strong>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>10 Januari 2023</p>
                                            </b-col>
                                            <b-col md="6" class="text-center">
                                              <p>16 Januari 2023</p>
                                            </b-col>
                                          </b-row>
                                        </td>
                                        <td class="text-center"><strong>29.000 MT</strong></td>
                                        <td>-</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 h5 text-primary">Select Operational Data</legend>
                    <b-row class="pl-3 pr-3">
                      <b-col cols="12">
                        <b-alert :show="true" variant=" " class="text-white bg-warning">
                          <div class="iq-alert-icon">
                            <i class="ri-information-fill"></i>
                          </div>
                          <div class="iq-alert-text">Operational Not Yet Input Data For This Period, Please Input First</div>
                        </b-alert>
                      </b-col>
                      <b-col md="12">
                        <div class="table-responsive">
                          <table class="table table-striped" style="width: 3700px;max-height: 750px;overflow-y: scroll;">
                            <thead class="thead-dark text-center">
                              <tr>
                                <th class="text-center" style="position:sticky;left:0px;background-color:#039BAB;" width="50px">#</th>
                                <th class="text-center" style="position:sticky;left:45px;background-color:#039BAB;" width="250px">PROJECT</th>
                                <th class="text-center" style="position:sticky;left:295px;background-color:#039BAB;" width="150px">FLEET</th>
                                <th class="text-center" style="position:sticky;left:425px;background-color:#039BAB;" width="250px">STATUS</th>
                                <th class="text-center" width="127px">MODA</th>
                                <th class="text-center" width="197px">COMPANY</th>
                                <th class="text-center" width="127px">CARGO TYPE</th>
                                <th class="text-center" width="197px">POL</th>
                                <th class="text-center" width="187px">TA POL/READY</th>
                                <th class="text-center" width="147px">BERTHING</th>
                                <th class="text-center" width="147px">COMMENCED</th>                          
                                <th class="text-center" width="147px">COMPLETED</th>
                                <th class="text-center" width="147px">CARGO LOADING</th>  
                                <th class="text-center" width="197px">POD</th>
                                <th class="text-center" width="147px">TA POD/READY</th>
                                <th class="text-center" width="147px">BERTHING</th>
                                <th class="text-center" width="147px">COMMENCED</th>                          
                                <th class="text-center" width="147px">COMPLETED</th>
                                <th class="text-center" width="147px">CARGO UNLOADING</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style="position:sticky;left:0px;background-color:#039BAB;color:#ffffff;"><input type="checkbox" name="voyage" id="voyage"/></td>
                                <td style="position:sticky;left:45px;background-color:#039BAB;color:#ffffff;">AI PROJECT - LONG HAULING</td>
                                <td style="position:sticky;left:295px;background-color:#039BAB;color:#ffffff;">PT.TCP 201/PB.TCP 3301</td>
                                <td style="position:sticky;left:425px;background-color:#039BAB;color:#ffffff;"><b-badge variant="success" style="font-size:12pt;">Completed</b-badge></td>
                                <td>330 ft</td>
                                <td>PT.Transcoal Pacific</td>
                                <td>Nickel</td>
                                <td>MP Asam2</td>
                                <td>31-Dec-21 00.00</td>
                                <td>02-Jan-22 02.10</td>
                                <td>02-Jan-22 03.05</td>
                                <td>02-Jan-22 05.05</td>
                                <td>7.606 MT</td>
                                <td>LABUAN</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>7.506 MT</td>
                              </tr>
                              <tr>
                                <td style="position:sticky;left:0px;background-color:#039BAB;color:#ffffff;"><input type="checkbox" name="voyage" id="voyage"/></td>
                                <td style="position:sticky;left:45px;background-color:#039BAB;color:#ffffff;">AI PROJECT - LONG HAULING</td>
                                <td style="position:sticky;left:295px;background-color:#039BAB;color:#ffffff;">PT.TCP 202/PB.TCP 3302</td>
                                <td style="position:sticky;left:425px;background-color:#039BAB;color:#ffffff;"><b-badge variant="success" style="font-size:12pt;">Completed</b-badge></td>
                                <td>330 ft</td>
                                <td>PT.Transcoal Pacific</td>
                                <td>Nickel</td>
                                <td>MP Asam2</td>
                                <td>31-Dec-21 00.00</td>
                                <td>02-Jan-22 02.10</td>
                                <td>02-Jan-22 03.05</td>
                                <td>02-Jan-22 05.05</td>
                                <td>7.606 MT</td>
                                <td>LABUAN</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>04-Jan-22 20.00</td>
                                <td>7.506 MT</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col md="12">
                        <b-row>
                          <b-col>
                            <b-form-group label-for="dateIssued" label="Company">
                              <b-form-select
                                plain
                                :options="CompanyName"
                                id="gender"
                                v-model="companySelected"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Price" label-for="cost">
                              <b-input-group size="md" prepend="Rp">
                                <b-form-input type="number" class="form-control"></b-form-input>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Price to Vendor" label-for="cost">
                              <b-input-group size="md" prepend="Rp">
                                <b-form-input type="number"></b-form-input>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col md="12" class="mt-5">
                  <b-button size="lg" variant="success" block>
                    <i class="fa fa-save"></i> Save Data
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-sidebar>
        </template>
      </iq-card>
    </b-col>

    <!-- SLIDE - FORM REAL SHIPMENT -->
    <b-col md="12">
      <b-sidebar
        v-model="slideFormRealShipment"
        id="slideFormRealShipment"
        :title="`Create Real Shipment`"
        width="90%"
        backdrop-variant="dark"
        bg-variant="white"
        right
        backdrop
        shadow>
        <template #default="{hide}">
          <SlideFormRealShipment
            :closeSlideFormRealShipment="closeSlideFormRealShipment"
            :refreshData="() => {}"
            :projectData="{}"
            :id="null"
            :action="'ADD'"
          />
        </template>
      </b-sidebar>
    </b-col>
    <!-- SLIDE - FORM REAL SHIPMENT END -->

    <!-- SLIDE - DETAIL REALISASI -->
    <b-col md="12">
      <b-sidebar
        v-model="slideDetailRealisasi"
        id="slideDetailRealisasi"
        :title="`Detail Real Shipment`"
        width="100%"
        backdrop-variant="dark"
        bg-variant="white"
        right
        backdrop
        shadow>
        <template #default="{hide}">
          <SlideDetailRealisasi />
        </template>
      </b-sidebar>
    </b-col>
    <!-- SLIDE - DETAIL REALISASI END -->
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions } from '@src/Utils/helper'
import SlideFormRealShipment from './SlideFormRealShipment.vue'
import SlideDetailRealisasi from './SlideDetailRealisasi.vue'

export default {
  name: 'TabRealisasi',
  data () {
    return {
      id: null, 
      action:'',

      // Slide
      slideFormRealShipment: false,
      slideDetailRealisasi: false,
    }
  },
  components:{
    SlideFormRealShipment,
    SlideDetailRealisasi,
  },
  async mounted () {
    await this.getProjectList()
    await this.fetchFleets()
    await this.getCompanyListData()
  },
  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    closeSlideFormRealShipment(){
      this.id = null
      this.action = ''
      this.slideFormRealShipment = false
    },  
    openSlideFormRealShipment(value, id){
      this.id = null
      this.action = value
      this.slideFormRealShipment = !this.slideFormRealShipment;

      if(id) this.id = id
    },

    closeSlideDetailRealisasi(){
      this.action = ''
      this.slideDetailRealisasi = false
    },  
    openSlideDetailRealisasi(value, id){
      this.id = null
      this.slideDetailRealisasi = !this.slideDetailRealisasi;

      if(id) this.id = id
    },
 
  }
}
</script>