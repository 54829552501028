<template>
  <b-row>
    <b-col lg="12" class="px-5 py-2">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>List Expired Crew</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12" class="text-right mb-4">
              <b-button variant="primary" @click.prevent="onExportExpiredCrew" type="button"  v-if="list?.data?.length > 0" >
                  <div class="d-flex gap-2 align-items-center"> 
                    <span class="spinner-border spinner-border-sm mr-2" v-if="exporting"></span>
                    <i class="fa fa-file-excel mr-2" v-else></i> 
                    <span>Export</span>
                  </div>
                </b-button>
            </b-col>
            <b-col md="3" class="mb-2">
              <b-form-group label="Search">
                <b-form-input class="mr-2" v-model="params.filter" placeholder="Search name..."></b-form-input>
              </b-form-group>
            </b-col> 
            <b-col md="4">
              <b-form-group label="Company">
                <v-select
                  v-model="params.company_id"
                  label="text"
                  :options="optionsCompanies"
                  :reduce="
                    (optionsCompanies) => optionsCompanies.value
                  "
                  placeholder="Select..."
                ></v-select>
              </b-form-group>
            </b-col> 
            <b-col md="4" class="mb-2">
              <b-form-group label="Date">
                <date-picker
                v-model="dateRange"
                type="date"
                range
                placeholder="Select date range"
              ></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="1" class="mb-2">
              <b-form-group label="Filter">
                <b-button
                  @click="getListExpiredCrew"
                  variant="primary"
                  ><i class="fa fa-filter"></i
                ></b-button>
              </b-form-group>
            </b-col>
            <b-col v-if="loading" md="12">
              <div class="alert alert-info m-4 align-items-center ">
                <span class="spinner-border spinner-border-sm mr-2"></span>  Loading...
              </div>
            </b-col>
            <b-col md="12" v-else>
              <div class="d-flex overflow-auto " v-if="list?.data?.length > 0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class=" text-center">NO</th>
                      <th class=" text-center">Name</th>
                      <th class=" text-center text-truncate">Fleet</th>
                      <th class=" text-center text-truncate">Position</th>
                      <th class=" text-center text-truncate">Join on Board</th>
                      <th class=" text-center text-truncate">Sign On</th>
                      <th class=" text-center text-truncate">Sign Off</th>
                      <th class=" text-center">Status</th>
                    </tr>
                  </thead>
  
                  <tbody>
                    <tr v-for="(crew, i) in list?.data" :key="'crew-' + i" >
                      <td class="text-center">{{ list?.from + i }}</td>
                      <td class="text-truncate">
                        <router-link
                          :to="{
                            path: `profile`,
                            query: {
                              user: 'crew',
                              id: crew.id,
                            },
                          }">{{ crew.fullname }}</router-link><br />
                          <small style="color: gray">NIP : <b>{{ crew?.nip ?? '-' }}</b></small>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.vehicle?.name ?? '-' }}
                        <br />
                        <small style="color: gray">{{crew?.company?.company ?? '-'}}</small>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.position?.name ?? '-' }} </td>
                      <td class="text-center text-truncate ">{{ crew?.join_on_board ? moment(crew?.join_on_board).format('DD MMM YYYY HH:mm') : '-' }}
                        <b-badge
                          :variant="
                            joinOnBoardStatus(crew) === 'More than 1 year'
                              ? 'success d-block'
                              : joinOnBoardStatus(crew) === 'More than 6 month'
                              ? 'primary d-block'
                              : joinOnBoardStatus(crew) === 'More than 3 month'
                              ? 'warning d-block'
                              : 'dark d-block'
                          "
                          v-if="joinOnBoardStatus(crew)">{{ joinOnBoardStatus(crew) }}</b-badge>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.sign_on ? moment(crew?.sign_on).format('DD MMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center text-truncate">{{ crew?.sign_off ? moment(crew?.sign_off).format('DD MMM YYYY HH:mm') :  '-' }} <br /> </td>
                      <td class="text-center">
                        <b-badge class="mb-1" :variant="`${crew?.status_emp_crewing?.alias === 'active' ? 'success' : 'warning'} d-block`"
                          >{{ crew?.status_emp_crewing?.name ?? '-' }}
                        </b-badge>
                        <b-badge variant="danger d-block" v-if="expiredContract(crew)"
                          >{{ expiredContract(crew) }}
                        </b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3" v-if="list?.data?.length > 0">
                <b-pagination
                  v-model="params.page"
                  :total-rows="list?.total"
                  :per-page="list?.per_page"
                  first-number
                  @change="getListExpiredCrew"
                  align="center"
                />
              </div>
              <p v-else class="text-center my-4">List Expired Crew tidak ada.</p>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import { xray } from '../../config/pluginInit'
import moment from 'moment'
import { crewingActions } from '@src/Utils/helper'
import { saveAs } from 'file-saver'

export default {
  name: 'SlideExpiredCrew',
  props:{
    optionsCompanies:{
      type:Array,
      default:() => []
    },
  },
  data(){
    return {
      loading:false,
      exporting:false,

      dateRange: [new Date(moment().startOf('year')), new Date(moment().endOf('year'))],

      params:{
        page:1,
        per_page:50,
        filter:'',
        company_id:null,
      },
      list:null
    }
  },
  async mounted() {
    xray.index()

    await this.getListExpiredCrew()
  },
  created() {
    this.getListExpiredCrew = _.debounce(this.getListExpiredCrew, 500)
  },
  methods:{
    ...crewingActions,
    joinOnBoardStatus(data) {
      if (!data.join_on_board) return ''
      if (data.working_period > 12) {
        return 'More than 1 year'
      } else if (data.working_period > 6) {
        return 'More than 6 month'
      } else if (data.working_period > 3) {
        return 'More than 3 month'
      } else {
        return 'Less than 3 month'
      }
    },
    expiredContract(data) {
      return moment(data?.sign_off).format('DD-MM-YYYY') <
        moment(new Date()).format('DD-MM-YYYY') &&
        data?.status_emp_crewing?.name === 'Active'
        ? 'Out of Contract'
        : ''
    },

    async getListExpiredCrew(page){
      this.loading = true

      let params = {
        page: page || 1,
        ...this.params,
        start_date: this.dateRange?.[0] ? moment(new Date(this.dateRange[0])).format('YYYY-MM-DD') : null,
        end_date: this.dateRange?.[1] ? moment(new Date(this.dateRange[1])).format('YYYY-MM-DD') : null
      }

      if(!params.filter) delete params.filter
      if(!params.start_date) delete params.start_date
      if(!params.end_date) delete params.end_date
      if(!params.company_id) delete params.company_id

      const res = await this.fetchExpiredCrew(params)

      if(res.status === "success"){
        this.list = res.data
        this.loading = false
      } else {
        this.list = null
        this.loading = false
      }
    },
    async onExportExpiredCrew(){
      try {
        this.exporting = true

        let params = {
          export: 1,
          filter: this.filter
        }
        if(!params.filter) delete params.filter

        const res = await this.exportExpiredCrew(params)

        saveAs(res.data, 'Expired Crew.xlsx')
        this.exporting = false
      } catch (error) {
        this.$swal(`Oops!`, 'Export Failed: ' + error, 'error')
        this.exporting = false
      }
    },
  }
}
</script>