<template>
    <b-row class="m-3">
      <b-col md="12" v-if="!loading">
        <b-row>
          <b-col lg="12" class="mb-4">
            <div :class="`d-flex align-items-center justify-content-end w-100`">
              <b-button
                type="button"
                variant="danger"
                class="mr-3"
                @click="closeSlideHistoryMail"
              >
                <div>
                  <i class="fa fa-ban"></i>
                  <span>Cancel</span>
                </div>
              </b-button>
            </div>
          </b-col>
          <b-col md="12">
            <h5 class="card-title text-primary"><strong>HISTORY MAIL</strong></h5>
            <ul class="iq-timeline m-0 p-0" style="height:650px">
              <li v-for="(state, index) in history" :key="index + 1">
                <b-row class="m-0">
                  <b-col md="12">
                    <div class="timeline-dots-fill"></div>
                    <div class="d-flex flex-column mb-2">
                      <span class="text-primary">Sender: </span>
                      <strong>{{ state?.sender_mail }}</strong>
                      <small>{{ state?.sender_name }}</small>
                    </div>
                    <div class="d-flex flex-column mb-2">
                      <span class="text-primary">Receiver: </span>
                      <strong>to: {{ state?.to }}</strong>
                      <strong>cc: {{ state?.cc }}</strong>
                    </div>
                    <div class="d-flex flex-column" v-if="state?.attachment?.length > 0">
                      <span class="text-primary mb-1">Attachments: </span>
                      <div class="d-flex flex-wrap">
                        <img 
                          v-for="(file, fileIndex) in state?.attachment" 
                          :key="fileIndex" 
                          src="../../../assets/images/activity/pdf.png" 
                          alt="img" 
                          height="30px"
                          style="cursor:pointer"
                          class="mr-2 mb-2"
                          @click="handleOpenFilePreview(`${apiUrl}/${file?.file_url}`)"
                        />
                      </div>
                    </div>
                    <hr>
                  </b-col>
                </b-row>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="12" v-else>
        <div class="alert alert-info m-4">
          Loading...
        </div>
      </b-col>
    </b-row>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'
  import { saveAs } from 'file-saver'

  export default {
    name:'SlideHistoryMail',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      }, 
      closeSlideHistoryMail:{
        type:Function,
        default:() => {}
      },  
    },
    components:{
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,

        saving: false,
        loading: false,
        
        user_id: JSON.parse(localStorage.getItem('profile')).id,
        user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
      }
    },
    watch:{
      id: {
        deep: true,
        immediate:true,
        handler: function (value) {
          if (value) {
            this.getHistoryMail(value)
          }
        },
      },
    },
    mounted(){
    },
    computed:{
    },
    methods:{
      ...busdevActions,
      handleOpenFilePreview: function (url) {
        window.open(url, "_blank");
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
      async getHistoryMail(id){
        this.loading = true

        let params = {
          payment_id: this.id
        }

        const res = await this.fetchHistoryMail(params)

        if(res?.data?.status === true){
          this.history = res.data.data
        } else {
          this.history = []
        }

        this.loading = false
      },
    }
  } 
</script>

<!-- <style scoped>
/* Ensure file input is hidden */
#file-upload {
  display: none;
}
</style> -->
