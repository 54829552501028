<template>
  <div class="d-flex overflow-auto">
    <table class="table table-striped">
      <thead>
        <tr>
          <th
            class="text-center text-truncate"
            v-for="(state, index) in tableData.head"
            :key="index"
            >{{ state }}</th
          >
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in tableData.body" :key="`data-${index}`">
          <td class="text-center">{{ indexes + index }}</td>
          <td>
            <router-link
              :to="{
                path: `profile`,
                query: {
                  user: 'candidate',
                  id: data.id,
                },
              }"
              >{{ data.full_name }}</router-link><br />
              <small style="color: gray">Seaferer Code : {{ data.seaferer_code }}</small> <br />
              <small class="text-danger truncate" v-if="data?.is_delete">Waiting approval to delete this candidate</small>
          </td>
          <td class="text-center">{{ data.name_position }}</td>
          <!-- <td class="text-center">{{
            data.apply_date
              ? moment(new Date(data.apply_date)).format('DD MMMM YYYY')
              : '-'
          }}</td> -->
          <td class="text-center">{{
            data.date_interview
              ? moment(new Date(data.date_interview)).format(
                  'DD MMM YYYY HH:mm'
                )
              : '-'
          }}</td>
          <td class="text-center">{{ data.expert || '-' }}</td>
          <td class="text-center">
            <b-badge
              :variant="`${
                data.status === 'candidate'
                  ? 'info'
                  : data.status === 'expired_interview'
                  ? 'danger'
                  : data.status === 'waiting_result'
                  ? 'success'
                  : 'primary'
              } d-block`"
            >
              {{ data.status.split('_').join(' ') }}
            </b-badge>
          </td>
          <td class="text-center">
            <b-dropdown
              size="lg"
              variant="link iq-bg-primary iq-border-radius-10"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <span
                  class="dropdown-toggle p-0"
                  id="dropdownMenuButton5"
                  data-toggle="dropdown"
                >
                  <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                </span>
              </template>

              <b-dropdown-item
                href="#"
                :variant="'primary'"
                v-b-toggle.sign-on
                @click="() => setDataUser(data)"
                v-if="data.status !== 'candidate' && permission"
              >
                  <i class="fa fa-user mr-2"></i>
                  Bypass Process to Crew
              </b-dropdown-item>

              <b-dropdown-divider v-if="data.status !== 'candidate' && permission === true"></b-dropdown-divider>

              <b-dropdown-item
                href="#"
                @click="proceedToRecruitment(data)"
                :variant="data.status === 'candidate' ? 'primary' : 'secondary'"
                :disabled="data.status !== 'candidate'"><i class="fa fa-check mr-2"></i
                >Proceed to Recruitment
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                v-b-modal.verification_document
                @click="() => setDataUser(data)"
                :variant="
                  data.status === 'checklist' || data.status === 'set_interview'
                    ? 'primary'
                    : 'secondary'
                "
                :disabled="
                  data.status === 'checklist' || data.status === 'set_interview'
                    ? false
                    : true
                "
                ><i class="fa fa-check mr-2"></i>Verification
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                v-b-modal.setup-interview
                :disabled="
                  data.status === 'set_interview' || data.status === 'interview'
                    ? false
                    : true
                "
                @click="() => setDataUser(data)"
                :variant="
                  data.status === 'set_interview' || data.status === 'interview'
                    ? 'primary'
                    : 'secondary'
                "
              >
                <i class="fa fa-calendar mr-2"></i>Set Interview
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                :disabled="data.status !== 'interview'"
                @click="() => emailPromptCandidate(data)"
                :variant="
                  data.status === 'interview'
                    ? 'primary'
                    : 'secondary'
                "
              >
                <i class="fa fa-calendar mr-2"></i>Send Email to Candidate
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                :disabled="data.status !== 'interview'"
                @click="() => emailPromptInterviewer(data)"
                :variant="
                  data.status === 'interview'
                    ? 'primary'
                    : 'secondary'
                "
              >
                <i class="fa fa-calendar mr-2"></i>Send Email to Interviewer
              </b-dropdown-item>

              <b-dropdown-item
                :disabled="
                  data.status === 'interview' || data.status === 'mcu'
                    ? false
                    : true
                "
                @click="() => setDataUser(data)"
              >
                <router-link
                  :class="
                    data.status === 'interview' || data.status === 'mcu'
                      ? 'text-primary'
                      : 'text-secondary'
                  "
                  :to="{
                    path: `/crewing/interview-review/${data.id}`,
                    params: { id: data.id },
                  }"
                >
                  <i class="fa fa-address-card mr-2"></i>Interview
                  Review</router-link
                >
              </b-dropdown-item>

              <b-dropdown-item
                href="#"
                v-b-modal.medical-checkup
                @click="() => setDataUser(data)"
                :disabled="
                  data.status === 'mcu' || data.status === 'induction'
                    ? false
                    : true
                "
                :variant="
                  data.status === 'mcu' || data.status === 'induction'
                    ? 'primary'
                    : 'secondary'
                "
                ><i class="fas fa-stethoscope mr-2"></i>Medical
                Checkup</b-dropdown-item
              >

              <b-dropdown-item
                href="#"
                v-b-modal.induction
                @click="() => setDataUser(data)"
                :variant="
                  data.status === 'induction' || data.status === 'contract'
                    ? 'primary'
                    : 'secondary'
                "
                :disabled="
                  data.status === 'induction' || data.status === 'contract'
                    ? false
                    : true
                "
                ><i class="fa fa-file mr-2"></i>Induction</b-dropdown-item
              >

              <b-dropdown-item
                v-b-toggle.sign-on
                href="#"
                @click="() => setDataUser(data)"
                :variant="'primary'"
                v-if="data.status === 'contract'"
                ><i class="fas fa-handshake mr-2"></i>Contract / Sign
                On</b-dropdown-item>

              <b-dropdown-item
                v-else
                :variant="'secondary'"
                disabled
                ><i class="fas fa-handshake mr-2"></i>Contract / Sign
                On</b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                href="#"
                v-b-toggle.blacklist-crew
                @click="() => setDataUser(data)"
                variant="danger"
                ><i class="fa fa-ban mr-2"></i>Black List</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-b-modal.delete-candidate
                @click="() => setDataUser(data)"
                variant="danger"
                ><i class="fas fa-user mr-2"></i>Delete Candidate</b-dropdown-item
              >
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { crewingActions } from '@src/Utils/helper'

export default {
  data() {
    return {
      key: 'candidate',
      permission:true
      // permission:JSON.parse(localStorage.getItem('profile')).menu[1].child[5].child[0].child[0]
    }
  },
  props: {
    indexes: {
      type: Number,
      default: 1,
    }, 
    currentPage: {
      type: Number,
      default: false,
    },
    tableData: {
      type: Object,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return {
          id: null,
          key: '',
        }
      },
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
  },
  mounted() {},
  methods: {
    ...crewingActions,
    async proceedToRecruitment(data) {
      let changeStatus = new FormData()

      changeStatus.append('change_status', 'checklist')

      const res = await this.changeStatusCandidate({
        id: data.id,
        change_status: changeStatus,
      })

      if (res.status === 'success') {
        data.status = 'checklist'
        this.$swal(
          `Success!`,
          `Candidate melanjutkan ke proses rekrut`,
          'success'
        )
      }
    },
    setDataUser(state) {
      this.form.id = state.id
      this.form.key = 'candidate'
    },
    setUserId(state) {
      this.form.id = state.id
      this.form.key = ''
    },
    async emailPromptCandidate(data){
      this.$swal({
        title: 'Apakah anda yakin ingin mengirimkan email ke candidate?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await this.sendEmailToCandidate({id:data.id})
          if(res.status === false || res.status === 400 || res.status === 500){
            this.$swal(
              'Failed!',
              'Something went wrong.',
              'error'
            )
            return 
          } 
          this.$swal(
            'Success!',
            'Berhasil mengirimkan email ke candidate.',
            'success'
          )
        }
      })
    },
    async emailPromptInterviewer(data){
      this.$swal({
        title: 'Apakah anda yakin ingin mengirimkan email ke interviwer?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await this.sendEmailToInterviewer({id:data.id})
          if(res.status === false || res.status === 400 || res.status === 500){
            this.$swal(
              'Failed!',
              'Something went wrong.',
              'error'
            )
            return 
          } 
          this.$swal(
            'Success!',
            'Berhasil mengirimkan email ke Interviewer.',
            'success'
          )
        }
      })
    }
  },
}
</script>
