var render = function render(){
  var _vm$projectData, _vm$projectData$proje, _vm$projectData$proje2, _vm$projectData2, _vm$projectData2$proj, _vm$projectData3, _vm$projectData3$cust, _vm$projectData4, _vm$projectData4$cust, _vm$projectData5, _vm$projectData6, _vm$projectData7, _vm$projectData8, _vm$projectData9, _vm$projectData10, _vm$projectData10$con, _vm$projectData$cargo, _vm$projectData11, _vm$projectData11$car, _vm$projectData$terms, _vm$projectData12, _vm$projectData12$ter, _vm$totalQuantityAdd, _vm$projectData13, _vm$projectData14, _vm$projectData15, _vm$projectData16, _vm$projectData17, _vm$projectData18, _vm$projectData19, _vm$projectData20, _vm$projectData21;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveRealShipment.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Submit  ")]) : _vm._e(), _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideFormRealShipment
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Cancel  ")])], 1), _c('b-col', {
    staticClass: "p-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project")]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Projects"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : (_vm$projectData$proje = _vm$projectData.project_sub) === null || _vm$projectData$proje === void 0 ? void 0 : (_vm$projectData$proje2 = _vm$projectData$proje.project) === null || _vm$projectData$proje2 === void 0 ? void 0 : _vm$projectData$proje2.project_name) + " - " + _vm._s((_vm$projectData2 = _vm.projectData) === null || _vm$projectData2 === void 0 ? void 0 : (_vm$projectData2$proj = _vm$projectData2.project_sub) === null || _vm$projectData2$proj === void 0 ? void 0 : _vm$projectData2$proj.name))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Customer",
      "label-for": "customer_id"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData3 = _vm.projectData) === null || _vm$projectData3 === void 0 ? void 0 : (_vm$projectData3$cust = _vm$projectData3.customer) === null || _vm$projectData3$cust === void 0 ? void 0 : _vm$projectData3$cust.customer_code) + " - " + _vm._s((_vm$projectData4 = _vm.projectData) === null || _vm$projectData4 === void 0 ? void 0 : (_vm$projectData4$cust = _vm$projectData4.customer) === null || _vm$projectData4$cust === void 0 ? void 0 : _vm$projectData4$cust.account_name))])])], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": '12'
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Detail Project Contract")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("-")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', {
    attrs: {
      "width": "30%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Fleets")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet ownership")]), _vm._v(" "), _c('br'), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s(((_vm$projectData5 = _vm.projectData) === null || _vm$projectData5 === void 0 ? void 0 : _vm$projectData5.fleet_ownership) === 'own-ship' ? "OWN SHIP" : 'CHARTERED'))])], 1), _vm.projectData.fleet_ownership ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet Type")]), _vm._v(" "), _c('br'), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("Tug & Barge")])], 1) : _vm._e()], 1), _vm.projectData.fleet_type_id ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_vm._v("Fleets")]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-warp"
  }, _vm._l(_vm.projectData.fleets, function (objDetail, detailIndex) {
    return _c('b-badge', {
      key: detailIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 00" + _vm._s(detailIndex + 1))]);
  }), 1)])], 1) : _vm._e()], 1)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Collection of Forms")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap gap-2"
  }, _vm._l(_vm.projectData.project_forms, function (state, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "border border-primary text-primary text-uppercase m-1",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.label) + " ")]);
  }), 1)]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', {
    attrs: {
      "width": "30%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode Start")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData6 = _vm.projectData) !== null && _vm$projectData6 !== void 0 && _vm$projectData6.periode_start ? _vm.moment((_vm$projectData7 = _vm.projectData) === null || _vm$projectData7 === void 0 ? void 0 : _vm$projectData7.periode_start).format('DD MMMM YYYY') : '-'))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode End")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData8 = _vm.projectData) !== null && _vm$projectData8 !== void 0 && _vm$projectData8.periode_start ? _vm.moment((_vm$projectData9 = _vm.projectData) === null || _vm$projectData9 === void 0 ? void 0 : _vm$projectData9.periode_start).format('DD MMMM YYYY') : '-'))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Contract Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData10 = _vm.projectData) === null || _vm$projectData10 === void 0 ? void 0 : (_vm$projectData10$con = _vm$projectData10.contract_type) === null || _vm$projectData10$con === void 0 ? void 0 : _vm$projectData10$con.name))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Cargo Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData$cargo = (_vm$projectData11 = _vm.projectData) === null || _vm$projectData11 === void 0 ? void 0 : (_vm$projectData11$car = _vm$projectData11.cargo_type) === null || _vm$projectData11$car === void 0 ? void 0 : _vm$projectData11$car.name) !== null && _vm$projectData$cargo !== void 0 ? _vm$projectData$cargo : '-'))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Term")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData$terms = (_vm$projectData12 = _vm.projectData) === null || _vm$projectData12 === void 0 ? void 0 : (_vm$projectData12$ter = _vm$projectData12.terms) === null || _vm$projectData12$ter === void 0 ? void 0 : _vm$projectData12$ter.name) !== null && _vm$projectData$terms !== void 0 ? _vm$projectData$terms : '-') + " "), _c('br'), _vm._v(" Remarks: " + _vm._s(_vm.projectData.terms_remarks))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Total Quantity")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.projectData.cargo_type_id ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s((_vm$totalQuantityAdd = _vm.totalQuantityAdd) === null || _vm$totalQuantityAdd === void 0 ? void 0 : _vm$totalQuantityAdd.toLocaleString()))]), _c('strong', [_vm._v(_vm._s(_vm.projectData.unit))])]) : _c('div', [_c('i', [_vm._v("Choose cargo type first.")])])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POL")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-2 w-100"
  }, _vm._l((_vm$projectData13 = _vm.projectData) === null || _vm$projectData13 === void 0 ? void 0 : _vm$projectData13.pol, function (obj, polIndex) {
    var _obj$value;
    return _c('span', {
      key: polIndex
    }, [_vm._v(_vm._s(polIndex + 1) + ". " + _vm._s((_obj$value = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value !== void 0 ? _obj$value : '-'))]);
  }), 0)]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POD")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-2 w-100"
  }, _vm._l((_vm$projectData14 = _vm.projectData) === null || _vm$projectData14 === void 0 ? void 0 : _vm$projectData14.pod, function (obj, podIndex) {
    var _obj$value2;
    return _c('span', {
      key: podIndex
    }, [_vm._v(_vm._s(podIndex + 1) + ". " + _vm._s((_obj$value2 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value2 !== void 0 ? _obj$value2 : '-'))]);
  }), 0)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Laycan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData15 = _vm.projectData) !== null && _vm$projectData15 !== void 0 && _vm$projectData15.laycan ? _vm.moment((_vm$projectData16 = _vm.projectData) === null || _vm$projectData16 === void 0 ? void 0 : _vm$projectData16.laycan).format('DD MMMM YYYY') : '-'))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Distance")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData17 = _vm.projectData) === null || _vm$projectData17 === void 0 ? void 0 : _vm$projectData17.distance))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Shipment Plan Number")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$projectData18 = _vm.projectData) === null || _vm$projectData18 === void 0 ? void 0 : _vm$projectData18.shipment_plan_number))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Freigh Rate (Est)")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency((_vm$projectData19 = _vm.projectData) === null || _vm$projectData19 === void 0 ? void 0 : _vm$projectData19.freight_rate_est)))]), _c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(((_vm$projectData20 = _vm.projectData) === null || _vm$projectData20 === void 0 ? void 0 : _vm$projectData20.ppn) == 'include' ? 'Include ' : 'Exclude ') + " PPN")])])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData21 = _vm.projectData) === null || _vm$projectData21 === void 0 ? void 0 : _vm$projectData21.remarks))])])])])])])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Select Operational Data")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Select Fleet"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "placeholder": "Select..."
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "date",
      "label": "Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "date",
      "label": "Find"
    }
  }, [_c('b-button', {
    staticClass: "px-4",
    attrs: {
      "type": "button",
      "variant": "primary"
    }
  }, [_vm._v(" FIND DATA ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-bordered table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "width": "5px",
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("STATUS")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("CARGO UNLOADING")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("CARGO LOADING")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("TA POL / READY")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("BERTHING")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMMENCED")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMPLETED")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("POD")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("TA POD / READY")])])]), _c('tbody', [[_c('tr', [_c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  })])]], 2)])])])], 1)], 1)])], 1)], 1), _c('b-col', {
    staticClass: "mb-4 mt-0",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideFormRealShipment
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Cancel  ")]), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Submit  ")]) : _vm._e()], 1)])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }