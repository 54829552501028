<template>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h5 class="card-title text-primary"><strong>PIC MOVEMENT / TRANSFER</strong></h5>
          </template>
          <template v-slot:headerAction>
            <b-button @click="openSlideForm('ADD')"  variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> &nbsp; CREATE MOVEMENT</b-button>
          </template>
  
          <!-- FILTER START -->
          <template>
            <b-row class="m-2">
              <b-col lg="2">
                <b-form-group label="All Transfer Type" label-for="movementType">
                  <b-form-select plain class="mb-2" placeholder="Select Options..." v-model="list.params.movementType" name="movementType">
                    <b-form-select-option value="">Select options</b-form-select-option>
                    <b-form-select-option value="new">New</b-form-select-option>
                    <b-form-select-option value="rotate">Rotate</b-form-select-option>
                    <b-form-select-option value="reduce">Reduce</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>  
              <b-col lg="2">
                <b-form-group label="Status" label-for="status">
                  <b-form-select plain class="mb-2" v-model="list.params.state">
                    <b-form-select-option value="">All</b-form-select-option>
                    <b-form-select-option value="active">Active</b-form-select-option>
                    <b-form-select-option value="request">Request</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Date" label-for="Join on Board">
                  <date-picker
                    v-model="dateRange"
                    type="date"
                    range
                    placeholder="Select date range"
                  ></date-picker>
                </b-form-group>
              </b-col>
              <b-col lg="2"></b-col>
              <b-col lg="2">
                <b-form-group label="Search PIC" label-for="stage_id" >
                  <b-form-input placeholder="Search..." v-model="list.params.search" />
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group style label="Filter" label-for="button_search">
                  <b-button variant="primary" class="mb-3 mr-1" @click="fetchListPICMovement(1)"><i class="fa fa-search"></i></b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
  
          <!-- LIST -->
          <template>
            <b-row class="m-2">
              <b-col md="12">
                <div class="d-flex w-100 overflow-auto ">
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="text-center">NO</th>
                        <th class="text-center" style="width:150px">NAME</th>
                        <th class="text-center">TRANSFER TYPE</th>
                        <th class="text-center">CREATED DATE</th>
                        <th class="text-center">EFFECTIVE DATE</th>
                        <th class="text-center" style="width: 300px">FLEETS</th>
                        <th class="text-center">STATUS</th>
                        <th class="text-center">ATTACHMENT</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in list?.data" :key="index">
                        <th class="text-center">{{ index + 1 }}</th>
                        <td class="text-center">{{ state?.sourcePic?.name ?? 'N/A' }}</td>
                        <td class="text-center">
                          <b-badge variant="primary" v-if="['new'].includes(state?.movementType)">New</b-badge>
                          <b-badge variant="warning" v-if="['rotate'].includes(state?.movementType)">Rotate</b-badge>
                          <b-badge variant="danger" v-if="['reduce'].includes(state?.movementType)">Reduce</b-badge>
                        </td>
                        <td class="text-center text-truncate ">{{ state?.createdAt ? moment(state.createdAt, "DD-MM-YYYY HH:mm").format("DD MMM YYYY HH:mm") : '-' }}</td>
                        <td class="text-center text-truncate ">{{ state?.effectiveDate ? moment(state.effectiveDate, "DD-MM-YYYY").format("DD MMM YYYY")  : '-' }}</td>
                        <td class="text-center text-truncate" v-if="['new'].includes(state?.movementType)">
                          <div class="d-flex flex-wrap">
                            <b-badge v-for="(fleet, fleetIndex) in state?.vehicles" :key="fleetIndex" class="border border-primary text-primary text-uppercase mr-1 mb-1" variant="none">{{ fleet?.name }}</b-badge>
                          </div>
                        </td>
                        <td class="text-center text-truncate" v-if="['rotate'].includes(state?.movementType)">
                          <div class="d-flex align-items-center justify-content-center">
                            <b-badge class="border border-dark text-dark text-uppercase" variant="none">TB. TAVIA 001</b-badge>
                            <span class="text-primary mx-2"><i class="fa fa-chevron-right" /><i class="fa fa-chevron-right" /></span>
                            <b-badge class="border border-primary text-primary text-uppercase" variant="none">TB. TAVIA 002</b-badge>
                          </div>
                          <div class="d-flex align-items-center justify-content-center">
                            <b-badge class="border border-dark text-dark text-uppercase" variant="none">TB. TAVIA 002</b-badge>
                            <span class="text-primary mx-2"><i class="fa fa-chevron-right" /><i class="fa fa-chevron-right" /></span>
                            <b-badge class="border border-primary text-primary text-uppercase" variant="none">TB. TAVIA 003</b-badge>
                          </div>
                          <div class="d-flex align-items-center justify-content-center">
                            <b-badge class="border border-dark text-dark text-uppercase" variant="none">TB. TAVIA 003</b-badge>
                            <span class="text-primary mx-2"><i class="fa fa-chevron-right" /><i class="fa fa-chevron-right" /></span>
                            <b-badge class="border border-primary text-primary text-uppercase" variant="none">TB. TAVIA 004</b-badge>
                          </div>
                        </td>
                        <td class="text-center text-truncate" v-if="['reduce'].includes(state?.movementType)">
                          <div class="d-flex flex-wrap">
                            <b-badge v-for="(fleet, fleetIndex) in state?.vehicles" :key="fleetIndex" class="border border-primary text-primary text-uppercase mr-1 mb-1"  variant="none">{{ fleet?.name }}</b-badge>
                          </div>
                        </td>
                        <td class="text-center text-capitalize">
                          <b-badge variant="success" v-if="['active'].includes(state?.state)">{{state?.state}}</b-badge>
                          <b-badge variant="danger" v-if="['decline'].includes(state?.state)">{{state?.state}}</b-badge>
                          <b-badge variant="primary" v-if="['approved'].includes(state?.state)">{{state?.state}}</b-badge>
                        </td>
                        <td class="text-center">{{ state?.attachment ? 'File' : '-' }}</td>
                        <td class="text-center">
                          <b-dropdown 
                            size="lg"
                            variant="link iq-bg-primary iq-border-radius-10"
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" variant="primary" @click="openSlideForm('DETAIL', state?.id)">
                              <i class="fa fa-eye mr-2"></i>Detail
                            </b-dropdown-item>
                            <b-dropdown-item href="#" variant="success" @click="openModalStatusApproval(state?.id, 'approve')" v-if="!['active'].includes(state?.state)">
                              <i class="fa fa-check mr-2"></i>Status Approve
                            </b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger" @click="openModalStatusApproval(state?.id, 'reject')" v-if="!['active'].includes(state?.state)">
                              <i class="fa fa-ban mr-2"></i>Cancel Transfer
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr v-if="list.data?.length <= 0">
                        <td colspan="9" class="text-center"><strong class="text-muted">No data exists.</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-3" v-if="list.data.length > 0">
                  <b-pagination
                    v-model="list.meta.currentPage"
                    :total-rows="list.meta.total"
                    :per-page="list.meta.perPage"
                    first-number
                    @change="fetchListPICMovement"
                    align="center"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
  
          <!-- SLIDE - FORM PIC MOVEMENT -->
          <b-sidebar
            v-model="slideAddPicMovement"
            id="slideAddPicMovement"
            :title="`${action == 'ADD' ? 'ADD' : action == 'EDIT' ? 'EDIT' : 'DETAIL'} PIC MOVEMENT`"
            width="70%"
            backdrop-variant="dark"
            bg-variant="white"
            right
            backdrop
            shadow>
            <template #default="{hide}">
              <SlideFormPicMovement
                :action="action"
                :refreshData="refreshData"
                @updateAction="updateAction" 
                :editId="editId" 
              /> 
            </template>
          </b-sidebar>
          <!-- SLIDE - FORM PIC MOVEMENT  END -->

          <!-- MODAL NOTICE SIGN OFF -->
          <b-modal
            id="status-approval"
            size="md"
            :title="`${action === 'reject' ? 'Cancel Transfer' : 'Status Approve'}`"
            cancel-title="Close"
            hide-footer
          >
            <b-row>
              <b-col lg="12">
                <b-form-group label="Remarks" label-for="description">
                  <b-form-textarea rows="4" placeholder="Text Type" name="description" v-model="formApproval.description" />
                </b-form-group>
                <div class="d-flex w-100 border my-2" />
                <div class="d-flex w-100 align-items-center justify-content-end ">
                  <b-button variant="danger" type="button" class="mr-2" @click="onStatusApproval('reject')" v-if="action === 'reject'">
                    <i class="fa fa-ban mr-2" /> Reject
                  </b-button>
                  <b-button variant="success" type="button" @click="onStatusApproval('approve')" v-if="action === 'approve'">
                    <i class="fa fa-check mr-2" /> Approve
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-modal>
          <!-- MODAL SIGN OFF  END -->
        </iq-card>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import _ from 'lodash'
  import moment from 'moment'
  import { fleetsActions, picMovementActions } from '@src/Utils/helper'
  import SlideFormPicMovement from './SlideFormPicMovement.vue'
  
  export default {
    name: 'PicMovement',
    data() {
      return{
        action:'',
        slideAddPicMovement: false,
        dateRange:[moment().startOf("year").toDate(), moment().endOf("month").toDate()],

        list:{
          loading:false,
          saving:false,
          params:{
            movementType:'', 
            search:'',
            state:''
          },
          data:[],
          meta:{}
        },

        editId:null,

        formApproval: {
          description:'',
        }
      }

    },
    created(){
      this.fetchListPICMovement = _.debounce(this.fetchListPICMovement, 500)
    },
    async mounted () {
      await this.fetchListPICMovement()
    },
    components:{
      SlideFormPicMovement
    },
  
    methods: {
      ...fleetsActions,
      ...picMovementActions,
      refreshData(){
        this.slideAddPicMovement = !this.slideAddPicMovement
        this.action = ''

        this.fetchListPICMovement()
      },
      updateAction(newAction){
        this.action = newAction
      },
      openSlideForm(value, id){
        this.slideAddPicMovement = !this.slideAddPicMovement
        this.action = value
        this.editId = id
      },
      openModalStatusApproval(id, actionApproval){
        this.editId = id
        this.action = actionApproval
        this.$nextTick(() => {
          this.$bvModal.show('status-approval')
        })
      },
       async fetchListPICMovement(page) {
        let params = {
          ...this.list.params,
          startDate: this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('DD-MM-YYYY') : null,
          endDate: this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('DD-MM-YYYY') : null,
          page: page || 1,
        }

        // Iterate over each property in params
        for (const prop in params) {
          if (params.hasOwnProperty(prop)) {
            // Check conditions for each property and delete if necessary
            if ((params[prop] === '') || (params[prop] === null)) {
              delete params[prop];
            }
          }
        }

        this.list.loading = true

        const {status, data} = await this.getListPicMovement(params)

        if (status === 'success') {
          this.list.meta.perPage = data.perPage
          this.list.meta.currentPage = data.currentPage
          this.list.meta.total = data.total
          this.list.meta.totalPage = data.totalPage

          if (data.data.length > 0) {
            this.list.data = data.data
          } else {
            this.list.data = []
          }
        }
        this.list.loading = false
      },

      // APPROVAL
      async onStatusApproval(action){
        let payload = {
          id:this.editId ?? 0,
          url:action,
          data: this.formApproval
        }

        this.$swal({
            title: `Are you sure want to ${action} it?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${action} it!`
        }).then(async (result) => {
            if (result.isConfirmed) {
              const res = await this.approvalPICMovement(payload)

              if([400, 500, 404, 409, 401, 402].includes(res.status)){
                this.$swal(`Oops.`, res?.data?.message ?? 'Something went wrong.', 'error')
              } else {
                await this.fetchListPICMovement()
                this.$swal(
                  'Success!',
                  `Success ${action} PIC Movement.`,
                  'success'
                )
                this.formApproval.description = ''
                this.$nextTick(() => {
                  this.$bvModal.hide('status-approval')
                })
              }
            }
        })
      },
    }
  }
  </script>