<template>
  <b-container fluid>
    <form  @submit="onSaveForms" action="#">
      <b-row v-if="edit_loading">
        <b-col md="12">
          <div class="text-center my-5">
            <b-spinner type="grow" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="12">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
              variant="danger"
              type="button"
              class="mr-4"
              @click="closeModalForm"
            >
                <i class="fa fa-remove"></i>
                <span>Cancel</span>
            </b-button>

            <b-button
              :disabled="saving"
              variant="primary"
              type="submit"
            >
              <span
                v-if="saving"
                class="spinner-border spinner-border-sm"
              />
              <div v-else>
                <i class="fa fa-save"></i>
                <span>Submit Data</span>
              </div>
            </b-button>
          </div>
          <hr>
        </b-col>
        <b-col lg="6" class="mb-4">
          <b-row>
            <b-col md="6">
              <b-form-group label="Code" label-for="code">
                <b-form-input
                  id="code"
                  type="text"
                  placeholder="Type text"
                  v-model="forms.code"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Name" label-for="name">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Type text"
                  v-model="forms.name"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Title" label-for="title">
                <b-form-input
                  id="title"
                  type="text"
                  placeholder="Type text"
                  v-model="forms.title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row> 
          <b-row>
            <b-col md="12">
              <b-form-group label="Desciption" label-for="description">
                <b-form-textarea
                  id="description"
                  type="text"
                  placeholder="Type text"
                  v-model="forms.description"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row> 
          <b-row>
            <b-col md="6">
              <b-form-group label="Status" label-for="status">
                <b-form-checkbox name="check-button" switch  v-model="forms.status">Active / Inactive
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Using Remarks" label-for="using_remarks">
                <b-form-checkbox name="check-button" switch v-model="forms.using_remarks">Yes / No
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <strong>Field</strong>
            </b-col>
            <b-col md="12">
              <div class="d-flex flex-column align-items-center" v-for="(state, index) in forms.field" :key="index">
                <div class="d-flex align-items-center w-100">
                  <b-form-group :label="'Label #' + (index + 1)" label-for="name" class="mr-2 w-50">
                    <b-form-input
                      id="name"
                      type="text"
                      placeholder="Type text"
                      v-model="state.label"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group :label="'Type Data #' + (index + 1)" label-for="name" class="mr-4 w-50">
                    <v-select
                      v-model="state.type"
                      label="text"
                      :options="listTypeData"
                      :reduce="(type) => type.value"
                      required
                    ></v-select>
                  </b-form-group>
                  <i class="fa fa-trash text-danger mt-2" style="cursor: pointer;" @click="() => {
                      forms.field.splice(index, 1)
                    }"></i>
                </div>
                <b-form-group class="align-self-end">
                  <b-form-checkbox name="check-button" switch v-model="state.required">is Required?
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>

            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end w-100">
                <b-button
                  variant="primary"
                  @click="addField"
                >
                  <span>Add a line</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <strong>Signature</strong>
            </b-col>
            <b-col md="12" v-for="(state, index) in forms.signature" :key="index">
              <div class="d-flex flex-column align-items-center" >
                <div class="d-flex align-items-center w-100">
                  <b-form-group :label="'Signature Label #' + (index + 1)" label-for="name" class="mr-2 w-50">
                    <b-form-input
                      id="name"
                      type="text"
                      placeholder="Type text"
                      v-model="state.label"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group :label="'Signature Type Data #' + (index + 1)" label-for="name" class="mr-4 w-50">
                    <v-select
                      v-model="state.type"
                      label="text"
                      :options="listTypeData"
                      :reduce="(type) => type.value"
                      required
                    ></v-select>
                  </b-form-group>
                  <i class="fa fa-trash text-danger mt-2" style="cursor: pointer;" @click="() => {
                    forms.signature.splice(index, 1)
                  }"></i>
                </div>
                <b-form-group class="align-self-end">
                  <b-form-checkbox name="check-button" switch v-model="state.required">is Required?
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>

            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end w-100">
                <b-button
                  variant="primary"
                  @click="addSignature"
                >
                  <span>Add a line</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <strong>Question Type</strong>
            </b-col>
            <b-col md="12">
              <div class="custom-control custom-control-inline custom-radio" v-for="(state, index) in listQuestionsType" :key="index">
                <input type="radio" name="question_type_id" v-model="forms.question_type_id" class="custom-control-input" :value="state.code" :id="state.code">
                <label class="custom-control-label" :for="state.code">{{ state.name }}</label>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row v-if="forms.question_type_id === 'multiple-lines-text-box'">
            <b-col md="12" v-for="(state, index) in forms.question_type_content" :key="index">
              <div class="d-flex align-items-center">
                <b-form-group :label="'Text Box #' + (index + 1)" label-for="name" class="mr-4 w-100">
                  <b-form-input
                    id="name"
                    type="text"
                    placeholder="Type text"
                    v-model="state.label"
                  ></b-form-input>
                </b-form-group>
                <i class="fa fa-trash text-danger mt-2" style="cursor: pointer;" @click="() => {
                    forms.question_type_content.splice(index, 1)
                  }"></i>
              </div>
            </b-col>

            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end w-100">
                <b-button
                  variant="primary"
                  @click="addQuestionType"
                >
                  <span>Add a line</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-else-if="forms.question_type_id === 'single-lines-text-box'">
            <b-col md="12">
              <b-form-group label="Label">
                <b-form-input
                  id="text"
                  type="text"
                  placeholder="Type Text"
                  v-model="forms.question_type_content.label"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Input must be an Email">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="forms.question_type_content.input_must_be_email"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Save as user email">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="forms.question_type_content.save_as_user_email"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Save as user nickname">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="forms.question_type_content.save_as_user_nickname"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Validate entry">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="forms.question_type_content.validate_entry"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Minumum Text Length" label-for="name">
                <b-form-input
                  id="name"
                  type="number"
                  placeholder="0"
                  v-model="forms.question_type_content.minimum_text_length"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Maximum Text Length" label-for="name">
                <b-form-input
                  id="name"
                  type="number"
                  placeholder="0"
                  v-model="forms.question_type_content.maximum_text_length"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Validation Error Message" label-for="name">
                <b-form-input
                  id="text"
                  type="text"
                  placeholder="Ext: The answer you entered is not valid"
                  v-model="forms.question_type_content.validation_error_message"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="forms.question_type_id === 'numerical-value'">
            <b-col md="12">
              <b-form-group label="Label">
                <b-form-input
                  id="text"
                  type="text"
                  placeholder="Type Text"
                  v-model="forms.question_type_content.label"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="status-job" label="Validate entry">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="forms.question_type_content.validate_entry"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Minumum Value" label-for="name">
                <b-form-input
                  id="name"
                  type="number"
                  placeholder="0"
                  v-model="forms.question_type_content.minimum_value"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Maximum Value" label-for="name">
                <b-form-input
                  id="name"
                  type="number"
                  placeholder="0"
                  v-model="forms.question_type_content.maximum_value"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Validation Error Message" label-for="name">
                <b-form-input
                  id="text"
                  type="text"
                  placeholder="Ext: The answer you entered is not valid"
                  v-model="forms.question_type_content.validation_error_message"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="forms.question_type_id === 'date'">
            <b-col md="12">
              <b-form-group label="Label">
                <b-form-input
                  id="text"
                  type="text"
                  placeholder="Type Text"
                  v-model="forms.question_type_content.label"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="status-job" label="Label">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="forms.question_type_content.validate_entry"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="status-job" label="Validate entry">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="forms.question_type_content.validate_entry"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="status-job" label="Minimum Date">
                <b-form-input
                  type="date"
                  v-model="forms.question_type_content.minimum_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="status-job" label="Maximum Date">
                <b-form-input
                  type="date"
                  v-model="forms.question_type_content.maximum_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Validation Error Message" label-for="name">
                <b-form-input
                  id="text"
                  type="text"
                  placeholder="Ext: The answer you entered is not valid"
                  v-model="forms.question_type_content.validation_error_message"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="forms.question_type_id === 'datetime'">
            <b-col md="12">
              <b-form-group label="Label">
                <b-form-input
                  id="text"
                  type="text"
                  placeholder="Type Text"
                  v-model="forms.question_type_content.label"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="status-job" label="Validate entry">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="forms.question_type_content.validate_entry"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="status-job" label="Minimum Date">
                <b-form-input
                  type="datetime-local"
                  v-model="forms.question_type_content.minimum_datetime"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label-for="status-job" label="Maximum Date">
                <b-form-input
                  type="datetime-local"
                  v-model="forms.question_type_content.maximum_datetime"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Validation Error Message" label-for="name">
                <b-form-input
                  id="text"
                  type="text"
                  placeholder="Ext: The answer you entered is not valid"
                  v-model="forms.question_type_content.validation_error_message"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="forms.question_type_id === 'multiple-choice-only-one-answer'">
            <b-col md="12" v-for="(state, index) in forms.question_type_content" :key="index">
              <div class="d-flex align-items-center">
                <b-form-group :label="'Radio Label #' + (index + 1)" label-for="name" class="mr-4 w-100">
                  <b-form-input
                    id="name"
                    type="text"
                    placeholder="Type text"
                    v-model="state.label"
                  ></b-form-input>
                </b-form-group>
                <i class="fa fa-trash text-danger mt-2" style="cursor: pointer;" @click="() => {
                    forms.question_type_content.splice(index, 1)
                  }"></i>
              </div>
            </b-col>

            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end w-100">
                <b-button
                  variant="primary"
                  @click="addQuestionType"
                >
                  <span>Add a line</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-else-if="forms.question_type_id === 'multiple-choice-multiple-answers-allowed'">
            <b-col md="12" v-for="(state, index) in forms.question_type_content" :key="index">
              <div class="d-flex align-items-center">
                <b-form-group :label="'Checkbox Label #' + (index + 1)" label-for="name" class="mr-4 w-100">
                  <b-form-input
                    id="name"
                    type="text"
                    placeholder="Type text"
                    v-model="state.label"
                  ></b-form-input>
                </b-form-group>
                <i class="fa fa-trash text-danger mt-2" style="cursor: pointer;" @click="() => {
                  forms.question_type_content.splice(index, 1)
                }"></i>
              </div>
            </b-col>

            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end w-100">
                <b-button
                  variant="primary"
                  @click="addQuestionType"
                >
                  <span>Add a line</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12">
          <div class="border my-2 bg-muted"></div>
        </b-col>
        <b-col md="12">
          <b-row>
            <b-col md="6">
              <b-form-group label="Form for Vehicle / Vehicle Type?" label-for="form_for_vehicle">
                <b-form-checkbox name="check-button" switch v-model="forms.form_for_vehicle"> Yes / No
                </b-form-checkbox>
              </b-form-group>
            </b-col> 
            <b-col md="6">
              <b-form-group label="Default for Shipment Planning" label-for="default_for_shipment_planning">
                <b-form-checkbox name="check-button" switch v-model="forms.default_for_shipment_planning"> Yes / No
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="forms.form_for_vehicle">
            <b-col md="6">
              <b-form-group label="* Vehicle Type Section" label-for="company_id">
                <b-form-select
                  required
                  plain
                  size="sm"
                  :options="listVehicleTypeMasterSection"
                  @input="onSelectedVehicleTypeSection"
                  v-model="vehicle_type_m_section_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">Select Vehicle Type Section</b-form-select-option>
                  </template>
                </b-form-select> 
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="table-responsive" v-if="listQuestionsTable.length > 0 && forms.form_for_vehicle">
                <table class="table table-striped" v-for="(parentQuestion, parentQuestionIndex) in listQuestionsTable" :key="parentQuestionIndex">
                  <thead>
                    <tr v-if="!parentQuestion.parent_id && parentQuestion?.inspection_checklist_question?.length <= 0">
                      <th colspan="6" class="align-middle text-center">{{ parentQuestion?.name }}</th>
                    </tr>
                    <tr v-else>
                      <th style="width:5%">No</th>
                      <th style="width:50%">{{parentQuestion?.name }}</th>
                      <th style="width:35%">Alias</th>
                      <th style="width:10%">Status</th>
                      <th style="width:5%" class="text-right">#</th>
                    </tr>
                  </thead>
                  <tbody v-if="parentQuestion?.inspection_checklist_question?.length > 0">
                    <tr v-for="(question, questionIndex) in parentQuestion?.inspection_checklist_question" :key="questionIndex">
                      <td>{{ questionIndex + 1 }}</td>
                      <td v-if="question.edit">
                        <input type="text" v-model="question.question" name="question" class="form-control">
                      </td>
                      <td v-else>{{ question.question }}</td>
                      <td v-if="question.edit"><input type="text" v-model="question.alias" name="alias" class="form-control"></td>
                      <td v-else>{{ question.alias }}</td>
                      <td :class="question.status ? 'text-success' : 'text-danger'">{{ question.status ? 'Active' : 'Inactive' }}</td>
                      <td class="text-right d-flex flex-column ">
                        <b-button variant="success" size="sm" class="mb-2" @click="onEditQuestion(parentQuestionIndex, questionIndex)"><i :class="question.edit ? 'fa fa-check' : 'fa fa-edit'" ></i></b-button>
                        <b-button variant="danger" size="sm" @click="onDeleteQuestion(parentQuestionIndex, questionIndex)">
                          <i class="fa fa-trash"></i>
                        </b-button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <b-button variant="primary" @click="onAddQuestion(parentQuestionIndex)">Add Question +</b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex" v-if="listQuestionsTable.length <= 0 && forms.form_for_vehicle">
                <strong class="text-muted">Choose Vehicle Type Section First.</strong>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'FormCollectionForm',
  props:{
    isEdit: {
      type: Boolean,
      default: false
    },
    editId: {
      type: Number,
      default: null
    }, 
    refreshData: {
      type: Function,
      default: true
    },
    closeModalForm: {
      type: Function,
      default: true
    },
  },
  data () {
    return {
      isOnEdit:false,
      listTypeData: [
        {text:'Numeric Type', value:'Numeric Type'},
        {text:'Text Type', value:'Text Type'},
        {text:'Textarea', value:'Textarea'},
        {text:'Date', value:'Date'},
        {text:'Datetime', value:'Datetime'},
        {text:'Boolean Type', value:'Boolean Type'},
        {text:'Select Option', value:'Select Option'},
      ],
      listCategory: [],
      listQuestionsType: [],
      listQuestionsTable: [],
      listVehicleTypeMasterSection: [],
      emptyData1: {label: ""},
      emptyData2: {label: "", type: ""},
      emptyData3: {label: "", type: ""},

      categoryList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      edit_loading: false,
      loading:false,
      saving:false,
      delete:false,

      vehicle_type_m_section_id:null,

      forms: {
        code: "",
        name: "",
        description: "",
        title: "",
        field:[
          {label: "", type: ""}
        ],
        question_type_id: null,
        question_type_content: [],
        using_remarks: false, // 1 = active, 0 = inactive
        signature: [
          {label: "", type: ""}
        ],
        status: true, // 1 = active, 0 = inactive
        default_for_shipment_planning: false, // 1 = active, 0 = inactive
        form_for_vehicle: true // 1 = active, 0 = inactive
      },
    }
  },
  watch:{
    'editId': {
      handler(value) {
        if(value){
          this.getCollectionFormsById()
        } else {
          this.resetForms()
        }
      },
      immediate: true,
    },
    'forms.question_type_id': {
      handler(value){
        if(value && !this.isOnEdit){
          this.listQuestions(value)
        }
      }
    }
  },
  async mounted () {
    await this.getQuestionType()
    await this.getVehicleTypeMasterSection()
    await this.getListInspectionChecklistCategory()
    // await this.getListCategory()

    // await this.getCollectionQuestion()
  },
  methods: {
    ...busdevActions,
    addField(){
      this.forms.field.push({label: "", type: "", required:false})
    },
    addSignature(){
      this.forms.signature.push({label: "", type: "", required:false})
    }, 
    addQuestionType(){
      this.forms.question_type_content.push({label: "", required:false})
    },
    listQuestions(value){
      this.isOnEdit = false
      if(value === 'multiple-lines-text-box'){
        this.forms.question_type_content = [
          {label:''}
        ]
      } else if(value === 'single-lines-text-box'){
        this.forms.question_type_content = {
          label:'',
          input_must_be_email:false,
          save_as_user_email:false,
          save_as_user_nickname:false,
          save_as_user_nickname:false,
          validate_entry:false,
          minimum_text_length:'',
          maximum_text_length:'',
          validation_error_message:'',
        }
      } else if(value === 'numerical-value'){
        this.forms.question_type_content = {
          label:'',
          validate_entry:true,
          minimum_value:'',
          maximum_value:'',
          validation_error_message:'',
        }
      } else if(value === 'date'){
        this.forms.question_type_content = {
          label:'',
          validate_entry:true,
          minimum_date:'',
          maximum_date:'',
          validation_error_message:'',
        }
      } else if(value === 'datetime'){
        this.forms.question_type_content = {
          label:'',
          validate_entry:true,
          minimum_datetime:'',
          maximum_datetime:'',
          validation_error_message:'',
        }
      } else if(value === 'multiple-choice-only-one-answer'){
        this.forms.question_type_content = [
          {label:''}
        ]
      } else if(value === 'multiple-choice-multiple-answers-allowed'){
        this.forms.question_type_content = [
          {label:''}
        ]
      }
    },
    onSelectedVehicleTypeSection(){
      let categoryQuestion = []
      this.listCategory.map(obj => {
        if(obj.vehicle_type_m_section_id === this.vehicle_type_m_section_id){
          categoryQuestion.push({
            ...obj,
            inspection_checklist_question: obj.inspection_checklist_question.length > 0 ? 
              obj.inspection_checklist_question.map(val => {
                return {
                  ...val,
                  edit:false
                }
              }) : []
          })
        }
      })

      this.listQuestionsTable = categoryQuestion
    },
    onAddQuestion(parentIndex){
      this.listQuestionsTable[parentIndex].inspection_checklist_question.push({
        alias:"",
        question:"",
        checklist_category_id:this.listQuestionsTable[parentIndex].id,
        status:1,
        edit:true
      })
    },
    onEditQuestion(parentIndex, childIndex){
      this.listQuestionsTable[parentIndex].inspection_checklist_question[childIndex].edit = 
        !this.listQuestionsTable[parentIndex].inspection_checklist_question[childIndex].edit
    },
    onDeleteQuestion(parentIndex, childIndex){
      this.listQuestionsTable[parentIndex].inspection_checklist_question.splice(childIndex, 1)
    },
    async getListInspectionChecklistCategory (page) {
      this.listCategory = []
      let params = {
        page: 1,
        perPage: 100000
      }

      const res = await this.fetchInspectionChecklistCategory(params)
  
      if (res.data.status == true && res.data.data?.data.length > 0) {
        this.listCategory = res.data.data?.data
      }
    },
    async getQuestionType () {
      let params = {
        page: 1,
        perPage:100000,
        status:1
      }

      const res = await this.fetchQuestionType(params)

      if (res.data.status == true && res?.data?.data?.data.length > 0) {
        this.listQuestionsType = res?.data?.data?.data
      } 
    },

    async getCollectionFormsById () {
      this.edit_loading = true
      this.listQuestionsTable = []
      const res = await this.fetchCollectionFormById({id: this.editId})

      if (res.data.status == true) {
        const data = res.data.data
        console.log(data)
        this.isOnEdit = true

        let question_code = this.listQuestionsType.find(obj => obj.id === data.question_type_id).code

        this.forms = {
          code: data.code,
          name: data.name,
          description: data.description,
          title: data.title,
          field:data.field.length > 0 ? JSON.parse(data.field) : [
            {label: "", type: ""}
          ],
          question_type_id:question_code,
          question_type_content: data.question_type_content.length > 0 ? JSON.parse(data.question_type_content) : [],
          using_remarks: data?.using_remarks ? true : false, // 1 = active, 0 = inactive
          signature: data.signature ? JSON.parse(data.signature) : [
            {label: "", type: ""}
          ],
          form_for_vehicle: data?.form_for_vehicle ? true : false, // 1 = active, 0 = inactive
          default_for_shipment_planning: data?.default_for_shipment_planning ? true : false, // 1 = active, 0 = inactive
          status: data?.status ? true : false // 1 = active, 0 = inactive
        }


        if(data.collection_of_forms_category.length > 0){
          this.vehicle_type_m_section_id = data.collection_of_forms_category[0].vehicle_type_m_section_id

          this.listQuestionsTable = data.collection_of_forms_category.map(val => {
            if(val.inspection_checklist_question.length > 0){
              val.inspection_checklist_question.map(obj => {
                obj.edit = false
                return obj
              })
            }
            return val
          })
        } 
        this.edit_loading = false
      } 
    },  

    async getVehicleTypeMasterSection () {
      let params = {
        page: 1,
        perPage:100000,
        status:1
      }

      const res = await this.fetchVehicleTypeMasterSection(params)

      if (res.data.status == true && res?.data?.data?.data.length > 0) {
        res?.data?.data?.data.map(val => {
          this.listVehicleTypeMasterSection.push({
            text:val.name,
            value:val.id
          })
        })
      } 
    },

    async onSaveForms (e) {
      e.preventDefault();

      if(this.listQuestionsTable.length <= 0 && this.forms.form_for_vehicle){
        this.$swal(
          `Oops!`,
          'Question of Vehicle Type Section Required. Please select 1.',
          'warning'
        )
        return
      }
      
      this.saving = true
      let payload = {
        ...this.forms,
        question_type_id: this.listQuestionsType.find(obj => obj.code == this.forms.question_type_id).id,
        default_for_shipment_planning: this.forms.default_for_shipment_planning ? 1 : 0,
        using_remarks: this.forms.using_remarks ? 1 : 0,
        status: this.forms.status ? 1 : 0,
        form_for_vehicle: this.forms.form_for_vehicle ? 1 : 0,
        listOfQuestion: this.forms.form_for_vehicle ? this.listQuestionsTable : []
      }

      let res = null
      if(this.isEdit && this.editId){
        res = await this.updateCollectionForm({data: payload, id: this.editId})
      } else if (!this.category_edit) {
        res = await this.createCollectionForm({data: payload})
      }

      if (res?.data?.status === true) {
        this.$swal(`${this.isEdit ? 'Edit' : "Add"} Forms Success!`, res.data.message, 'success')
        this.resetForms()
        this.saving = false

        this.refreshData()
      } else {
        this.$swal(
          `Oops!`,
          res?.data,
          'error'
        )
        this.saving = false
      }
      this.saving = false
    },

    resetForms () {
      this.forms = {
        "code": "",
        "name": "",
        "description": "",
        "title": "",
        "field":[
          {"label": "", "type": ""}
        ],
        "question_type_id": null,
        "question_type_content": [],
        "using_remarks": false, // 1 = active, 0 = inactive
        "signature": [
          {"label": "", "type": ""}
        ],
        "form_for_vehicle": true,// 1 = active, 0 = inactive
        "status": true// 1 = active, 0 = inactive
      }

      this.listQuestionsTable = []
      this.vehicle_type_m_section_id = null
    },

    editForms (data) {
      this.forms = {
        "code":data.code,
        "name": data.name,
        "description": data.description,
        "title": data.title,
        "field":data.field,
        "question_type_id": data.question_type_id,
        "question_type_content": data.question_type_content,
        "using_remarks": data.using_remarks ? true : false, // 1 = active, 0 = inactive
        "signature": data.signature,
        "form_for_vehicle": data.form_for_vehicle ? true : false, // 1 = active, 0 = inactive
        "status": data.status ? true : false // 1 = active, 0 = inactive
      }
    },
  }
}
</script>

<style scoped>
</style>
