<template>
  <b-container fluid>
    <form @submit.prevent="onSaveRealShipment">
      <b-row>
        <b-col md="12">
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit" v-if="['EDIT', 'ADD'].includes(action)"><i class="fa fa-save"></i> Submit&nbsp; </b-button>
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormRealShipment"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
        </b-col>
        <b-col md="12" class="p-4">
          <b-row>
            <b-col cols="12">
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary">Project</legend>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="dateIssued" label="Projects">
                      <span>{{ projectData?.project_sub?.project?.project_name }} - {{ projectData?.project_sub?.name }}</span>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Customer"
                      label-for="customer_id"
                    >
                      <span>{{ projectData?.customer?.customer_code }} - {{ projectData?.customer?.account_name }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>
            </b-col>

            <!-- DETAIL PROJECT CONTRACT -->
            <b-col lg="12">
              <b-row>
                <b-col :lg="'12'">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Detail Project Contract</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="15%">Company</th>
                          <td width="5%">:</td>
                          <td width="30%">-</td> 
                          <th width="15%"></th>
                          <td width="5%"></td>
                          <td width="30%"></td> 
                        </tr>
                        <tr>
                          <th width="15%">Fleets</th>
                          <td width="5%">:</td>
                          <td width="30%" colSpan="4">
                            <div class="d-flex flex-column">
                              <b-row class="mb-2">
                                <b-col lg="3" class="mb-2">
                                  <span>Fleet ownership</span> <br />
                                  <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ projectData?.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                                </b-col>
                                <b-col lg="3" v-if="projectData.fleet_ownership">
                                  <span>Fleet Type</span> <br />
                                  <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">Tug & Barge</b-badge>
                                </b-col>
                              </b-row>
                              <b-row v-if="projectData.fleet_type_id">
                                <b-col lg='12'>Fleets</b-col>
                                <b-col md="12" >
                                  <div class="d-flex flex-warp">
                                    <b-badge v-for="(objDetail, detailIndex) in projectData.fleets" :key="detailIndex" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">TB. TAVIA 00{{ detailIndex + 1 }}</b-badge>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Collection of Forms</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <div class="d-flex flex-wrap gap-2">
                              <b-badge 
                                v-for="(state, index) in projectData.project_forms"
                                :key="index"
                                class="border border-primary text-primary text-uppercase m-1" 
                                variant="none"
                              >
                                {{ state?.label }}
                              </b-badge>
                            </div>
                          </td>
                          <th width="15%"></th>
                          <td width="5%"></td>
                          <td width="30%"></td>
                        </tr>
                        <tr>
                          <th width="15%">Periode Start</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <span>{{ projectData?.periode_start ? moment(projectData?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                          </td>
                          <th width="15%">Periode End</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <span>{{ projectData?.periode_start ? moment(projectData?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Contract Type</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <span>{{ projectData?.contract_type?.name }}</span>
                          </td>
                          <th width="15%">Cargo Type</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <span>{{ projectData?.cargo_type?.name ?? '-' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Term</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <span>{{ projectData?.terms?.name ?? '-' }} <br /> Remarks: {{ projectData.terms_remarks }}</span>
                          </td> 
                          <th width="15%">Total Quantity</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <div class="d-flex align-items-center" v-if="projectData.cargo_type_id">
                              <span class="mr-2">{{ totalQuantityAdd?.toLocaleString() }}</span>
                              <strong >{{ projectData.unit }}</strong>
                            </div>
                            <div v-else>
                              <i>Choose cargo type first.</i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">POL</th>
                          <td width="5%">:</td>
                          <td width="30%">
                              <div class="d-flex flex-column mb-2 w-100">
                                <span v-for="(obj, polIndex) in projectData?.pol" :key="polIndex">{{polIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                              </div>
                            </td>
                          <th width="15%">POD</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <div class="d-flex flex-column mb-2 w-100">
                              <span v-for="(obj, podIndex) in projectData?.pod" :key="podIndex">{{podIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Laycan</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <span>{{ projectData?.laycan ? moment(projectData?.laycan).format('DD MMMM YYYY') : '-' }}</span>
                          </td>
                          <th width="15%">Distance</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <span>{{ projectData?.distance }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Shipment Plan Number</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <strong class="text-muted">{{ projectData?.shipment_plan_number }}</strong>
                          </td> 
                          <th width="15%">Freigh Rate (Est)</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <div class="d-flex flex-column">
                              <span>{{ formatCurrency(projectData?.freight_rate_est) }}</span>
                              <strong class="text-muted">{{ projectData?.ppn == 'include' ? 'Include ' : 'Exclude ' }} PPN</strong>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Remarks</th>
                          <td width="5%">:</td>
                          <td width="30%" colSpan="4">
                            <span>{{ projectData?.remarks }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </b-col>
              </b-row>
            </b-col>

            <!-- OPERATIONAL DATA -->
            <b-col cols="12">
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary">Select Operational Data</legend>
                <b-row>
                  <b-col md="3">
                    <b-form-group label-for="dateIssued" label="Select Fleet">
                      <v-select label="text" placeholder="Select..." />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label-for="date" label="Date">
                      <b-form-input type="date" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label-for="date" label="Find">
                      <b-button type="button" variant="primary" class="px-4"> FIND DATA
                      </b-button>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <div class="d-flex overflow-auto">
                      <table class="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th class="text-center" style="width:5px; background-color:#BDE5EB">NO</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">FLEET</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">STATUS</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">CARGO TYPE</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">CARGO UNLOADING</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">CARGO LOADING</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">TA POL / READY</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">BERTHING</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">COMMENCED</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">COMPLETED</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">POD</th>
                            <th class="text-center text-truncate" style="background-color:#BDE5EB">TA POD / READY</th>
                            <!-- <th class="text-center bg-warning border-dark " style="vertical-align:middle; width:5px; position:sticky; right:0; z-index:1">ACTION</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <template>
                            <tr >
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <td class="text-truncate"></td>
                              <!-- <td class="text-center bg-warning border-dark " style="width:5px; position:sticky; right:0; z-index:1">
                                <b-dropdown 
                                  size="lg"
                                  variant="link iq-bg-primary iq-border-radius-10"
                                  toggle-class="text-decoration-none"
                                  no-caret
                                >
                                  <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                    </span>
                                  </template>
                                  <b-dropdown-item href="#" variant="primary" >
                                    <i class="fa fa-eye mr-2"></i>Detail
                                  </b-dropdown-item>
                                </b-dropdown>
                              </td> -->
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </fieldset>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="12" class="mb-4 mt-0">
          <div class="d-flex align-items-center justify-content-center">
            <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormRealShipment"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
            <b-button variant="primary" class="float-right mr-1" size="sm" type="submit" v-if="['EDIT', 'ADD'].includes(action)"><i class="fa fa-save"></i> Submit&nbsp; </b-button>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name:'SlideFormRealShipment',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      }, 
      refreshData:{
        type:Function,
        default:() => {}
      }, 
      closeSlideFormRealShipment:{
        type:Function,
        default:() => {}
      },  
      projectData:{
        type:Object,
        default:() => {}
      }, 
    },
    components:{
    },
    data(){
      return{
        form:{

        }
      }
    },
    watch:{
      id: {
        deep: true,
        handler: function (value) {
          if (value) {
            // this.getDetailPayment(value)
          }
        },
      },
      action: {
        deep: true,
        handler: function (value) {
          if (value === "ADD") {
            this.resetForm()
          }
        },
      }
    },
    mounted(){},
    computed:{
      totalQuantityAdd() {
        return this.projectData?.detail_plan?.reduce((total, item) => total + Number(item?.plan_quantity), 0);
      },
    },
    methods:{
      ...busdevActions,
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },

      resetForm(){
        this.form = {

        }
      },

      async getDetailPayment(id){
        // const res = await this.fetchRealShipmentById({id})

        // if(res?.data?.status === true){
        //   let data = res.data.data
        //   this.form = {

        //   }
        // }
      },

      async onSaveRealShipment(){
        let payload ={
          ...this.form,
          // request_date: moment(this.projectData.request_date).format('YYYY-MM-DD'),
          // project_contract_id: this.projectData.id
        }

        // const res = await this.createRealShipment({data:payload})

        // if(res.data.status === true){
        //   this.refreshData()
        //   this.resetForm()
        //   this.closeSlideFormRealShipment()
        //   this.$swal(`Success!`, `Create Payment List Success.`, 'success')
        // }
      }
    }
  }
</script>
