<template>
  <form @submit.prevent="onSendMail"> 
    <b-row class="m-3">
      <b-col md="12" v-if="!loading">
        <b-row>
          <b-col lg="12" class="mb-4">
            <div :class="`d-flex align-items-center justify-content-end w-100`">
              <b-button
                type="button"
                variant="danger"
                class="mr-3"
                @click="closeSlideSendMail"
              >
                <div>
                  <i class="fa fa-ban"></i>
                  <span>Cancel</span>
                </div>
              </b-button>
              <b-button
                variant="primary"
                type="submit"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Send Mail</span>
                </div>
              </b-button>
            </div>
            <hr>
          </b-col>
          <b-col md="4">
            <fieldset class="form-group border p-3 bg-default">
              <legend class="w-auto px-2 h5 text-primary">Head Mail</legend>
              <b-form-group label="To" label-for="to">
                <b-form-input type="text" id="to" placeholder="Type Text" v-model="form.to"></b-form-input>
              </b-form-group>
              <b-form-group label="CC" label-for="cc">
                <b-form-input type="text" id="cc" placeholder="Type Text" v-model="form.cc"></b-form-input>
              </b-form-group>
              <b-form-group label="Title" label-for="title">
                <b-form-input type="text" id="title" placeholder="Type Text" v-model="form.title"></b-form-input>
              </b-form-group>
              <b-form-group label="Priority" label-for="priority">
                <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  v-model="form.priority"
                >
                  Yes / No
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Attachment">
                <v-multiselect 
                  :multiple="true"
                  v-model="form.attachment"
                  :options="optionsFile" 
                  placeholder="Select file..." 
                  track-by="file_name" 
                  label="file_name"
                >
                  <span slot="noResult">No data found.</span>
                </v-multiselect>
              </b-form-group>
              <div class="d-flex flex-column" v-if="form?.attachment?.length > 0">
                <b-button v-for="(file, fileIndex) in form?.attachment" :key="fileIndex" variant="link" class="m-0 p-0 mb-2 text-primary text-left" type="button">
                  <i class="fa fa-file text-primary" /> {{ file?.file_name }}
                </b-button> 
              </div>
            </fieldset>
          </b-col>
    
          <b-col md="8">
            <fieldset class="form-group border p-3 bg-default">
              <legend class="w-auto px-2 h5 text-primary">Body Mail</legend>
              <div >
                <wysiwyg :style="{ height: '500px' }" v-model="form.content" />
              </div>
            </fieldset>
          </b-col> 
        </b-row>
      </b-col>

      <b-col md="12" v-else>
        <div class="alert alert-info m-4">
          Loading...
        </div>
      </b-col>
    </b-row>
  </form>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'
  import { saveAs } from 'file-saver'

  export default {
    name:'SlideSendMail',
    props:{
      action:{
        type:String,
        default:''
      },  
      projectId:{
        type:Number,
        default:null
      }, 
      id:{
        type:Number,
        default:null
      }, 
      closeSlideSendMail:{
        type:Function,
        default:() => {}
      },  
    },
    components:{
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,

        saving: false,
        loading: false,

        optionsFile: [],

        form:{
          to:'',
          cc:'',
          title:'',
          priority:false, 
          content:'',
          attachment:[]
        },
        
        user_id: JSON.parse(localStorage.getItem('profile')).id,
        user_mail: JSON.parse(localStorage.getItem('profile')).email,
        user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
      }
    },
    watch:{
      projectId: {
        deep: true,
        immediate:true,
        handler: function (value) {
          if (value) {
            this.resetForm()
            this.getListFile(value)
          }
        },
      },
    },
    mounted(){
    },
    computed:{
    },
    methods:{
      ...busdevActions,
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
      resetForm(){
        this.form = {
          to:'',
          cc:'',
          title:'',
          priority:false, 
          content:'',
        }
      },
      async getListFile(id){
        this.loading = true
        let params = {
          project_contract_id: id
        }

        const res = await this.fetchListFile(params)

        if(res?.data?.status === true && res.data.data.length > 0){
          this.optionsFile = res.data.data.map(val => {
            // const fileName = val.file_name;
            // const parts = fileName.split("-");
            // const desiredFileName = parts[1].trim();

            // val.file_name = desiredFileName

            return val
          })
        } else {
          this.optionsFile = []
        }

        this.loading = false
      },
      async onSendMail(){
        this.saving = true

        let payload = {
          "payment_id":this.id,
          "sender_name": this.user_name,
          "sender_mail":this.user_mail,
          "to":this.form.to,
          "cc":this.form.cc,
          "name":`Dear, ${this.form.to}`,
          "subject":this.form.title,
          "priority":this.form.priority,
          "content":this.form.content,
          "urlAttachments":this.form.attachment,
          "intro":"Payment.",
          "outro":"Thank you for reading this email, as you know this email is automatically",
        }

        if(this.form.attachment.length > 0){
          payload.urlAttachments = this.form.attachment.map(obj => {
            obj.file_url = `${this.apiUrl}/${obj.file_url}`

            return obj
          })
        }

        const res = await this.sendMailPayment({data:payload})

        if(res.data.status === true){
          this.resetForm()
          this.closeSlideSendMail()
          this.$swal(`Success!`, `Send Mail Success.`, 'success')
        } else {
          this.$swal(`Oops!`, `Something went wrong.`, 'error')
        }

        this.saving = false
      }
    }
  } 
</script>

<!-- <style scoped>
/* Ensure file input is hidden */
#file-upload {
  display: none;
}
</style> -->
