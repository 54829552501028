<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-6 p-0" :active="true" href="#hasil_rakor" title="SHIPMENT PLANNING" />
                <!-- <tab-nav-items class="col-md-4 p-0" :active="false" href="#proses_penawaran" title="PROSES PENAWARAN" /> -->
                <tab-nav-items class="col-md-6 p-0" :active="false" href="#realisasi" title="RUNNING SHIPMENT" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <tab-content-item :active="true" id="hasil_rakor" >
              <TabShipmentPlanning />
            </tab-content-item>

            <tab-content-item :active="false" id="proses_penawaran">
              <!-- <TabProsesPenawaran /> -->
            </tab-content-item>

            <tab-content-item :active="false" id="realisasi">
              <TabRealisasi />
            </tab-content-item>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import _ from 'lodash'
import TabShipmentPlanning from './TabShipmentPlanning.vue'
import TabProsesPenawaran from './TabProsesPenawaran.vue'
import TabRealisasi from './TabRealisasi.vue'

export default {
  name: 'BusdevDashboard',
  data () {
    return {}
  },
  mounted () {},
  components:{
    TabShipmentPlanning,
    TabProsesPenawaran,
    TabRealisasi,
  },
  methods: {},
}
</script>