var render = function render(){
  var _vm$task2, _vm$task6, _vm$task6$project_sta, _vm$task8, _vm$task9, _vm$task10, _vm$task11, _vm$task12, _vm$task13, _vm$task15, _vm$task15$project_lo, _vm$task15$project_lo2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "bg-white shadow rounded px-3 py-3 border border-white"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('span', [_c('strong', {
    staticClass: "text-dark"
  }, [_vm._v(_vm._s(_vm.task.project))])]), _c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link iq-bg-primary iq-border-radius-10",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "dropdown-toggle p-0",
          attrs: {
            "id": "dropdownMenuButton5",
            "data-toggle": "dropdown"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o m-0 text-primary"
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        var _vm$task;
        return _vm.openSlideFormAddPlan('PREVIEW', (_vm$task = _vm.task) === null || _vm$task === void 0 ? void 0 : _vm$task.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-eye mr-2"
  }), _vm._v("Detail ")]), (_vm$task2 = _vm.task) !== null && _vm$task2 !== void 0 && _vm$task2.is_pfa ? _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        var _vm$task3;
        return _vm.openPfaSlide((_vm$task3 = _vm.task) === null || _vm$task3 === void 0 ? void 0 : _vm$task3.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-info-circle mr-2"
  }), _vm._v("PFA ")]) : _vm._e(), _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        var _vm$task4, _vm$task5;
        return _vm.openProjectFormSlide((_vm$task4 = _vm.task) === null || _vm$task4 === void 0 ? void 0 : _vm$task4.id, (_vm$task5 = _vm.task) === null || _vm$task5 === void 0 ? void 0 : _vm$task5.project);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-info-circle mr-2"
  }), _vm._v("Collection Forms ")]), (_vm$task6 = _vm.task) !== null && _vm$task6 !== void 0 && (_vm$task6$project_sta = _vm$task6.project_stage) !== null && _vm$task6$project_sta !== void 0 && _vm$task6$project_sta.spal_process ? _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        var _vm$task7;
        return _vm.openSlideSpalProcess('ADD', (_vm$task7 = _vm.task) === null || _vm$task7 === void 0 ? void 0 : _vm$task7.id);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-info-circle mr-2"
  }), _vm._v("Spal Process ")]) : _vm._e(), _vm.approvalPermission((_vm$task8 = _vm.task) === null || _vm$task8 === void 0 ? void 0 : _vm$task8.user_approval) === true || ['System Checker'].includes(_vm.userProfileName) && Number((_vm$task9 = _vm.task) === null || _vm$task9 === void 0 ? void 0 : _vm$task9.count_approval) !== Number((_vm$task10 = _vm.task) === null || _vm$task10 === void 0 ? void 0 : _vm$task10.many_approval) ? _c('b-dropdown-divider') : _vm._e(), _vm.approvalPermission((_vm$task11 = _vm.task) === null || _vm$task11 === void 0 ? void 0 : _vm$task11.user_approval) === true || ['System Checker'].includes(_vm.userProfileName) && Number((_vm$task12 = _vm.task) === null || _vm$task12 === void 0 ? void 0 : _vm$task12.count_approval) !== Number((_vm$task13 = _vm.task) === null || _vm$task13 === void 0 ? void 0 : _vm$task13.many_approval) ? _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.project-approval",
      modifiers: {
        "project-approval": true
      }
    }],
    attrs: {
      "href": "#",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        var _vm$task14;
        _vm.projectId = (_vm$task14 = _vm.task) === null || _vm$task14 === void 0 ? void 0 : _vm$task14.id;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-check mr-2"
  }), _vm._v("Approval ")]) : _vm._e(), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onDeleteProjectContract(_vm.task);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash mr-2"
  }), _vm._v("Delete ")])], 1)], 1), _c('span', [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.task.project_type === 'existing' ? 'Existing' : "New Project"))])]), _c('span', [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.task.shipment) + " SHIPMENT")])]), _c('span', [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.task.volume) + " VOLUME")])])]), _c('div', {
    staticClass: "mt-4 align-items-center"
  }, [_vm._m(0), _vm._v(" "), _c('br'), _c('span', {
    staticClass: "text-sm text-dark"
  }, [_c('strong', [_vm._v(_vm._s(_vm.moment(_vm.task.periode_start).format("DD-MM-YYYY")))]), _vm._v(" s/d "), _c('strong', [_vm._v(_vm._s(_vm.moment(_vm.task.periode_end).format("DD-MM-YYYY")))])])]), _c('small', {
    staticClass: "text-muted text-capitalize text-end"
  }, [_vm._v("Created by: "), _c('strong', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s((_vm$task15 = _vm.task) === null || _vm$task15 === void 0 ? void 0 : (_vm$task15$project_lo = _vm$task15.project_log) === null || _vm$task15$project_lo === void 0 ? void 0 : (_vm$task15$project_lo2 = _vm$task15$project_lo[0]) === null || _vm$task15$project_lo2 === void 0 ? void 0 : _vm$task15$project_lo2.name))])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('strong', [_vm._v("Periode:")])]);

}]

export { render, staticRenderFns }