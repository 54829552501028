var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATION MANAGEMENT")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          attrs: {
            "id": "collapse-filter",
            "visible": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category:",
            "label-for": "kind-fleet"
          }
        }, [_vm._l(_vm.vehicleTypes, function (item, index) {
          return [_c('b-form-checkbox', {
            key: index,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(" " + _vm._s(item.name) + " ")])];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "search_vehicle",
            "label": "Search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "search_vehicle",
            "placeholder": "search by name"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Company"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.CompanyName,
            "id": "gender"
          },
          model: {
            value: _vm.params.companyId,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "companyId", $$v);
            },
            expression: "params.companyId"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Category Ownership"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.categoryOwnership,
            "id": "gender"
          },
          model: {
            value: _vm.params.ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "ownership", $$v);
            },
            expression: "params.ownership"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.fetchFleetActivities
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.loading ? _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center my-5 w-100"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3767018210)
  }) : [_vm.fleetActivities.length < 1 ? _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "text-center"
        }, [_vm._v("No fleet activities found.")])];
      },
      proxy: true
    }], null, false, 2002124283)
  }) : [_c('b-row', _vm._l(_vm.fleetActivities, function (f, i) {
    var _f$crew_data, _f$pic;
    return _c('b-col', {
      key: "f-".concat(i),
      attrs: {
        "sm": "4"
      }
    }, [_c('b-card', {
      staticClass: "iq-mb-3"
    }, [_c('b-card-text', [_c('div', {
      staticClass: "top d-flex justify-content-between"
    }, [f.miData ? _c('div', {
      staticClass: "mb-5",
      staticStyle: {
        "z-index": "9"
      }
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.handleOpenMI(f);
        }
      }
    }, [_c('h5', {
      staticClass: "mt-1 ml-1"
    }, [_vm._v("TOTAL CASE:")]), _c('p', [_c('b-button', {
      staticClass: "m-1",
      attrs: {
        "variant": "outline-danger"
      }
    }, [_vm._v(" Claim "), _c('b-badge', {
      staticClass: "ml-2",
      attrs: {
        "variant": "danger"
      }
    }, [_vm._v(_vm._s(f.miData.totalClaim))])], 1), _c('b-button', {
      staticClass: "m-1",
      attrs: {
        "variant": "outline-primary"
      }
    }, [_vm._v(" Non Claim "), _c('b-badge', {
      staticClass: "ml-2",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(_vm._s(f.miData.totalNonClaim))])], 1)], 1)])]) : _vm._e(), _c('div', {
      staticClass: "mb-5",
      staticStyle: {
        "z-index": "9"
      }
    }, [_c('b-dropdown', {
      attrs: {
        "size": "sm",
        "text": "Small",
        "variant": "light",
        "right": ""
      }
    }, [_c('template', {
      slot: "button-content"
    }, [_c('i', {
      staticClass: "fa fa-ellipsis-h"
    })]), _vm.permissionEditFleet ? _c('b-dropdown-item', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.fetchFleetDetail(f.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-edit"
    }), _vm._v(" Edit")]) : _vm._e(), _vm.permissionDeleteFleet ? _c('b-dropdown-item', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteFleet(f);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    }), _vm._v(" Delete")]) : _vm._e()], 2)], 1), _c('div', {
      staticClass: "boat",
      class: {
        'boat-no-animation': [7, 6].includes(f.vehicleType.id)
      }
    }, [f.vehicleType && f.vehicleType.icon && f.vehicleType.icon.url ? _c('img', {
      attrs: {
        "src": f.vehicleType.icon.url,
        "alt": ""
      }
    }) : _c('img', {
      attrs: {
        "src": require('../../assets/images/Barge.png'),
        "alt": ""
      }
    })]), ![7, 6].includes(f.vehicleType.id) ? _c('div', {
      staticClass: "waves"
    }) : _vm._e()]), _c('div', {
      staticClass: "bottom",
      style: {
        background: [7, 6].includes(f.vehicleType.id) ? '#d4e8ff !important' : '#33ccff'
      }
    }, [![7, 6].includes(f.vehicleType.id) ? _c('div', {
      staticClass: "float"
    }) : _vm._e()])]), _c('b-card-text', {
      staticClass: "mb-3"
    }, [_c('router-link', {
      attrs: {
        "to": {
          path: 'fleet-information',
          query: {
            vehicleId: f.id,
            menuObject: _vm.hashObject(f.modules[2])
          }
        }
      }
    }, [_c('h4', {
      staticClass: "text-center text-danger"
    }, [_c('b', [_vm._v(_vm._s(f.name))])])])], 1), _c('b-card-text', [_c('b-row', {
      staticClass: "text-center"
    }, [_vm._l(f.modules, function (m, im) {
      return [m.hasAccess && (_vm.validateTechnicalPermission(m.url) || _vm.validateOperationalPermission(m.url) || _vm.validateReportPermission(m.url)) ? _c('b-col', {
        key: "m-".concat(im),
        staticClass: "mb-3",
        attrs: {
          "cols": "4",
          "md": "3"
        }
      }, [_c('router-link', {
        attrs: {
          "to": {
            path: m.url,
            query: {
              vehicleId: f.id,
              menuObject: _vm.hashObject(m)
            }
          }
        }
      }, [m.icon && m.icon.url ? _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: m.name,
          expression: "m.name",
          modifiers: {
            "top": true
          }
        }],
        attrs: {
          "src": m.icon.url,
          "alt": "Responsive image"
        }
      }) : _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: 'Operational',
          expression: "'Operational'",
          modifiers: {
            "top": true
          }
        }],
        attrs: {
          "src": require("../../assets/images/flaticon/operational.png"),
          "alt": "Responsive image"
        }
      }), _c('small', {
        staticStyle: {
          "font-size": "8pt !important"
        }
      }, [_vm._v(_vm._s(m.name))])])], 1) : _vm._e(), m.hasAccess && false ? _c('b-col', {
        key: "m-b-".concat(im),
        attrs: {
          "md": "12"
        }
      }, [_c('router-link', {
        attrs: {
          "to": "/ops/docking"
        }
      }, [_c('b-button', {
        staticClass: "mb-3",
        attrs: {
          "block": "",
          "variant": "success"
        }
      }, [_c('i', {
        staticClass: "fa fa-ship"
      }), _vm._v("Docking Intermediate Survey")])], 1)], 1) : _vm._e()];
    })], 2), _vm.permissionMaintenanceSetup ? _c('b-row', [[_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-card', {
      staticClass: "mb-1",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-header', {
      staticClass: "p-0 bg-transparent",
      attrs: {
        "header-tag": "header",
        "role": "tab"
      }
    }, [_c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.clone_component",
        modifiers: {
          "clone_component": true
        }
      }],
      staticClass: "justify-content-between d-flex align-items-center border-0",
      attrs: {
        "variant": "success",
        "block": ""
      }
    }, [_c('span', [_vm._v("Maintenance Setup Component")]), _c('i', {
      staticClass: "fa fa-clone"
    })]), _c('b-modal', {
      attrs: {
        "id": "clone_component",
        "size": "large",
        "title": "Duplikasi Komponen Kapal",
        "header-bg-variant": "light",
        "header-text-variant": "dark",
        "footer-bg-variant": "light",
        "footer-text-variant": "dark"
      }
    }, [_c('b-alert', {
      attrs: {
        "show": true,
        "variant": "primary"
      }
    }, [_c('div', {
      staticClass: "iq-alert-icon"
    }, [_c('i', {
      staticClass: "ri-alert-line"
    })]), _c('div', {
      staticClass: "iq-alert-text"
    }, [_c('b', [_vm._v("Perhatikan")]), _vm._v(" Pastikan kapal yang akan diduplikasi komponen nya sudah sesuai datanya")])]), _c('b-row', [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Dari Komponen Kapal*",
        "label-for": "fleet"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      }
    }, [_c('b-form-select-option', [_vm._v("Select Fleet")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 1")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 2")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 1")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 2")])], 1)], 1)], 1), _c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Ke Komponen Kapal*",
        "label-for": "fleet"
      }
    }, [_c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      }
    }, [_c('b-form-select-option', [_vm._v("Select Fleet")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 1")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 2")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 1")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 2")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]], 2) : _vm._e(), _vm.permissionMaintenanceInitiation ? _c('b-row', [[_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-card', {
      staticClass: "mb-1",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-header', {
      staticClass: "p-0 bg-transparent",
      attrs: {
        "header-tag": "header",
        "role": "tab"
      }
    }, [_c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.clone_component",
        modifiers: {
          "clone_component": true
        }
      }],
      staticClass: "justify-content-between d-flex align-items-center border-0",
      attrs: {
        "variant": "warning",
        "block": ""
      }
    }, [_c('span', [_vm._v("Maintenance Initiation")]), _c('i', {
      staticClass: "fa fa-clone"
    })])], 1)], 1)], 1)]], 2) : _vm._e(), _vm.permissionSpecialSurvey ? _c('b-row', [[_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-card', {
      staticClass: "mb-1",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-header', {
      staticClass: "p-0 bg-transparent",
      attrs: {
        "header-tag": "header",
        "role": "tab"
      }
    }, [f !== null && f !== void 0 && f.unscheduledDocking.includes('INTERMEDIATE') ? _c('b-button', {
      class: "".concat("justify-content-between d-flex align-items-center border-0"),
      attrs: {
        "block": "",
        "variant": 'danger'
      },
      on: {
        "click": function click($event) {
          _vm.$router.push("/docking/dashboard?vehicleId=".concat(f === null || f === void 0 ? void 0 : f.id, "&typeSchedule=", 'intermerdiate', "&menuObject=").concat(_vm.hashObject(f === null || f === void 0 ? void 0 : f.modules[6])));
        }
      }
    }, [_c('span', [_vm._v("Set intermediate Survey")]), _c('i', {
      staticClass: "fa fa-ship"
    })]) : _vm._e(), f !== null && f !== void 0 && f.unscheduledDocking.includes('SPECIAL') ? _c('b-button', {
      class: "".concat("justify-content-between d-flex align-items-center border-0"),
      attrs: {
        "block": "",
        "variant": 'info'
      },
      on: {
        "click": function click($event) {
          _vm.$router.push("/docking/dashboard?vehicleId=".concat(f === null || f === void 0 ? void 0 : f.id, "&typeSchedule=", 'special_value', "&menuObject=").concat(_vm.hashObject(f === null || f === void 0 ? void 0 : f.modules[6])));
        }
      }
    }, [_c('span', [_vm._v("Set Special Survey")]), _c('i', {
      staticClass: "fa fa-ship"
    })]) : _vm._e()], 1)], 1)], 1)]], 2) : _vm._e(), _c('b-row', [[_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-card', {
      staticClass: "mb-1",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-header', {
      staticClass: "p-0 bg-transparent",
      attrs: {
        "header-tag": "header",
        "role": "tab"
      }
    }, [_c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: "accordion-" + i,
        expression: "`accordion-` + i"
      }],
      class: "".concat("justify-content-between d-flex align-items-center border-0"),
      attrs: {
        "block": "",
        "variant": "primary"
      }
    }, [_c('span', [_vm._v("List Crew (" + _vm._s(f === null || f === void 0 ? void 0 : f.total_crew) + ")")]), _c('i', {
      staticClass: "ri-group-fill"
    })])], 1), _c('b-collapse', {
      attrs: {
        "id": "accordion-" + i,
        "visible": false,
        "accordion": "my-accordion",
        "role": "tabpanel"
      }
    }, [_c('b-card-body', {
      staticClass: "p-0 py-2"
    }, [(f === null || f === void 0 ? void 0 : (_f$crew_data = f.crew_data) === null || _f$crew_data === void 0 ? void 0 : _f$crew_data.length) > 0 ? _c('ul', {
      staticClass: "doctors-lists m-0 p-0"
    }, _vm._l(f.crew_data, function (crew) {
      var _crew$personal_data$f, _crew$personal_data, _crew$position$name, _crew$position;
      return _c('li', {
        key: 'crew-' + crew.id,
        staticClass: "d-flex mb-4 align-items-center"
      }, [_c('div', {
        staticClass: "user-img img-fluid"
      }, [_c('img', {
        staticClass: "rounded-circle avatar-40",
        attrs: {
          "src": crew === null || crew === void 0 ? void 0 : crew.photo,
          "alt": "story-img"
        }
      })]), _c('div', {
        staticClass: "media-support-info ml-3"
      }, [_c('h6', [_vm._v(_vm._s((_crew$personal_data$f = crew === null || crew === void 0 ? void 0 : (_crew$personal_data = crew.personal_data) === null || _crew$personal_data === void 0 ? void 0 : _crew$personal_data.full_name) !== null && _crew$personal_data$f !== void 0 ? _crew$personal_data$f : '-'))]), _c('p', {
        staticClass: "mb-0 font-size-12"
      }, [_vm._v(_vm._s((_crew$position$name = crew === null || crew === void 0 ? void 0 : (_crew$position = crew.position) === null || _crew$position === void 0 ? void 0 : _crew$position.name) !== null && _crew$position$name !== void 0 ? _crew$position$name : '-'))]), _c('small', {
        staticClass: "mb-0 font-size-12"
      }, [_vm._v(" Join On Board: "), _c('strong', [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.join_on_board ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.join_on_board).format('DD MMMM YYYY') : '-'))])]), _c('br'), _c('small', {
        staticClass: "mb-0 font-size-12"
      }, [_vm._v(" Sign Off: "), _c('strong', [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.sign_off ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_off).format('DD MMMM YYYY') : '-'))])])]), _c('div', {
        staticClass: "iq-card-header-toolbar d-flex align-items-center"
      }, [_c('b-dropdown', {
        attrs: {
          "size": "lg",
          "variant": "link",
          "toggle-class": "p-0 text-decoration-none",
          "no-caret": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function fn() {
            return [_c('span', {
              staticClass: "dropdown-toggle p-0",
              attrs: {
                "id": "dropdownMenuButton6",
                "data-toggle": "dropdown"
              }
            }, [_c('i', {
              staticClass: "ri-more-2-line"
            })])];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        attrs: {
          "href": "#"
        },
        on: {
          "click": function click($event) {
            return _vm.$router.push({
              path: "/crewing/profile",
              query: {
                user: 'crew',
                id: crew.id
              }
            });
          }
        }
      }, [_c('i', {
        staticClass: "ri-eye-line mr-2"
      }), _vm._v("View Detail")])], 1)], 1)]);
    }), 0) : _c('div', {
      staticClass: "d-flex align-items-center justify-content-center"
    }, [_c('h6', {
      staticClass: "text-muted"
    }, [_c('strong', [_vm._v("This Fleet doesnt have Crew")])])])])], 1)], 1)], 1)]], 2), _c('b-row', [[_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-card', {
      staticClass: "mb-1",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-header', {
      staticClass: "p-0 bg-transparent",
      attrs: {
        "header-tag": "header",
        "role": "tab"
      }
    }, [_c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: "pic-accordion-" + i,
        expression: "`pic-accordion-` + i"
      }],
      class: "".concat("justify-content-between d-flex align-items-center border-0"),
      attrs: {
        "block": "",
        "variant": "primary"
      }
    }, [_c('span', [_vm._v("List PIC (" + _vm._s(f === null || f === void 0 ? void 0 : f.pic.length) + ")")]), _c('i', {
      staticClass: "ri-group-fill"
    })])], 1), _c('b-collapse', {
      attrs: {
        "id": "pic-accordion-" + i,
        "visible": false,
        "accordion": "pic-accordion",
        "role": "tabpanel"
      }
    }, [_c('b-card-body', {
      staticClass: "p-0 py-2"
    }, [(f === null || f === void 0 ? void 0 : (_f$pic = f.pic) === null || _f$pic === void 0 ? void 0 : _f$pic.length) > 0 ? _c('ul', {
      staticClass: "doctors-lists m-0 p-0"
    }, _vm._l(f.pic, function (pic) {
      var _pic$name;
      return _c('li', {
        key: 'pic-' + pic.id,
        staticClass: "my-2 align-items-center"
      }, [_c('div', {
        staticClass: "d-flex w-100 align-items-center justify-content-between"
      }, [_c('div', {
        staticClass: "d-flex flex-column"
      }, [_c('span', [_c('strong', [_vm._v(_vm._s((_pic$name = pic === null || pic === void 0 ? void 0 : pic.name) !== null && _pic$name !== void 0 ? _pic$name : '-'))])])]), _c('i', {
        staticClass: "fa fa-user text-primary mr-2",
        staticStyle: {
          "font-size": "20px"
        }
      })]), _c('div', {
        staticClass: "w-100 d-flex my-2 border"
      })]);
    }), 0) : _c('div', {
      staticClass: "d-flex align-items-center justify-content-center"
    }, [_c('h6', {
      staticClass: "text-muted"
    }, [_c('strong', [_vm._v("This Fleet doesnt have PIC")])])])])], 1)], 1)], 1)]], 2)], 1)], 1)], 1);
  }), 1), _c('b-row', [!_vm.isHideLoadMore ? _c('b-col', {
    attrs: {
      "md": "auto mx-auto my-3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "dark px-4 py-3"
    },
    on: {
      "click": function click($event) {
        return _vm.fetchFleetActivitiesLoadMore();
      }
    }
  }, [_vm.isFetchingLoadMore ? _c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _vm._e(), _vm._v(" load more")], 1)], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "edit-fleet",
      "size": "xl",
      "no-close-on-backdrop": true,
      "header-bg-variant": _vm.modal.headerBgVariant,
      "header-text-variant": _vm.modal.headerTextVariant,
      "body-bg-variant": _vm.modal.bodyBgVariant,
      "body-text-variant": _vm.modal.bodyTextVariant,
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Edit Your Fleet")]), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": close
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        }), _vm._v(" Close ")])];
      }
    }])
  }, [_c('AddFleet', {
    attrs: {
      "formData": _vm.fleetDetail
    },
    on: {
      "save": function save($event) {
        return _vm.fetchFleetActivities();
      },
      "childAction": function childAction($event) {
        return _vm.$bvModal.hide('edit-fleet');
      }
    }
  })], 1), _vm.fleetDetailMiDataModal ? _c('b-sidebar', {
    attrs: {
      "id": "sidebar-mi",
      "title": "".concat(_vm.fleetDetailMiDataModal.name, " - TOTAL CASE DETAIL"),
      "width": "1350px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr')]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": true,
            "href": "#accident",
            "title": "Accident / Incident"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#recommendation",
            "title": "Recommendation"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#evaluation",
            "title": "Evaluation"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#erm",
            "title": "Enterprise Risk Management"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 3080177949)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-3"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "accident"
    }
  }, [_c('SummaryManagementDetailAccidentList', {
    attrs: {
      "data": _vm.fleetDetailMiDataModal.forms
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "recommendation"
    }
  }, [_c('SummaryManagementRecommendations', {
    attrs: {
      "lists": _vm.fleetDetailMiDataModal.recommendations
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "evaluation"
    }
  }, [_c('SummaryManagementEvaluations', {
    attrs: {
      "lists": _vm.fleetDetailMiDataModal.evaluations
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "erm"
    }
  }, [_c('SummaryManagementERM', {
    attrs: {
      "lists": _vm.fleetDetailMiDataModal.erm
    }
  })], 1)], 1)])], 1)], 1) : _vm._e()]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }