<template>
  <b-container fluid>
    <form @submit.prevent="onSavePaymentList">
      <b-row>
        <b-col md="12">
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit" v-if="['EDIT', 'ADD'].includes(action)"><i class="fa fa-save"></i> Create Payment List&nbsp; </b-button>
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormPayment"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
        </b-col>
        <b-col md="12" class="p-4">
          <b-row>
            <b-col md="6">
              <b-form-group label="Request Name">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.request_name"  />
                <strong v-if="['PREVIEW'].includes(action)">{{form.request_name}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="projectData?.project_contract_plan?.length > 0">
              <b-form-group label="For Trip">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.for_trip" />
                <strong v-if="['PREVIEW'].includes(action)">{{form.for_trip}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Request Date">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" type="date" v-model="form.request_date" />
                <strong v-if="['PREVIEW'].includes(action)">{{moment(form.request_date).format('DD MMMM YYYY')}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Real Pricing">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.real_pricing" />
                <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.real_pricing) }}</b-form-text>
                <strong v-if="['PREVIEW'].includes(action)">{{formatCurrency(form.real_pricing)}}</strong>
              </b-form-group>
            </b-col> 
            <b-col md="6">
              <b-form-group label="Balance">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.balance" @change="onChangeBalance" />
                <strong v-if="['PREVIEW'].includes(action)">{{form.balance}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Invoice Price">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.invoice_price" @change="() => {onChangeInvoicePrice(); countTaxTotal(); countInvoiceTotal()}" />
                <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.invoice_price) }}</b-form-text>
                <strong v-if="['PREVIEW'].includes(action)">{{ formatCurrency(form.invoice_price) }}</strong>
              </b-form-group>
            </b-col>  
            <b-col md="6">
              <b-form-group label="Use Tax">
                <b-form-checkbox 
                  v-if="['EDIT', 'ADD'].includes(action)"
                  id="use_tax" 
                  v-model="form.use_tax" 
                  label="Yes / No" 
                  name="check-button"
                  switch
                  inline
                />
                <strong v-if="['PREVIEW'].includes(action)">{{ form.use_tax ? 'Yes' : 'No' }}</strong>
              </b-form-group>
            </b-col>

            <template v-if="form.use_tax">
              <b-col md="6">
                <b-form-group label="Tax Name">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.name_tax" />
                  <strong v-if="['PREVIEW'].includes(action)">{{ form.name_tax }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Tax Percentage (%)">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0.00" type="number" v-model="form.tax_percentage" step="0.001" @change="() => {countTaxTotal(); countInvoiceTotal()}" />
                    <strong v-if="['PREVIEW'].includes(action)">{{ Number(form.tax_percentage).toLocaleString() }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Tax Total">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.tax_total" />
                  <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.tax_total) }}</b-form-text>
                  <strong v-if="['PREVIEW'].includes(action)">{{ formatCurrency(form.tax_total) }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Invoice Total">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.invoice_total" />
                  <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.invoice_total) }}</b-form-text>
                  <strong v-if="['PREVIEW'].includes(action)">{{ formatCurrency(form.tax_total) }}</strong>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name:'TabPaymentList',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      }, 
      refreshData:{
        type:Function,
        default:true
      }, 
      closeSlideFormPayment:{
        type:Function,
        default:true
      },  
      projectData:{
        type:Object,
        default:null
      }, 
    },
    components:{
    },
    data(){
      return{
        form:{
          request_name:'',
          for_trip:'',
          request_date:null,
          real_pricing:null,
          balance:null,
          invoice_price:null,
          use_tax:false,
          name_tax:'',
          tax_percentage:null,
          tax_total:null,
          invoice_total:null,
        }
      }
    },
    watch:{
      id: {
        deep: true,
        handler: function (value) {
          if (value) {
            this.getDetailPayment(value)
          }
        },
      },
      action: {
        deep: true,
        handler: function (value) {
          if (value === "ADD") {
            this.resetForm()
          }
        },
      }
    },
    mounted(){},
    methods:{
      ...busdevActions,
      countInvoiceTotal(){
        if(this.form.invoice_price && this.form.tax_total){
          this.form.invoice_total = Number(this.form.invoice_price) + Number(this.form.tax_total)
        }
      },
      countTaxTotal(){
        if(this.form.invoice_price && this.form.tax_percentage){
          this.form.tax_total = Number(this.form.invoice_price) * Number(this.form.tax_percentage)
        }
      },
      onChangeBalance(){
        if(this.form.balance && this.form.real_pricing){
          this.form.invoice_price = Number(this.form.balance) * Number(this.form.real_pricing) / 100
        }
      },
      onChangeInvoicePrice(){
        if(this.form.invoice_price && this.form.real_pricing){
          this.form.balance = Number(this.form.invoice_price) / Number(this.form.real_pricing) * 100
        }
      },

      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },

      resetForm(){
        this.form = {
          request_name:'',
          for_trip:'',
          request_date:null,
          real_pricing:null,
          balance:null,
          invoice_price:null,
          use_tax:false,
          name_tax:'',
          tax_percentage:null,
          tax_total:null,
          invoice_total:null,
        }
      },

      async getDetailPayment(id){
        const res = await this.fetchPaymentListById({id})

        if(res?.data?.status === true){
          let data = res.data.data
          this.form = {
            request_name: data.request_name,
            for_trip: data.for_trip,
            request_date: data.request_date ? moment(data.request_date).format('YYYY-MM-DD') : null,
            real_pricing: data.real_pricing,
            balance: data.balance,
            invoice_price: data.invoice_price,
            use_tax: data.use_tax,
            name_tax: data.name_tax,
            tax_percentage: data.tax_percentage,
            tax_total: data.tax_total,
            invoice_total: data.invoice_total,
          }
        }
      },

      async onSavePaymentList(){
        let payload ={
          ...this.form,
          request_date: moment(this.form.request_date).format('YYYY-MM-DD'),
          project_contract_id: this.projectData.id
        }

        const res = await this.createPaymentList({data:payload})

        if(res.data.status === true){
          this.refreshData()
          this.resetForm()
          this.closeSlideFormPayment()
          this.$swal(`Success!`, `Create Payment List Success.`, 'success')
        }
      }
    }
  }
</script>
