var render = function render(){
  var _vm$detailProjectCont4, _vm$detailProjectCont5, _vm$detailProjectCont6, _vm$detailProjectCont7, _vm$detailProjectCont8, _vm$detailProjectCont9, _vm$detailProjectCont10, _vm$detailProjectCont11, _vm$detailProjectCont12, _vm$detailProjectCont13, _vm$detailProjectCont14, _vm$detailProjectCont15, _vm$detailProjectCont16, _vm$detailProjectCont17, _vm$detailProjectCont18, _vm$detailProjectCont19, _vm$detailProjectCont20, _vm$projectDetail, _vm$projectDetail$pro, _vm$projectDetail2, _vm$projectDetail3, _vm$projectDetail3$pr, _vm$projectDetail$sta, _vm$projectDetail4, _vm$detailProjectCont21, _vm$customerDetail$ac, _vm$customerDetail, _vm$customerDetail$ad, _vm$customerDetail2, _ref, _vm$customerDetail$ph, _vm$customerDetail3, _vm$customerDetail4, _vm$customerDetail$em, _vm$customerDetail5, _vm$customerDetail$co, _vm$customerDetail6, _vm$customerDetail$pr, _vm$customerDetail7, _vm$customerDetail$ci, _vm$customerDetail8, _vm$customerDetail$di, _vm$customerDetail9, _vm$customerDetail$vi, _vm$customerDetail10, _vm$customerDetail$na, _vm$customerDetail11, _ref2, _vm$customerDetail$mo, _vm$customerDetail12, _vm$customerDetail13, _vm$form, _vm$form2, _vm$form3, _vm$form4, _vm$form$contract_typ, _vm$detailProjectCont22, _vm$detailProjectCont23, _vm$detailProjectCont24, _vm$detailProjectCont25, _vm$detailProjectCont26, _vm$detailProjectCont27, _vm$detailProjectCont28, _vm$detailProjectCont29, _vm$totalQuantityAdd, _vm$form5, _vm$form6, _vm$form7, _vm$form8, _vm$detailProjectCont30, _vm$form9, _vm$detailProjectCont31, _vm$detailProjectCont32;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.detail_project_loading ? _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSavePlan.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    staticClass: "m-3"
  }, [['EDIT', 'PREVIEW'].includes(_vm.action) ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100 justify-content-end"
  }, _vm._l(_vm.listProjectStage, function (state, index) {
    var _vm$detailProjectCont, _vm$detailProjectCont3, _vm$listProjectStage;
    return _c('div', {
      key: index
    }, [_c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "variant": ((_vm$detailProjectCont = _vm.detailProjectContract) === null || _vm$detailProjectCont === void 0 ? void 0 : _vm$detailProjectCont.detail_project_stage_id) === state.id ? 'primary' : 'none'
      },
      on: {
        "click": function click($event) {
          var _vm$detailProjectCont2;
          return _vm.onChangeStatusStage((_vm$detailProjectCont2 = _vm.detailProjectContract) === null || _vm$detailProjectCont2 === void 0 ? void 0 : _vm$detailProjectCont2.id, state.id);
        }
      }
    }, [_c('span', {
      class: ((_vm$detailProjectCont3 = _vm.detailProjectContract) === null || _vm$detailProjectCont3 === void 0 ? void 0 : _vm$detailProjectCont3.detail_project_stage_id) === state.id ? 'text-white' : ''
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.title))])]), ((_vm$listProjectStage = _vm.listProjectStage) === null || _vm$listProjectStage === void 0 ? void 0 : _vm$listProjectStage.length) - 1 !== index ? _c('strong', [_vm._v(" >> ")]) : _vm._e()], 1);
  }), 0)]) : _vm._e(), ['EDIT', 'PREVIEW'].includes(_vm.action) ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list"
  }, [_c('tab-nav', {
    staticClass: "nav-fill mb-3",
    attrs: {
      "pills": true
    }
  }, [_c('tab-nav-items', {
    staticClass: "col-md-2 p-0",
    attrs: {
      "active": true,
      "href": "#general",
      "title": "General"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'GENERAL';
      }
    }
  }), _c('tab-nav-items', {
    staticClass: "col-md-2 p-0",
    attrs: {
      "active": false,
      "href": "#form-list",
      "title": "Form List"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'FORM LIST';
      }
    }
  }), _c('tab-nav-items', {
    staticClass: "col-md-2 p-0",
    attrs: {
      "active": false,
      "href": "#pfa",
      "title": "PFA"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'PFA';
      }
    }
  }), (_vm$detailProjectCont4 = _vm.detailProjectContract) !== null && _vm$detailProjectCont4 !== void 0 && (_vm$detailProjectCont5 = _vm$detailProjectCont4.project_stage) !== null && _vm$detailProjectCont5 !== void 0 && _vm$detailProjectCont5.spal_process ? _c('tab-nav-items', {
    staticClass: "col-md-2 p-0",
    attrs: {
      "active": false,
      "href": "#spal-process",
      "title": "Spal Process"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'SPAL PROCESS';
      }
    }
  }) : _vm._e(), (_vm$detailProjectCont6 = _vm.detailProjectContract) !== null && _vm$detailProjectCont6 !== void 0 && (_vm$detailProjectCont7 = _vm$detailProjectCont6.project_stage) !== null && _vm$detailProjectCont7 !== void 0 && _vm$detailProjectCont7.is_running ? _c('tab-nav-items', {
    staticClass: "col-md-2 p-0",
    attrs: {
      "active": false,
      "href": "#trip-document",
      "title": "Trip Document"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'TRIP DOCUMENT';
      }
    }
  }) : _vm._e(), (_vm$detailProjectCont8 = _vm.detailProjectContract) !== null && _vm$detailProjectCont8 !== void 0 && (_vm$detailProjectCont9 = _vm$detailProjectCont8.project_stage) !== null && _vm$detailProjectCont9 !== void 0 && _vm$detailProjectCont9.is_running || (_vm$detailProjectCont10 = _vm.detailProjectContract) !== null && _vm$detailProjectCont10 !== void 0 && (_vm$detailProjectCont11 = _vm$detailProjectCont10.project_stage) !== null && _vm$detailProjectCont11 !== void 0 && _vm$detailProjectCont11.is_end_stage ? _c('tab-nav-items', {
    staticClass: "col-md-2 p-0",
    attrs: {
      "active": false,
      "href": "#payment-list",
      "title": "Payment List"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'PAYMENT LIST';
      }
    }
  }) : _vm._e(), _c('tab-nav-items', {
    staticClass: "col-md-2 p-0",
    attrs: {
      "active": false,
      "href": "#real-shipment",
      "title": "Real Shipment"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'REAL SHIPMENT';
      }
    }
  })], 1)], 1)]), _c('hr')], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "general"
    }
  }, [['EDIT', 'PREVIEW'].includes(_vm.action) ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    class: "d-flex align-items-center justify-content-end w-100"
  }, [['EDIT'].includes(_vm.action) ? _c('b-button', {
    staticClass: "mt-2 mr-3",
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])]) : _vm._e(), ['EDIT'].includes(_vm.action) ? _c('b-button', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "type": "button",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.changeAction('PREVIEW');
      }
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('b-button', {
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "type": "button",
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.changeAction('EDIT');
      }
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-pen"
  }), _c('span', [_vm._v("Edit Data")])])]) : _vm._e()], 1)]) : _vm._e(), ['ADD'].includes(_vm.action) ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project")]), ['ADD'].includes(_vm.action) ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": true,
      "variant": "primary"
    }
  }, [_vm._l(_vm.company, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      attrs: {
        "inline": "",
        "name": item.name,
        "value": item.value
      },
      model: {
        value: _vm.project_type,
        callback: function callback($$v) {
          _vm.project_type = $$v;
        },
        expression: "project_type"
      }
    }, [_vm._v(_vm._s(item.label))])];
  })], 2)], 1)], 1) : _vm._e(), _c('b-row', [_vm.project_type === 'existing' ? _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_vm.project_type === 'existing' ? _c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Projects"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('v-select', {
    attrs: {
      "label": "text",
      "name": "project_sub_id",
      "options": _vm.projectOptions,
      "reduce": function reduce(projectOptions) {
        return projectOptions.value;
      },
      "placeholder": "Select Project",
      "required": ""
    },
    on: {
      "input": _vm.onSelectedProject
    },
    model: {
      value: _vm.form.project_sub_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "project_sub_id", $$v);
      },
      expression: "form.project_sub_id"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont12 = _vm.detailProjectContract) === null || _vm$detailProjectCont12 === void 0 ? void 0 : (_vm$detailProjectCont13 = _vm$detailProjectCont12.project_sub) === null || _vm$detailProjectCont13 === void 0 ? void 0 : (_vm$detailProjectCont14 = _vm$detailProjectCont13.project) === null || _vm$detailProjectCont14 === void 0 ? void 0 : _vm$detailProjectCont14.project_name) + " - " + _vm._s((_vm$detailProjectCont15 = _vm.detailProjectContract) === null || _vm$detailProjectCont15 === void 0 ? void 0 : (_vm$detailProjectCont16 = _vm$detailProjectCont15.project_sub) === null || _vm$detailProjectCont16 === void 0 ? void 0 : _vm$detailProjectCont16.name))])], 1) : _vm._e()], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": _vm.project_type === 'new_project' ? '12' : '6'
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Customer",
      "label-for": "customer_id"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('v-select', {
    attrs: {
      "label": "text",
      "name": "customer_id",
      "options": _vm.optionsListCustomer,
      "reduce": function reduce(optionsListCustomer) {
        return optionsListCustomer.value;
      },
      "placeholder": "Select Customer",
      "required": ""
    },
    on: {
      "input": _vm.onSelectedCustomer
    },
    model: {
      value: _vm.form.customer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customer_id", $$v);
      },
      expression: "form.customer_id"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont17 = _vm.detailProjectContract) === null || _vm$detailProjectCont17 === void 0 ? void 0 : (_vm$detailProjectCont18 = _vm$detailProjectCont17.customer) === null || _vm$detailProjectCont18 === void 0 ? void 0 : _vm$detailProjectCont18.customer_code) + " - " + _vm._s((_vm$detailProjectCont19 = _vm.detailProjectContract) === null || _vm$detailProjectCont19 === void 0 ? void 0 : (_vm$detailProjectCont20 = _vm$detailProjectCont19.customer) === null || _vm$detailProjectCont20 === void 0 ? void 0 : _vm$detailProjectCont20.account_name))])], 1)], 1)], 1), _vm.project_type === 'new_project' ? _c('b-button', {
    attrs: {
      "variant": "success",
      "size": "sm"
    },
    on: {
      "click": _vm.openCustomerSlide
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v("   ADD NEW CUSTOMER")]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_vm.project_type === 'existing' && _vm.form.customer_id && _vm.form.project_sub_id ? _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project Info")]), _c('div', {
    staticClass: "d-flex w-100 overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("PROJECT NAME")]), _c('th', [_vm._v("PROJECT SUB NAME")]), _c('th', [_vm._v("LOCATION")]), _c('th', [_vm._v("STATUS")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s((_vm$projectDetail = _vm.projectDetail) === null || _vm$projectDetail === void 0 ? void 0 : (_vm$projectDetail$pro = _vm$projectDetail.project) === null || _vm$projectDetail$pro === void 0 ? void 0 : _vm$projectDetail$pro.project_name))]), _c('td', [_vm._v(_vm._s((_vm$projectDetail2 = _vm.projectDetail) === null || _vm$projectDetail2 === void 0 ? void 0 : _vm$projectDetail2.name))]), _c('td', [_vm._v(_vm._s((_vm$projectDetail3 = _vm.projectDetail) === null || _vm$projectDetail3 === void 0 ? void 0 : (_vm$projectDetail3$pr = _vm$projectDetail3.project) === null || _vm$projectDetail3$pr === void 0 ? void 0 : _vm$projectDetail3$pr.location))]), _c('td', [_vm._v(_vm._s((_vm$projectDetail$sta = (_vm$projectDetail4 = _vm.projectDetail) === null || _vm$projectDetail4 === void 0 ? void 0 : _vm$projectDetail4.status) !== null && _vm$projectDetail$sta !== void 0 ? _vm$projectDetail$sta : '-'))])])])])])])]) : _vm._e(), ['EDIT', 'PREVIEW'].includes(_vm.action) ? _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Log History Activity")]), _c('div', {
    staticClass: "overflow-auto d-flex w-100",
    staticStyle: {
      "max-height": "544px"
    }
  }, [_c('b-row', _vm._l((_vm$detailProjectCont21 = _vm.detailProjectContract) === null || _vm$detailProjectCont21 === void 0 ? void 0 : _vm$detailProjectCont21.project_log, function (state, index) {
    return _c('b-col', {
      key: index,
      staticClass: "mb-4",
      attrs: {
        "lg": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('strong', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name) + " -")]), _c('span', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.moment(state.date_log).format('DD MMMM YYYY')))])]), _c('span', [_vm._v(_vm._s(state.log_description))]), _c('br'), _c('strong', [_vm._v("----")])]);
  }), 1)], 1)])]) : _vm._e(), _vm.project_type === 'new_project' && _vm.form.customer_id && ['ADD'].includes(_vm.action) ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project Form")]), _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitFormProject.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Project Name",
      "label-for": "project_name"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Search or add new project",
      "label": "projectName",
      "track-by": "id",
      "close-on-select": true,
      "clear-on-select": true,
      "options": _vm.projects,
      "required": ""
    },
    on: {
      "search-change": _vm.searchProjects
    },
    model: {
      value: _vm.formProject.selected_project,
      callback: function callback($$v) {
        _vm.$set(_vm.formProject, "selected_project", $$v);
      },
      expression: "formProject.selected_project"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    on: {
      "click": _vm.doAddProject
    },
    slot: "noResult"
  }, [_vm._v(" Not Found. Click to add "), _c('strong', [_vm._v(_vm._s(_vm.formProject.projectName))])])])], 1), _c('b-form-group', {
    attrs: {
      "label": "Project Location",
      "label-for": "project_location"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "port_name",
      "type": "text",
      "placeholder": "Project Location",
      "maxlength": "30"
    },
    model: {
      value: _vm.formProject.location,
      callback: function callback($$v) {
        _vm.$set(_vm.formProject, "location", $$v);
      },
      expression: "formProject.location"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Sub Project",
      "label-for": "sub_project_name"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "id": "port_name",
      "type": "text",
      "placeholder": "Sub Project Name",
      "maxlength": "30"
    },
    model: {
      value: _vm.formProject.subName,
      callback: function callback($$v) {
        _vm.$set(_vm.formProject, "subName", $$v);
      },
      expression: "formProject.subName"
    }
  })], 1)], 1)], 1)]) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_vm.form.customer_id && _vm.form.project_sub_id && _vm.project_type === 'existing' || _vm.project_type === 'new_project' && _vm.form.customer_id ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Company Info")]), _c('div', {
    staticClass: "d-flex w-100 overflow-auto"
  }, [_c('table', {
    staticClass: "table mb-0 table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Nama Perusahaan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$ac = (_vm$customerDetail = _vm.customerDetail) === null || _vm$customerDetail === void 0 ? void 0 : _vm$customerDetail.account_name) !== null && _vm$customerDetail$ac !== void 0 ? _vm$customerDetail$ac : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Alamat")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$ad = (_vm$customerDetail2 = _vm.customerDetail) === null || _vm$customerDetail2 === void 0 ? void 0 : _vm$customerDetail2.address) !== null && _vm$customerDetail$ad !== void 0 ? _vm$customerDetail$ad : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("No. Telepon")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_ref = (_vm$customerDetail$ph = (_vm$customerDetail3 = _vm.customerDetail) === null || _vm$customerDetail3 === void 0 ? void 0 : _vm$customerDetail3.phone1) !== null && _vm$customerDetail$ph !== void 0 ? _vm$customerDetail$ph : (_vm$customerDetail4 = _vm.customerDetail) === null || _vm$customerDetail4 === void 0 ? void 0 : _vm$customerDetail4.phone2) !== null && _ref !== void 0 ? _ref : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Email")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$em = (_vm$customerDetail5 = _vm.customerDetail) === null || _vm$customerDetail5 === void 0 ? void 0 : _vm$customerDetail5.email) !== null && _vm$customerDetail$em !== void 0 ? _vm$customerDetail$em : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Negara")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$co = (_vm$customerDetail6 = _vm.customerDetail) === null || _vm$customerDetail6 === void 0 ? void 0 : _vm$customerDetail6.country_id) !== null && _vm$customerDetail$co !== void 0 ? _vm$customerDetail$co : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Provinsi")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$pr = (_vm$customerDetail7 = _vm.customerDetail) === null || _vm$customerDetail7 === void 0 ? void 0 : _vm$customerDetail7.province) !== null && _vm$customerDetail$pr !== void 0 ? _vm$customerDetail$pr : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Kota")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$ci = (_vm$customerDetail8 = _vm.customerDetail) === null || _vm$customerDetail8 === void 0 ? void 0 : _vm$customerDetail8.city) !== null && _vm$customerDetail$ci !== void 0 ? _vm$customerDetail$ci : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Kecamatan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$di = (_vm$customerDetail9 = _vm.customerDetail) === null || _vm$customerDetail9 === void 0 ? void 0 : _vm$customerDetail9.district) !== null && _vm$customerDetail$di !== void 0 ? _vm$customerDetail$di : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Kelurahan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$vi = (_vm$customerDetail10 = _vm.customerDetail) === null || _vm$customerDetail10 === void 0 ? void 0 : _vm$customerDetail10.village) !== null && _vm$customerDetail$vi !== void 0 ? _vm$customerDetail$vi : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', {
    attrs: {
      "width": "30%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("PIC")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$na = (_vm$customerDetail11 = _vm.customerDetail) === null || _vm$customerDetail11 === void 0 ? void 0 : _vm$customerDetail11.name) !== null && _vm$customerDetail$na !== void 0 ? _vm$customerDetail$na : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("No. Handphone")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_ref2 = (_vm$customerDetail$mo = (_vm$customerDetail12 = _vm.customerDetail) === null || _vm$customerDetail12 === void 0 ? void 0 : _vm$customerDetail12.mobile1) !== null && _vm$customerDetail$mo !== void 0 ? _vm$customerDetail$mo : (_vm$customerDetail13 = _vm.customerDetail) === null || _vm$customerDetail13 === void 0 ? void 0 : _vm$customerDetail13.mobile2) !== null && _ref2 !== void 0 ? _ref2 : '-'))])])])])])]) : _vm._e()])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_vm.form.customer_id && _vm.form.project_sub_id && _vm.project_type === 'existing' || _vm.project_type === 'new_project' && _vm.form.customer_id ? _c('b-col', {
    attrs: {
      "lg": '12'
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Detail Project Contract Form")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('v-select', {
    staticClass: "w-100 mb-2",
    attrs: {
      "name": "company_id",
      "options": _vm.optionsCompanies,
      "label": "text",
      "track-by": "value",
      "reduce": function reduce(optionsCompanies) {
        return optionsCompanies.value;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.company_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "company_id", $$v);
      },
      expression: "form.company_id"
    }
  }) : _vm._e()], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', {
    attrs: {
      "width": "30%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Fleets")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet ownership")]), _c('b-form-select', {
    staticClass: "form-control",
    attrs: {
      "plain": "",
      "required": "",
      "name": "fleet_ownership"
    },
    on: {
      "change": function change($event) {
        _vm.onDataIntoPlan('fleet_ownership', _vm.form.fleet_ownership);
        _vm.onChangeOwnership(_vm.form.fleet_ownership);
      }
    },
    model: {
      value: _vm.form.fleet_ownership,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fleet_ownership", $$v);
      },
      expression: "form.fleet_ownership"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select ownership...")]), _c('option', {
    attrs: {
      "value": "own-ship"
    }
  }, [_vm._v("OWN SHIP")]), _c('option', {
    attrs: {
      "value": "chartered"
    }
  }, [_vm._v("CHARTERED")])])], 1), _vm.form.fleet_ownership ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet Type")]), _c('b-form-select', {
    staticClass: "form-control",
    attrs: {
      "plain": "",
      "options": _vm.optionsFleetTypes,
      "required": "",
      "name": "fleet_type_id"
    },
    on: {
      "change": function change($event) {
        _vm.onChangeFleetType(false, null, null);
        _vm.onDataIntoPlan('fleet_type_id', _vm.form.fleet_type_id);
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select fleet type...")])];
      },
      proxy: true
    }], null, false, 2802890174),
    model: {
      value: _vm.form.fleet_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fleet_type_id", $$v);
      },
      expression: "form.fleet_type_id"
    }
  })], 1) : _vm._e()], 1), _vm.form.fleet_type_id ? _c('b-row', _vm._l(_vm.form.fleets, function (obj, objIndex) {
    return _c('b-col', {
      key: objIndex,
      attrs: {
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "d-flex mt-2 flex-column w-100"
    }, [_c('strong', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(_vm.showVehicleName(obj.vehicle_id)))]), _c('b-button', {
      attrs: {
        "variant": "primary",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onOpenSlideDetailVehicle(obj.vehicle_id, objIndex);
        }
      }
    }, [_vm._v(_vm._s(obj.vehicle_id ? 'Change ' : 'Select ') + " fleet")])], 1)]);
  }), 1) : _vm._e()], 1) : _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet ownership")]), _vm._v(" "), _c('br'), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s(_vm.form.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED'))])], 1), _vm.form.fleet_ownership ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet Type")]), _vm._v(" "), _c('br'), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("Tug & Barge")])], 1) : _vm._e()], 1), _vm.form.fleet_type_id ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_vm._v("Fleets")]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-warp"
  }, _vm._l(_vm.form.fleets, function (objDetail, detailIndex) {
    return _c('b-badge', {
      key: detailIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 00" + _vm._s(detailIndex + 1))]);
  }), 1)])], 1) : _vm._e()], 1)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Collection of Forms")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [_vm.optionsForms.length > 0 ? _c('v-multiselect', {
    attrs: {
      "options": _vm.optionsForms,
      "multiple": true,
      "group-select": true,
      "placeholder": "Select Forms",
      "track-by": "label",
      "label": "label"
    },
    model: {
      value: _vm.form.project_forms,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "project_forms", $$v);
      },
      expression: "form.project_forms"
    }
  }) : _c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No forms yet.")])], 1) : _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap gap-2"
  }, _vm._l(_vm.form.project_forms, function (state, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "border border-primary text-primary text-uppercase m-1",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.label) + " ")]);
  }), 1)]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', {
    attrs: {
      "width": "30%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode Start")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "id": "periode_start",
      "name": "periode_start",
      "type": "date"
    },
    model: {
      value: _vm.form.periode_start,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "periode_start", $$v);
      },
      expression: "form.periode_start"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form = _vm.form) !== null && _vm$form !== void 0 && _vm$form.periode_start ? _vm.moment((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : _vm$form2.periode_start).format('DD MMMM YYYY') : '-'))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode End")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "id": "periode_end",
      "name": "periode_end",
      "type": "date"
    },
    model: {
      value: _vm.form.periode_end,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "periode_end", $$v);
      },
      expression: "form.periode_end"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form3 = _vm.form) !== null && _vm$form3 !== void 0 && _vm$form3.periode_start ? _vm.moment((_vm$form4 = _vm.form) === null || _vm$form4 === void 0 ? void 0 : _vm$form4.periode_start).format('DD MMMM YYYY') : '-'))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Contract Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('v-multiselect', {
    attrs: {
      "placeholder": "search/add contract",
      "label": "name",
      "track-by": "id",
      "close-on-select": true,
      "clear-on-select": true,
      "options": _vm.optionsContractType,
      "required": ""
    },
    on: {
      "search-change": _vm.searchContractType
    },
    model: {
      value: _vm.form.contract_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contract_type_id", $$v);
      },
      expression: "form.contract_type_id"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    on: {
      "click": _vm.doAddContractType
    },
    slot: "noResult"
  }, [_vm._v(" Not Found. Click to add "), _c('strong', [_vm._v(_vm._s(_vm.contractTypeName))])])]) : _vm._e(), ['ADD', 'EDIT'].includes(_vm.action) && _vm.form.contract_type_id ? _c('span', [_vm._v(_vm._s((_vm$form$contract_typ = _vm.form.contract_type_id) !== null && _vm$form$contract_typ !== void 0 && _vm$form$contract_typ.is_spal ? "Yes, using spal" : 'Not using spal'))]) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont22 = _vm.detailProjectContract) === null || _vm$detailProjectCont22 === void 0 ? void 0 : (_vm$detailProjectCont23 = _vm$detailProjectCont22.contract_type) === null || _vm$detailProjectCont23 === void 0 ? void 0 : _vm$detailProjectCont23.name))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Cargo Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('v-select', {
    staticClass: "w-100 mb-2",
    attrs: {
      "label": "text",
      "name": "cargo_type_id",
      "options": _vm.optionsCargoType,
      "reduce": function reduce(optionsCargoType) {
        return optionsCargoType.value;
      },
      "placeholder": "Select...",
      "required": ""
    },
    on: {
      "input": function input($event) {
        _vm.onDataIntoPlan('cargo_type_id', _vm.form.cargo_type_id);
        _vm.onChangeCargoType();
      }
    },
    model: {
      value: _vm.form.cargo_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cargo_type_id", $$v);
      },
      expression: "form.cargo_type_id"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont24 = (_vm$detailProjectCont25 = _vm.detailProjectContract) === null || _vm$detailProjectCont25 === void 0 ? void 0 : (_vm$detailProjectCont26 = _vm$detailProjectCont25.cargo_type) === null || _vm$detailProjectCont26 === void 0 ? void 0 : _vm$detailProjectCont26.name) !== null && _vm$detailProjectCont24 !== void 0 ? _vm$detailProjectCont24 : '-'))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Term")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('v-select', {
    staticClass: "w-100 mb-2",
    attrs: {
      "label": "text",
      "name": "cargo_type_id",
      "options": _vm.optionsTerms,
      "reduce": function reduce(optionsTerms) {
        return optionsTerms.value;
      },
      "placeholder": "Select..."
    },
    on: {
      "input": function input($event) {
        return _vm.onDataIntoPlan('terms_id', _vm.form.terms_id);
      }
    },
    model: {
      value: _vm.form.terms_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "terms_id", $$v);
      },
      expression: "form.terms_id"
    }
  }) : _vm._e(), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Text"
    },
    on: {
      "change": function change($event) {
        return _vm.onDataIntoPlan('terms_remarks', _vm.form.terms_remarks);
      }
    },
    model: {
      value: _vm.form.terms_remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "terms_remarks", $$v);
      },
      expression: "form.terms_remarks"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont27 = (_vm$detailProjectCont28 = _vm.detailProjectContract) === null || _vm$detailProjectCont28 === void 0 ? void 0 : (_vm$detailProjectCont29 = _vm$detailProjectCont28.terms) === null || _vm$detailProjectCont29 === void 0 ? void 0 : _vm$detailProjectCont29.name) !== null && _vm$detailProjectCont27 !== void 0 ? _vm$detailProjectCont27 : '-') + " "), _c('br'), _vm._v(" Remarks: " + _vm._s(_vm.form.terms_remarks))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Total Quantity")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.form.cargo_type_id ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s((_vm$totalQuantityAdd = _vm.totalQuantityAdd) === null || _vm$totalQuantityAdd === void 0 ? void 0 : _vm$totalQuantityAdd.toLocaleString()))]), _vm.optionUnit.length <= 0 && !_vm.unit_others ? _c('strong', [_vm._v(_vm._s(_vm.form.unit))]) : _vm._e(), _vm.optionUnit.length > 0 && !_vm.unit_others ? _c('v-select', {
    staticClass: "w-100 mb-2",
    attrs: {
      "label": "text",
      "name": "unit",
      "options": _vm.optionUnit,
      "reduce": function reduce(optionUnit) {
        return optionUnit.value;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.unit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "unit", $$v);
      },
      expression: "form.unit"
    }
  }) : _vm._e(), _vm.unit_others ? _c('b-form-input', {
    staticClass: "w-50",
    attrs: {
      "size": "sm",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.unit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "unit", $$v);
      },
      expression: "form.unit"
    }
  }) : _vm._e()], 1) : _c('div', [_c('i', [_vm._v("Choose cargo type first.")])])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POL")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, _vm._l(_vm.form.pol, function (obj, polIndex) {
    return _c('div', {
      key: polIndex,
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('v-select', {
      staticClass: "mr-2 w-100",
      staticStyle: {
        "background-color": "#ffffff"
      },
      attrs: {
        "label": "text",
        "options": _vm.positionList,
        "reduce": function reduce(positionList) {
          return positionList.text;
        },
        "placeholder": "Select..."
      },
      on: {
        "input": function input($event) {
          return _vm.onDataIntoPlan('pol', _vm.form.pol);
        }
      },
      model: {
        value: obj.value,
        callback: function callback($$v) {
          _vm.$set(obj, "value", $$v);
        },
        expression: "obj.value"
      }
    }), obj.value === 'Others' ? _c('b-form-input', {
      staticClass: "mr-2",
      attrs: {
        "size": "sm",
        "placeholder": "Type Text"
      },
      model: {
        value: obj.valueOther,
        callback: function callback($$v) {
          _vm.$set(obj, "valueOther", $$v);
        },
        expression: "obj.valueOther"
      }
    }) : _vm._e(), polIndex === 0 ? _c('i', {
      staticClass: "fa fa-plus text-success border border-success p-1 rounded-circle",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.pol.push({
            value: '',
            valueOther: ''
          });
        }
      }
    }) : _c('i', {
      staticClass: "fa fa-trash text-danger border border-danger p-1 rounded-circle",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.pol.splice(polIndex, 1);
        }
      }
    })], 1);
  }), 0) : _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-2 w-100"
  }, _vm._l(_vm.form.pol, function (obj, polIndex) {
    var _obj$value;
    return _c('span', {
      key: polIndex
    }, [_vm._v(_vm._s(polIndex + 1) + ". " + _vm._s((_obj$value = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value !== void 0 ? _obj$value : '-'))]);
  }), 0)]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POD")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, _vm._l(_vm.form.pod, function (obj, podIndex) {
    return _c('div', {
      key: podIndex,
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('v-select', {
      staticClass: "mr-2 w-100",
      staticStyle: {
        "background-color": "#ffffff"
      },
      attrs: {
        "label": "text",
        "options": _vm.positionList,
        "reduce": function reduce(positionList) {
          return positionList.text;
        },
        "placeholder": "Select..."
      },
      on: {
        "input": function input($event) {
          return _vm.onDataIntoPlan('pod', _vm.form.pod);
        }
      },
      model: {
        value: obj.value,
        callback: function callback($$v) {
          _vm.$set(obj, "value", $$v);
        },
        expression: "obj.value"
      }
    }), obj.value === 'Others' ? _c('b-form-input', {
      staticClass: "mr-2",
      attrs: {
        "size": "sm",
        "placeholder": "Type Text"
      },
      model: {
        value: obj.valueOther,
        callback: function callback($$v) {
          _vm.$set(obj, "valueOther", $$v);
        },
        expression: "obj.valueOther"
      }
    }) : _vm._e(), podIndex === 0 ? _c('i', {
      staticClass: "fa fa-plus text-success border border-success p-1 rounded-circle",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.pod.push({
            value: '',
            valueOther: ''
          });
        }
      }
    }) : _c('i', {
      staticClass: "fa fa-trash text-danger border border-danger p-1 rounded-circle",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.pod.splice(podIndex, 1);
        }
      }
    })], 1);
  }), 0) : _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-2 w-100"
  }, _vm._l(_vm.form.pod, function (obj, podIndex) {
    var _obj$value2;
    return _c('span', {
      key: podIndex
    }, [_vm._v(_vm._s(podIndex + 1) + ". " + _vm._s((_obj$value2 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value2 !== void 0 ? _obj$value2 : '-'))]);
  }), 0)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Laycan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "id": "laycan",
      "name": "laycan",
      "type": "date"
    },
    model: {
      value: _vm.form.laycan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "laycan", $$v);
      },
      expression: "form.laycan"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form5 = _vm.form) !== null && _vm$form5 !== void 0 && _vm$form5.laycan ? _vm.moment((_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : _vm$form6.laycan).format('DD MMMM YYYY') : '-'))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Distance")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "id": "distance",
      "name": "distance",
      "placeholder": "Type Number ...",
      "type": "number"
    },
    model: {
      value: _vm.form.distance,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distance", $$v);
      },
      expression: "form.distance"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form7 = _vm.form) === null || _vm$form7 === void 0 ? void 0 : _vm$form7.distance))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Shipment Plan Number")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.shipmentPlanNumber))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Freigh Rate (Est)")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-form-input', {
    staticClass: "mb-2",
    attrs: {
      "id": "freight_rate_est",
      "name": "freight_rate_est",
      "placeholder": "Type Number ...",
      "type": "number",
      "required": ""
    },
    model: {
      value: _vm.form.freight_rate_est,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "freight_rate_est", $$v);
      },
      expression: "form.freight_rate_est"
    }
  }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.freight_rate_est)))]), _c('b-form-radio-group', {
    attrs: {
      "inline": ""
    }
  }, [_c('b-form-radio', {
    attrs: {
      "inline": "",
      "value": "include",
      "required": "",
      "name": "ppn",
      "id": "include"
    },
    model: {
      value: _vm.form.ppn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ppn", $$v);
      },
      expression: "form.ppn"
    }
  }, [_vm._v("Include PPN")]), _c('b-form-radio', {
    attrs: {
      "inline": "",
      "value": "exclude",
      "required": "",
      "name": "ppn",
      "id": "exclude"
    },
    model: {
      value: _vm.form.ppn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ppn", $$v);
      },
      expression: "form.ppn"
    }
  }, [_vm._v("Exclude PPN")])], 1)], 1)]) : _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency((_vm$form8 = _vm.form) === null || _vm$form8 === void 0 ? void 0 : _vm$form8.freight_rate_est)))]), _c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.form.ppn == 'include' ? 'Include ' : 'Exclude ') + " PPN")])])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-textarea', {
    attrs: {
      "id": "remarks",
      "name": "remarks",
      "placeholder": "Type Text ..."
    },
    model: {
      value: _vm.form.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.form.remarks))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Needs More Detail Plan ?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": ""
    },
    model: {
      value: _vm.form.need_more_detail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "need_more_detail", $$v);
      },
      expression: "form.need_more_detail"
    }
  }, [_vm._v(" Yes / No ")])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Need Tender ?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": ['PREVIEW'].includes(_vm.action)
    },
    model: {
      value: _vm.form.is_tender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_tender", $$v);
      },
      expression: "form.is_tender"
    }
  }, [_vm._v(" Yes / No ")])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Needs Pre-Fixtured Analysis?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": ['PREVIEW'].includes(_vm.action)
    },
    model: {
      value: _vm.form.is_pfa,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_pfa", $$v);
      },
      expression: "form.is_pfa"
    }
  }, [_vm._v(" Yes / No ")])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Need Document?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": ['PREVIEW'].includes(_vm.action)
    },
    model: {
      value: _vm.form.need_document,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "need_document", $$v);
      },
      expression: "form.need_document"
    }
  }, [_vm._v(" Yes / No ")])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Is this project needs approval ?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": ['PREVIEW'].includes(_vm.action)
    },
    model: {
      value: _vm.form.need_approval,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "need_approval", $$v);
      },
      expression: "form.need_approval"
    }
  }, [_vm._v(" Yes / No ")])], 1), _vm.form.need_approval ? _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("How much approval is needed?")]) : _vm._e(), _vm.form.need_approval ? _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]) : _vm._e(), _vm.form.need_approval ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD'].includes(_vm.action) ? _c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "required": ""
    },
    on: {
      "change": _vm.onChangeManyApproval
    },
    model: {
      value: _vm.form.many_approval,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "many_approval", $$v);
      },
      expression: "form.many_approval"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select values....")]), _c('b-form-select-option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("1")]), _c('b-form-select-option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("2")]), _c('b-form-select-option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v("3")]), _c('b-form-select-option', {
    attrs: {
      "value": "4"
    }
  }, [_vm._v("4")])], 1) : _c('span', [_vm._v(_vm._s(_vm.form.many_approval))])], 1) : _vm._e()]), _vm.form.many_approval ? _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("User Approval")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), ['ADD'].includes(_vm.action) ? _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, _vm._l(_vm.form.user_approval, function (user, userIndex) {
    return _c('div', {
      key: userIndex,
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('strong', [_vm._v("#" + _vm._s(userIndex + 1))]), _c('v-select', {
      staticClass: "w-100 ml-2",
      attrs: {
        "label": "text",
        "name": "user",
        "options": _vm.optionsExpertUsers,
        "reduce": function reduce(optionsExpertUsers) {
          return optionsExpertUsers.value;
        },
        "placeholder": "Select User",
        "required": ""
      },
      model: {
        value: user.user,
        callback: function callback($$v) {
          _vm.$set(user, "user", $$v);
        },
        expression: "user.user"
      }
    })], 1);
  }), 0) : _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, _vm._l((_vm$detailProjectCont30 = _vm.detailProjectContract) === null || _vm$detailProjectCont30 === void 0 ? void 0 : _vm$detailProjectCont30.user_approval, function (user, userIndex) {
    return _c('div', {
      key: userIndex,
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('strong', {
      staticClass: "mr-2"
    }, [_vm._v("#" + _vm._s(userIndex + 1))]), _c('span', [_vm._v(_vm._s(user === null || user === void 0 ? void 0 : user.name))])]);
  }), 0)]) : _vm._e()])])])]) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_vm.form.is_tender && _vm.form.customer_id && _vm.form.project_sub_id || _vm.form.is_tender && _vm.form.customer_id ? _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Tender Form")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("PIC")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.formTender.pic,
      callback: function callback($$v) {
        _vm.$set(_vm.form.formTender, "pic", $$v);
      },
      expression: "form.formTender.pic"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.form.formTender.pic))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Url")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.formTender.url,
      callback: function callback($$v) {
        _vm.$set(_vm.form.formTender, "url", $$v);
      },
      expression: "form.formTender.url"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.form.formTender.url))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Description")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "colSpan": "4"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.formTender.description_of_tender,
      callback: function callback($$v) {
        _vm.$set(_vm.form.formTender, "description_of_tender", $$v);
      },
      expression: "form.formTender.description_of_tender"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.form.formTender.description_of_tender))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("File")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "colSpan": "4"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-file', {
    staticClass: "form-control",
    attrs: {
      "plain": "",
      "id": "file",
      "accept": "image/*,application/*",
      "multiple": ""
    },
    model: {
      value: _vm.form.formTender.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form.formTender, "file", $$v);
      },
      expression: "form.formTender.file"
    }
  }) : _c('img', {
    directives: [{
      name: "else",
      rawName: "v-else"
    }],
    staticStyle: {
      "width": "40px",
      "height": "40px"
    },
    attrs: {
      "src": require("../../../assets/images/activity/pdf.png"),
      "alt": "img-image"
    }
  })], 1)])])])])]) : _vm._e(), _vm.form.need_document && _vm.form.customer_id && _vm.form.project_sub_id ? _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Document Attachment")]), _c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "width": "20px"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("Name of File / Document")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("File / Document")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "width": "20px"
    }
  }, [_vm._v("#")])])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('tbody', _vm._l(_vm.form.form_document, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center align-top"
    }, [_vm._v("#" + _vm._s(index + 1))]), _c('td', {
      staticClass: "align-top"
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Name of File",
        "required": ""
      },
      model: {
        value: state.filename,
        callback: function callback($$v) {
          _vm.$set(state, "filename", $$v);
        },
        expression: "state.filename"
      }
    })], 1), _c('td', [_c('b-form-file', {
      attrs: {
        "required": state !== null && state !== void 0 && state.url ? false : true,
        "id": 'fileSupport' + index,
        "placeholder": "Choose a file",
        "accept": "application/pdf"
      },
      model: {
        value: state.file,
        callback: function callback($$v) {
          _vm.$set(state, "file", $$v);
        },
        expression: "state.file"
      }
    }), _c('a', {
      staticClass: "text-primary",
      attrs: {
        "href": state === null || state === void 0 ? void 0 : state.url
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.filename))])], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [index !== 0 ? _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.form.form_document.splice(index, 1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash pr-0"
    })]) : _vm._e()], 1)]);
  }), 0) : _c('tbody', _vm._l(_vm.detailProjectContract.project_document, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', [_c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.filename))])]), _c('td', {
      staticClass: "text-center"
    }, [_c('a', {
      staticClass: "text-primary",
      attrs: {
        "href": state === null || state === void 0 ? void 0 : state.url
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.filename) + "." + _vm._s(state === null || state === void 0 ? void 0 : state.extension))])]), _c('td')]);
  }), 0)])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.addFormDocument();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus pr-0"
  }), _vm._v(" ADD MORE ATTACHMENT")]) : _vm._e()], 1)]) : _vm._e()], 1)], 1)], 1)], 1), _vm.form.need_more_detail && _vm.form.customer_id && _vm.form.project_sub_id && _vm.project_type === 'existing' || _vm.form.need_more_detail && _vm.form.customer_id && _vm.project_type === 'new_project' ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Detail Project Contract Plan")]), _c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "200px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("SHIPMENT PLAN DETAIL NUMBER")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "350px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("DESCRIPTION")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "300px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "300px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("LOCATION")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("SHIPMENT (MONTH)")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PLAN DATE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PLAN QUANTITY")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("REMARKS")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("STATUS")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("ACTION")])]), _c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_vm._v("ETA POL")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_vm._v("ETA POD")])])]), _c('tbody', _vm._l((_vm$form9 = _vm.form) === null || _vm$form9 === void 0 ? void 0 : _vm$form9.detail_plan, function (state, index) {
    var _state$description, _state$terms_remarks, _state$remarks;
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center align-top"
    }, [_vm._v("#" + _vm._s(index + 1))]), _c('th', {
      staticClass: "text-center align-top"
    }, [_c('span', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.shipmentPlanNumber + "-".concat(_vm.formatNumber(index + 1))))])]), _c('td', {
      staticClass: "text-center align-top"
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-textarea', {
      attrs: {
        "placeholder": "Type Text",
        "type": "text",
        "id": "description",
        "name": "description",
        "size": "sm",
        "rows": "4"
      },
      model: {
        value: state.description,
        callback: function callback($$v) {
          _vm.$set(state, "description", $$v);
        },
        expression: "state.description"
      }
    }) : _c('span', [_vm._v(_vm._s((_state$description = state === null || state === void 0 ? void 0 : state.description) !== null && _state$description !== void 0 ? _state$description : '-'))])], 1), ['ADD', 'EDIT'].includes(_vm.action) ? _c('td', {
      staticClass: "text-left align-top"
    }, [_c('b-row', {
      staticClass: "mb-2"
    }, [_c('b-col', {
      staticClass: "mb-2",
      attrs: {
        "lg": "12"
      }
    }, [_c('span', [_vm._v("Fleet ownership")]), _c('b-form-select', {
      staticClass: "form-control",
      attrs: {
        "plain": "",
        "required": "",
        "name": "fleet_ownership"
      },
      on: {
        "change": function change($event) {
          return _vm.onChangeOwnership(state.fleet_ownership);
        }
      },
      model: {
        value: state.fleet_ownership,
        callback: function callback($$v) {
          _vm.$set(state, "fleet_ownership", $$v);
        },
        expression: "state.fleet_ownership"
      }
    }, [_c('option', {
      attrs: {
        "value": ""
      }
    }, [_vm._v("Select ownership...")]), _c('option', {
      attrs: {
        "value": "own-ship"
      }
    }, [_vm._v("OWN SHIP")]), _c('option', {
      attrs: {
        "value": "chartered"
      }
    }, [_vm._v("CHARTERED")])])], 1), state.fleet_ownership ? _c('b-col', {
      attrs: {
        "lg": "12"
      }
    }, [_c('span', [_vm._v("Fleet Type")]), _c('b-form-select', {
      staticClass: "form-control",
      attrs: {
        "plain": "",
        "required": "",
        "name": "fleet_type_id"
      },
      model: {
        value: state.fleet_type_id,
        callback: function callback($$v) {
          _vm.$set(state, "fleet_type_id", $$v);
        },
        expression: "state.fleet_type_id"
      }
    }, [_c('option', {
      domProps: {
        "value": null
      }
    }, [_vm._v("Select fleet type...")]), _c('option', {
      domProps: {
        "value": 1
      }
    }, [_vm._v("Tug & Barge")]), _c('option', {
      domProps: {
        "value": 2
      }
    }, [_vm._v("Mother Vessel")]), _c('option', {
      domProps: {
        "value": 3
      }
    }, [_vm._v("Floating Crane")]), _c('option', {
      domProps: {
        "value": 4
      }
    }, [_vm._v("Floating Barge (Oil Barge)")]), _c('option', {
      domProps: {
        "value": 5
      }
    }, [_vm._v("Pusher Tug & Pusher Barge / Barge")])])], 1) : _vm._e()], 1), state.fleet_type_id ? _c('b-row', _vm._l(state.fleets, function (objDetail, detailIndex) {
      return _c('b-col', {
        key: detailIndex,
        attrs: {
          "md": "12"
        }
      }, [_c('div', {
        staticClass: "d-flex mt-2 flex-column w-100"
      }, [_c('strong', {
        staticClass: "text-truncate"
      }, [_vm._v(_vm._s(_vm.showVehicleName(objDetail.vehicle_id)))]), _c('b-button', {
        attrs: {
          "variant": "primary",
          "size": "sm"
        },
        on: {
          "click": function click($event) {
            return _vm.onOpenSlideDetailVehicle(objDetail === null || objDetail === void 0 ? void 0 : objDetail.vehicle_id, detailIndex);
          }
        }
      }, [_vm._v(_vm._s(objDetail.vehicle_id ? 'Change ' : 'Select ') + " fleet")])], 1)]);
    }), 1) : _vm._e()], 1) : _c('td', {
      staticClass: "text-left align-top"
    }, [_c('b-row', {
      staticClass: "mb-2"
    }, [_c('b-col', {
      staticClass: "mb-2",
      attrs: {
        "lg": "12"
      }
    }, [_c('span', [_vm._v("Fleet ownership")]), _vm._v(" "), _c('br'), _c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase mb-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(state.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED'))])], 1), state.fleet_ownership ? _c('b-col', {
      attrs: {
        "lg": "12"
      }
    }, [_c('span', [_vm._v("Fleet Type")]), _vm._v(" "), _c('br'), _c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase mb-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("Tug & Barge")])], 1) : _vm._e()], 1), state.fleet_type_id ? _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "12"
      }
    }, [_vm._v("Fleets")]), _vm._l(state.fleets, function (objDetail, detailIndex) {
      return _c('b-col', {
        key: detailIndex,
        attrs: {
          "md": "12"
        }
      }, [_c('b-badge', {
        staticClass: "border border-primary text-primary text-uppercase mb-2",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v("TB. TAVIA 00" + _vm._s(detailIndex + 1))])], 1);
    })], 2) : _vm._e()], 1), _c('td', {
      staticClass: "text-left align-top"
    }, [_c('b-form-group', {
      staticClass: "mb-2",
      attrs: {
        "label": "Cargo Type",
        "label-for": "cargo_type_id"
      }
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('v-select', {
      staticClass: "w-100 mb-2",
      attrs: {
        "label": "text",
        "name": "cargo_type_id",
        "options": _vm.optionsCargoType,
        "reduce": function reduce(optionsCargoType) {
          return optionsCargoType.value;
        },
        "placeholder": "Select User",
        "required": ""
      },
      model: {
        value: state.cargo_type_id,
        callback: function callback($$v) {
          _vm.$set(state, "cargo_type_id", $$v);
        },
        expression: "state.cargo_type_id"
      }
    }) : _c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase mb-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(_vm.cargoTypeLabel(state.cargo_type_id)))])], 1), _c('b-form-group', {
      staticClass: "mb-2",
      attrs: {
        "label": "Term"
      }
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "sm",
        "options": _vm.optionsTerms
      },
      model: {
        value: state.terms_id,
        callback: function callback($$v) {
          _vm.$set(state, "terms_id", $$v);
        },
        expression: "state.terms_id"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("Select Term")])], 1) : _c('b-badge', {
      staticClass: "border border-dark text-dark text-uppercase mb-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(_vm.termsLabel(state.terms_id)))]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-textarea', {
      staticClass: "mt-2",
      attrs: {
        "rows": "2",
        "placeholder": "Text Type"
      },
      model: {
        value: state.terms_remarks,
        callback: function callback($$v) {
          _vm.$set(state, "terms_remarks", $$v);
        },
        expression: "state.terms_remarks"
      }
    }) : _c('span', [_vm._v("Remarks: "), _c('span', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s((_state$terms_remarks = state === null || state === void 0 ? void 0 : state.terms_remarks) !== null && _state$terms_remarks !== void 0 ? _state$terms_remarks : '-'))])])], 1)], 1), _c('td', {
      staticClass: "text-left align-top"
    }, [_c('span', [_vm._v("POL")]), ['ADD', 'EDIT'].includes(_vm.action) ? _vm._l(state.pol, function (obj, polIndex) {
      return _c('div', {
        key: polIndex,
        staticClass: "d-flex align-items-center mb-2 w-100"
      }, [_c('v-select', {
        staticClass: "mr-2 w-100",
        staticStyle: {
          "background-color": "#ffffff"
        },
        attrs: {
          "label": "text",
          "options": _vm.positionList,
          "reduce": function reduce(positionList) {
            return positionList.text;
          },
          "placeholder": "Select..."
        },
        model: {
          value: obj.value,
          callback: function callback($$v) {
            _vm.$set(obj, "value", $$v);
          },
          expression: "obj.value"
        }
      }), obj.value === 'Others' ? _c('b-form-input', {
        staticClass: "mr-2",
        attrs: {
          "size": "sm",
          "placeholder": "Type Text"
        },
        model: {
          value: obj.valueOther,
          callback: function callback($$v) {
            _vm.$set(obj, "valueOther", $$v);
          },
          expression: "obj.valueOther"
        }
      }) : _vm._e(), polIndex === 0 ? _c('i', {
        staticClass: "fa fa-plus text-success border border-success p-1 rounded-circle",
        staticStyle: {
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return state.pol.push({
              value: '',
              valueOther: ''
            });
          }
        }
      }) : _c('i', {
        staticClass: "fa fa-trash text-danger border border-danger p-1 rounded-circle",
        staticStyle: {
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return state.pol.splice(polIndex, 1);
          }
        }
      })], 1);
    }) : [_c('div', {
      staticClass: "d-flex flex-column mb-2 w-100"
    }, _vm._l(state.pol, function (obj, polIndex) {
      var _obj$value3;
      return _c('span', {
        key: polIndex
      }, [_vm._v(_vm._s(polIndex + 1) + ". " + _vm._s((_obj$value3 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value3 !== void 0 ? _obj$value3 : '-'))]);
    }), 0)], _c('div', {
      staticClass: "d-flex w-100 border mb-2"
    }), _c('span', [_vm._v("POD")]), ['ADD', 'EDIT'].includes(_vm.action) ? _vm._l(state.pod, function (obj, podIndex) {
      return _c('div', {
        key: podIndex,
        staticClass: "d-flex align-items-center mb-2 w-100"
      }, [_c('v-select', {
        staticClass: "mr-2 w-100",
        staticStyle: {
          "background-color": "#ffffff"
        },
        attrs: {
          "label": "text",
          "options": _vm.positionList,
          "reduce": function reduce(positionList) {
            return positionList.text;
          },
          "placeholder": "Select..."
        },
        model: {
          value: obj.value,
          callback: function callback($$v) {
            _vm.$set(obj, "value", $$v);
          },
          expression: "obj.value"
        }
      }), obj.value === 'Others' ? _c('b-form-input', {
        staticClass: "mr-2",
        attrs: {
          "size": "sm",
          "placeholder": "Type Text"
        },
        model: {
          value: obj.valueOther,
          callback: function callback($$v) {
            _vm.$set(obj, "valueOther", $$v);
          },
          expression: "obj.valueOther"
        }
      }) : _vm._e(), podIndex === 0 ? _c('i', {
        staticClass: "fa fa-plus text-success border border-success p-1 rounded-circle",
        staticStyle: {
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return state.pod.push({
              value: '',
              valueOther: ''
            });
          }
        }
      }) : _c('i', {
        staticClass: "fa fa-trash text-danger border border-danger p-1 rounded-circle",
        staticStyle: {
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return state.pod.splice(podIndex, 1);
          }
        }
      })], 1);
    }) : [_c('div', {
      staticClass: "d-flex flex-column mb-2 w-100"
    }, _vm._l(state.pod, function (obj, podIndex) {
      var _obj$value4;
      return _c('span', {
        key: podIndex
      }, [_vm._v(_vm._s(podIndex + 1) + ". " + _vm._s((_obj$value4 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value4 !== void 0 ? _obj$value4 : '-'))]);
    }), 0)]], 2), _c('td', {
      staticClass: "text-center align-top"
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "sm"
      },
      model: {
        value: state.shipment,
        callback: function callback($$v) {
          _vm.$set(state, "shipment", $$v);
        },
        expression: "state.shipment"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": ""
      }
    }, [_vm._v("SELECT MONTH")]), _c('b-form-select-option', {
      attrs: {
        "value": "JANUARY"
      }
    }, [_vm._v("JANUARI")]), _c('b-form-select-option', {
      attrs: {
        "value": "FEBRUARI"
      }
    }, [_vm._v("FEBRUARI")]), _c('b-form-select-option', {
      attrs: {
        "value": "MARET"
      }
    }, [_vm._v("MARET")]), _c('b-form-select-option', {
      attrs: {
        "value": "APRIL"
      }
    }, [_vm._v("APRIL")]), _c('b-form-select-option', {
      attrs: {
        "value": "MEI"
      }
    }, [_vm._v("MEI")]), _c('b-form-select-option', {
      attrs: {
        "value": "JUNI"
      }
    }, [_vm._v("JUNI")]), _c('b-form-select-option', {
      attrs: {
        "value": "JULI"
      }
    }, [_vm._v("JULI")]), _c('b-form-select-option', {
      attrs: {
        "value": "AGUSTUS"
      }
    }, [_vm._v("AGUSTUS")]), _c('b-form-select-option', {
      attrs: {
        "value": "SEPTEMBER"
      }
    }, [_vm._v("SEPTEMBER")]), _c('b-form-select-option', {
      attrs: {
        "value": "OKTOBER"
      }
    }, [_vm._v("OKTOBER")]), _c('b-form-select-option', {
      attrs: {
        "value": "NOVEMBER"
      }
    }, [_vm._v("NOVEMBER")]), _c('b-form-select-option', {
      attrs: {
        "value": "DESEMBER"
      }
    }, [_vm._v("DESEMBER")])], 1) : _c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.shipment))])], 1), _c('td', {
      staticClass: "text-center align-top",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
      staticClass: "mb-1",
      attrs: {
        "type": "date",
        "id": "eta_pol",
        "size": "sm"
      },
      model: {
        value: state.eta_pol,
        callback: function callback($$v) {
          _vm.$set(state, "eta_pol", $$v);
        },
        expression: "state.eta_pol"
      }
    }) : _c('span', [_vm._v(_vm._s(state.eta_pol ? _vm.moment(state.eta_pol).format('DD MMMM YYYY') : '-'))])], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
      staticClass: "mb-1",
      attrs: {
        "type": "date",
        "id": "eta_pod",
        "size": "sm"
      },
      model: {
        value: state.eta_pod,
        callback: function callback($$v) {
          _vm.$set(state, "eta_pod", $$v);
        },
        expression: "state.eta_pod"
      }
    }) : _c('span', [_vm._v(_vm._s(state.eta_pod ? _vm.moment(state.eta_pod).format('DD MMMM YYYY') : '-'))])], 1)], 1)], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "id": "plan_quantity",
        "size": "sm",
        "placeholder": "0",
        "required": ""
      },
      model: {
        value: state.plan_quantity,
        callback: function callback($$v) {
          _vm.$set(state, "plan_quantity", $$v);
        },
        expression: "state.plan_quantity"
      }
    }) : _c('span', [_vm._v(_vm._s(state.plan_quantity.toLocaleString()))])], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-textarea', {
      attrs: {
        "rows": "4",
        "placeholder": "Comments if any / Komentar jika ada",
        "name": "remarks"
      },
      model: {
        value: state.remarks,
        callback: function callback($$v) {
          _vm.$set(state, "remarks", $$v);
        },
        expression: "state.remarks"
      }
    }) : _c('span', [_vm._v(_vm._s((_state$remarks = state === null || state === void 0 ? void 0 : state.remarks) !== null && _state$remarks !== void 0 ? _state$remarks : '-'))])], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "sm"
      },
      model: {
        value: state.status,
        callback: function callback($$v) {
          _vm.$set(state, "status", $$v);
        },
        expression: "state.status"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": ""
      }
    }, [_vm._v("Select...")]), _c('b-form-select-option', {
      attrs: {
        "value": "plan"
      }
    }, [_vm._v("Plan")]), _c('b-form-select-option', {
      attrs: {
        "value": "progress"
      }
    }, [_vm._v("Progress")]), _c('b-form-select-option', {
      attrs: {
        "value": "closed"
      }
    }, [_vm._v("Closed")])], 1) : _c('strong', {
      class: "".concat(['plan'].includes(state === null || state === void 0 ? void 0 : state.status) ? 'text-primary' : ['progress'].includes(state === null || state === void 0 ? void 0 : state.status) ? 'text-warning' : 'text-success', " text-capitalize")
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.status))])], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [index !== 0 || ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.form.detail_plan.splice(index, 1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash pr-0"
    })]) : _vm._e()], 1)]);
  }), 0)])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.addDetailPlanData(false);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus pr-0"
  }), _vm._v(" ADD MORE PLAN")]) : _vm._e()], 1)]) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "detail_vehicle",
      "title": "Detail Fleet",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var hide = _ref3.hide;
        return [_c('SlidePreviewDetailVehicle', {
          attrs: {
            "options-fleets": _vm.optionsFilteredFleets,
            "fleet": _vm.selectedFleet,
            "fleet-type-id": _vm.form.fleet_type_id,
            "ownership": _vm.form.fleet_ownership,
            "on-open-slide-detail-vehicle": _vm.onOpenSlideDetailVehicle
          },
          on: {
            "updateVehicleId": _vm.updateVehicleId
          }
        })];
      }
    }], null, false, 2498527902),
    model: {
      value: _vm.slidePreviewDetailVehicle,
      callback: function callback($$v) {
        _vm.slidePreviewDetailVehicle = $$v;
      },
      expression: "slidePreviewDetailVehicle"
    }
  })], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "form-list"
    }
  }, [_c('TabFormList', {
    attrs: {
      "formData": _vm.detailProjectContract,
      "openInspectionChecklistSlide": _vm.openInspectionChecklistSlide
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "pfa"
    }
  }, [_c('SlideFormPFA', {
    attrs: {
      "pfaId": (_vm$detailProjectCont31 = _vm.detailProjectContract) === null || _vm$detailProjectCont31 === void 0 ? void 0 : _vm$detailProjectCont31.id,
      "pfaAction": 'PREVIEW',
      "options-cargo-type": _vm.optionsCargoType,
      "optionsFleets": _vm.optionsFleets,
      "onExportPFA": _vm.onExportPFA,
      "refreshDataTable": function refreshDataTable() {},
      "openPfaSlide": function openPfaSlide() {}
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "spal-process"
    }
  }, [_c('SlideSpalProcess', {
    attrs: {
      "id": (_vm$detailProjectCont32 = _vm.detailProjectContract) === null || _vm$detailProjectCont32 === void 0 ? void 0 : _vm$detailProjectCont32.id,
      "closeSlideSpalProcess": function closeSlideSpalProcess() {},
      "action": 'PREVIEW'
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "trip-document"
    }
  }, [_c('TabTripDocument', {
    attrs: {
      "projectData": _vm.detailProjectContract
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "payment-list"
    }
  }, [_c('TabPaymentList', {
    attrs: {
      "projectData": _vm.detailProjectContract
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "real-shipment"
    }
  }, [_c('TabRealShipment', {
    attrs: {
      "projectData": _vm.detailProjectContract
    }
  })], 1)], 1)])], 1)], 1) : _c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }