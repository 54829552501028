var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("CUSTOMERS")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center gap-4 float-right"
        }, [_c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.openCustomerMaster
          }
        }, [_c('i', {
          staticClass: "fa fa-home mr-2"
        }), _c('span', [_vm._v("Masters Customer")])]), _c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.onExportCustomer
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _c('span', [_vm._v("Export Data")])]), _vm.addPermission ? _c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.addCustomer
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _c('span', [_vm._v("Create Data")])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$listsCustomer, _vm$listsCustomer$dat, _vm$listsCustomer2, _vm$listsCustomer2$da, _vm$listsCustomer3, _vm$listsCustomer3$me, _vm$listsCustomer4, _vm$listsCustomer4$me;
        return [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search ID/Name...",
            "name": "search"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Customer Class",
            "label-for": "customer_class_id"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "name": "customer_class_id",
            "options": _vm.optionsCustomerClass
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("All")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.params.customer_class_id,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "customer_class_id", $$v);
            },
            expression: "params.customer_class_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Customer Status",
            "label-for": "customer_status"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "name": "customer_status"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": ''
                }
              }, [_vm._v("All")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'active'
                }
              }, [_vm._v("Active")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'on-hold'
                }
              }, [_vm._v("On Hold")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'credit-hold'
                }
              }, [_vm._v("Credit Hold")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'one-time'
                }
              }, [_vm._v("One-Time")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'inactive'
                }
              }, [_vm._v("Inactive")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'not-recommended'
                }
              }, [_vm._v("Not Recommended")]), _c('b-form-select-option', {
                attrs: {
                  "value": 'blacklist'
                }
              }, [_vm._v("Blacklist")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.params.customer_status,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "customer_status", $$v);
            },
            expression: "params.customer_status"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "button",
            "variant": "primary"
          },
          on: {
            "click": _vm.getListCustomer
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.loading ? _c('div', {
          staticClass: "alert alert-info"
        }, [_vm._v("Loading...")]) : _vm._e(), _c('iq-card', [((_vm$listsCustomer = _vm.listsCustomer) === null || _vm$listsCustomer === void 0 ? void 0 : (_vm$listsCustomer$dat = _vm$listsCustomer.data) === null || _vm$listsCustomer$dat === void 0 ? void 0 : _vm$listsCustomer$dat.length) > 0 ? _c('div', {
          staticClass: "overflow-auto d-flex"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Customer ID")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Customer Name")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Customer Class")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Country")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("City")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Terms")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Customer Status")]), _c('th', {
          staticClass: "text-left align-middle"
        }, [_vm._v("Address")]), _c('th', {
          staticClass: "text-left align-middle"
        }, [_vm._v("Phone 1")]), _c('th', {
          staticClass: "text-left align-middle"
        }, [_vm._v("Phone 2")]), _c('th', {
          staticClass: "text-left align-middle"
        }, [_vm._v("Email")]), _c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.listsCustomer.data, function (customer, customerIndex) {
          var _customer$customer_cl, _customer$customer_cl2, _customer$country_id, _customer$customer_sh, _customer$customer_sh2, _customer$customer_sh3;
          return _c('tr', {
            key: customerIndex
          }, [_c('td', [_vm._v(_vm._s(customerIndex + 1))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(customer === null || customer === void 0 ? void 0 : customer.customer_code))]), _c('td', [_vm._v(_vm._s(customer === null || customer === void 0 ? void 0 : customer.account_name))]), _c('td', [_vm._v(_vm._s((_customer$customer_cl = customer === null || customer === void 0 ? void 0 : (_customer$customer_cl2 = customer.customer_class) === null || _customer$customer_cl2 === void 0 ? void 0 : _customer$customer_cl2.name) !== null && _customer$customer_cl !== void 0 ? _customer$customer_cl : '-'))]), _c('td', [_vm._v(_vm._s((_customer$country_id = customer === null || customer === void 0 ? void 0 : customer.country_id) !== null && _customer$country_id !== void 0 ? _customer$country_id : '-'))]), _c('td', [_vm._v(_vm._s(customer === null || customer === void 0 ? void 0 : customer.city))]), _c('td', [_vm._v(_vm._s((_customer$customer_sh = customer === null || customer === void 0 ? void 0 : (_customer$customer_sh2 = customer.customer_shipping) === null || _customer$customer_sh2 === void 0 ? void 0 : (_customer$customer_sh3 = _customer$customer_sh2.shipping_terms) === null || _customer$customer_sh3 === void 0 ? void 0 : _customer$customer_sh3.code) !== null && _customer$customer_sh !== void 0 ? _customer$customer_sh : '-'))]), _c('td', {
            staticClass: "text-truncate text-capitalize"
          }, [_vm._v(_vm._s(customer === null || customer === void 0 ? void 0 : customer.customer_status))]), _c('td', [_vm._v(_vm._s(customer === null || customer === void 0 ? void 0 : customer.address))]), _c('td', [_vm._v(_vm._s(customer === null || customer === void 0 ? void 0 : customer.phone1))]), _c('td', [_vm._v(_vm._s(customer === null || customer === void 0 ? void 0 : customer.phone2))]), _c('td', [_vm._v(_vm._s(customer === null || customer === void 0 ? void 0 : customer.email))]), _vm.updatePermission || _vm.deletePermission ? _c('td', [_c('div', {
            staticClass: "d-flex flex-column align-items-center"
          }, [_c('i', {
            staticClass: "fa fa-pen text-primary mb-3",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.editCustomer(customer === null || customer === void 0 ? void 0 : customer.id);
              }
            }
          }), _c('i', {
            staticClass: "fa fa-trash text-danger",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteCustomer(customer);
              }
            }
          })])]) : _vm._e()]);
        }), 0)])]) : _vm._e(), ((_vm$listsCustomer2 = _vm.listsCustomer) === null || _vm$listsCustomer2 === void 0 ? void 0 : (_vm$listsCustomer2$da = _vm$listsCustomer2.data) === null || _vm$listsCustomer2$da === void 0 ? void 0 : _vm$listsCustomer2$da.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": (_vm$listsCustomer3 = _vm.listsCustomer) === null || _vm$listsCustomer3 === void 0 ? void 0 : (_vm$listsCustomer3$me = _vm$listsCustomer3.meta) === null || _vm$listsCustomer3$me === void 0 ? void 0 : _vm$listsCustomer3$me.totalItem,
            "per-page": (_vm$listsCustomer4 = _vm.listsCustomer) === null || _vm$listsCustomer4 === void 0 ? void 0 : (_vm$listsCustomer4$me = _vm$listsCustomer4.meta) === null || _vm$listsCustomer4$me === void 0 ? void 0 : _vm$listsCustomer4$me.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListCustomer
          },
          model: {
            value: _vm.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "page", $$v);
            },
            expression: "params.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No customer data found.")])])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "add-customer",
      "title": "".concat(_vm.isEdit ? 'EDIT' : 'ADD', " CUSTOMER"),
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Customers")]), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('FormCustomer', {
          attrs: {
            "show-add-customer": _vm.showAddCustomer,
            "is-edit": _vm.isEdit,
            "edit-id": _vm.editId,
            "refresh-data": _vm.refreshData,
            "optionsCustomerClass": _vm.optionsCustomerClass
          }
        })], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.showAddCustomer,
      callback: function callback($$v) {
        _vm.showAddCustomer = $$v;
      },
      expression: "showAddCustomer"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "customer-master",
      "title": "CUSTOMERS MASTERS",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Customer Masters")]), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ContentCustomerMasters', {
          attrs: {
            "refreshMasterData": _vm.refreshMasterData
          }
        })], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.showCustomerMaster,
      callback: function callback($$v) {
        _vm.showCustomerMaster = $$v;
      },
      expression: "showCustomerMaster"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }