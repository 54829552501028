var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.openSlideFormRealShipment('ADD', null);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v("   ADD REAL SHIPMENT")])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [[_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-bordered table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "200px",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("STATUS")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "350px",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("ID SHIPMENT PLAN")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PLAN DATE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PLAN QUANTITY")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("CARGO LOADING")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("CARGO UNLOADING")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("LOADING")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("UNLOADING")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PRICE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PRICE TO VENDOR")]), _c('th', {
    staticClass: "text-center bg-warning border-dark",
    staticStyle: {
      "vertical-align": "middle",
      "width": "5px",
      "position": "sticky",
      "right": "0",
      "z-index": "1"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("ACTION")])]), _c('tr', [_c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#E4EAA3"
    }
  }, [_vm._v("ETA POL")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#E4EAA3"
    }
  }, [_vm._v("ETA POD")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("POL")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("TA POL / READY")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("BERTHING")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMMENCED")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMPLETED")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("POD")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("TA POD / READY")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("BERTHING")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMMENCED")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMPLETED")])])]), _c('tbody', [[_c('tr', [_c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-truncate"
  }), _c('td', {
    staticClass: "text-center bg-warning border-dark",
    staticStyle: {
      "width": "5px",
      "position": "sticky",
      "right": "0",
      "z-index": "1"
    }
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link iq-bg-primary iq-border-radius-10",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "dropdown-toggle p-0",
          attrs: {
            "id": "dropdownMenuButton5",
            "data-toggle": "dropdown"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o m-0 text-primary"
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "primary"
    },
    on: {
      "click": _vm.openSlideFormRealShipment
    }
  }, [_c('i', {
    staticClass: "fa fa-eye mr-2"
  }), _vm._v("Detail ")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen mr-2"
  }), _vm._v("Add Price ")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "warning"
    }
  }, [_c('i', {
    staticClass: "fa fa-envelope mr-2"
  }), _vm._v("Change Status ")])], 1)], 1)])]], 2)])])], _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "first-number": "",
      "align": "center"
    }
  })], 1)], 2), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideFormRealShipment",
      "title": "Create Real Shipment",
      "width": "90%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('SlideFormRealShipment', {
          attrs: {
            "closeSlideFormRealShipment": _vm.closeSlideFormRealShipment,
            "refreshData": _vm.refreshData,
            "projectData": _vm.projectData,
            "id": _vm.id,
            "action": _vm.action
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideFormRealShipment,
      callback: function callback($$v) {
        _vm.slideFormRealShipment = $$v;
      },
      expression: "slideFormRealShipment"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }